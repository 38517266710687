import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import useTelegramSDK from "../hooks/useTelegramSDK";
import { getReferralsData } from "../actions/serverActions";
import {
  setSuccessPopup,
  updateReferralCount,
  updateReferralPoints,
  updateRefetchData,
  updateTonBalance,
} from "../reducers/UiReducers";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import constants, {
  LEAGUE_TASKS_DATA,
  REFERRAL_REWARDS_DATA,
} from "../utils/constants";
import {
  getNumbersInFormat,
  getNumbersInFormatOnlyMillions,
} from "../actions/helperFn";
import { KeyboardArrowDown } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import ReferralSnackbar from "../components/ReferralSnackbar";
import UserDashboard from "../components/UserDashboard";

const Referral = () => {
  const dispatch = useDispatch();
  const { telegramUserId, viberate, telegramUsername } = useTelegramSDK(true);

  const [allReferrals, setAllReferrals] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const tonBalance = useSelector((state) => state.ui.tonBalance);
  const referralPoints = useSelector((state) => state.ui.referralPoints);
  const referralCount = useSelector((state) => state.ui.referralCount);

  // API call: to fetch tasks
  useEffect(() => {
    async function asyncFn() {
      if (telegramUserId) {
        await fetchData();
      }
    }
    asyncFn();
  }, [telegramUserId]);

  const fetchData = async () => {
    if (telegramUserId) {
      let currDataLength = allReferrals.length;
      let pageNo = Math.ceil(currDataLength / 10);
      let res = await getReferralsData(telegramUserId, pageNo);

      if (res) {
        if (res.referralCount > 0) {
          setExpanded(false);
        } else {
          setExpanded(true);
        }

        let tempUsers = [...allReferrals, ...res.users];
        setAllReferrals(tempUsers);

        await dispatch(updateReferralCount(res.referralCount));
        await dispatch(updateReferralPoints(res.referralPoints));
        await dispatch(updateTonBalance(res.totalTON));

        if (res.referralCount !== referralCount) {
          await dispatch(updateRefetchData());
        }
      }
    }
  };

  const isMoreDataAvailable = useMemo(() => {
    return allReferrals.length < referralCount;
  }, [referralCount, allReferrals]);

  let referralLinkShare = `${constants.botUrl}${telegramUserId}`;

  let referralTextShare = `${telegramUsername} has gifted you a Gaming Starter Pack and a FREE TON SPIN on GobblUp! 🎁 %0A %0A

Claim your Starter Pack here: ${constants.botUrl}${telegramUserId} %0A%0A

Gobbl is the world's first foodchain. Become part of the world's first web3 x food ecosystem and join the mega airdrop.`;
  const handleCopyToClipboard = () => {
    if (telegramUserId) {
      let referralText = `**${telegramUsername}** has gifted you a Gaming Starter Pack and a FREE TON SPIN on GobblUp! 🎁 

**Claim your Starter Pack here:** ${constants.botUrl}${telegramUserId}

Gobbl is the world's first foodchain. Become part of the world's first web3 x food ecosystem and join the mega airdrop.`;

      navigator.clipboard.writeText(referralText).then(
        function () {
          viberate("light");
          dispatch(setSuccessPopup(true));
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "calc(100vh - 0px)",
        position: "relative",
        background: "#161811",
        zIndex: 0,
      }}
    >
      <ReferralSnackbar text="Invite link copied!" />
      <Box
        style={{
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "25px",
          paddingBottom: "75px",
          position: "relative",
        }}
      >
        <UserDashboard />
        <Box
          style={{
            width: "90%",
            height: 134,
            borderRadius: "24px",
            padding: "1px",
            position: "relative",
            marginTop: "15px",
            background: "transparent",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              padding: "15px 25px",
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "'Rubik'",
                  fontWeight: 600,
                  fontSize: "38px",
                  lineHeight: "100%",
                  textAlign: "center",
                  color: "#D1FF1A",
                }}
              >
                {referralCount}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Karla",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#FFFFFF",
                }}
              >
                Total Referrals
              </Typography>
            </Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Karla",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Earned TON
                </Typography>
                <Box
                  style={{
                    fontFamily: "'Rubik'",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "119.71%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    color: "#D1FF1A",
                  }}
                >
                  <img
                    src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                    style={{ width: 18, height: 18 }}
                  />
                  {tonBalance} TON
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "'Karla'",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Earned Gobbl
                </Typography>
                <Box
                  style={{
                    fontFamily: "'Rubik'",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "119.71%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    color: "#D1FF1A",
                  }}
                >
                  <img
                    src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                    style={{ width: 18, height: 18 }}
                  />
                  {getNumbersInFormatOnlyMillions(referralPoints)}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(-90deg, #00CCCC 0%, #009999 50%, #6666FF 100%)",
              borderRadius: "24px",
              padding: "1px",
              zIndex: -2,
              marginBottom: "15px",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "#161811",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "15px",
                zIndex: -2,
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                  borderRadius: "24px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: -3,
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            width: "90%",
            minHeight: "100px",
            background: "transparent",
            borderRadius: "24px",
            marginTop: "15px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            padding: "15px",
          }}
        >
          <Box
            style={{
              fontWeight: 600,
              fontSize: "15px",
              lineHeight: "130%",
              color: "#FAFF00",
              textAlign: "center",
            }}
          >
            Gift your friends a Starter Pack <br />& a Ton Spin.
            <br />
            <Typography
              style={{
                fontFamily: "'Karla'",
                fontWeight: 400,
                fontSize: "11.5px",
                lineHeight: "14px",
                color: "#FFFFFF",
                opacity: 0.8,
                wordBreak: "break-all",
                textAlign: "center",
              }}
            >
              {`${constants.botUrl}${telegramUserId}`}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "7px",
              position: "relative",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCopyToClipboard}
              style={{
                width: "82px",
                height: "27px",
                background: "#FAFF00",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#000",
              }}
            >
              COPY
            </Button>

            <Link
              to={`https://t.me/share/url?text=${referralTextShare}&url=${referralLinkShare}`}
            >
              <Button
                style={{
                  width: "82px",
                  height: "27px",
                  background: "transparent",
                  borderRadius: "8px",
                  fontWeight: 600,
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFF00",
                  border: "1px solid #FAFF00",
                }}
              >
                SHARE
              </Button>
            </Link>
          </Box>
          <Button
            style={{
              minWidth: 0,
              minHeight: 0,
              padding: "3px 10px",
              fontWeight: 600,
              fontSize: "15px",
              lineHeight: "130%",
              color: "#FAFF00",
              textAlign: "center",
              textDecoration: "underline",
              textTransform: "capitalize",
              borderRadius: "12px",
            }}
            onClick={() => setExpanded(!expanded)}
          >
            Bonus Structure
            <KeyboardArrowDown
              color="#FAFF00"
              style={{
                transform: expanded ? "rotate(-180deg)" : "rotate(-0deg)",
                fill: "#FAFF00",
              }}
            />
          </Button>
          {expanded && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 300,
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                You earn Spins, Gobbl & TON for each Referral.
              </Typography>

              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#64FF99",
                  textAlign: "center",
                }}
              >
                2X rewards for Premium Users
              </Typography>

              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Box
                  style={{
                    width: "50%",
                    fontFamily: "'Rubik'",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#FFFFFF",
                    opacity: 0.8,
                  }}
                >
                  Level
                </Box>
                <Box
                  style={{
                    width: "30%",
                    fontFamily: "'Rubik'",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#FFFFFF",
                    opacity: 0.8,
                  }}
                >
                  Gobbl
                </Box>
                <Box
                  style={{
                    width: "20%",
                    fontFamily: "'Rubik'",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#FFFFFF",
                    opacity: 0.8,
                  }}
                >
                  TON
                </Box>
              </Box>
              {REFERRAL_REWARDS_DATA.map((reward, i) => (
                <Box
                  key={i}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      width: "50%",
                      fontFamily: "'Rubik'",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "#FFFFFF",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      paddingBottom: 5,
                    }}
                  >
                    <img
                      src={reward.img}
                      style={{
                        width: 24,
                        height: 22,
                        transform:
                          i % 2 == 0 ? "rotate(-12deg)" : "rotate(12deg)",
                        objectFit: "contain",
                      }}
                    />
                    {reward.title}
                  </Box>
                  <Box
                    style={{
                      width: "30%",
                      fontFamily: "'Rubik'",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#64FF99",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                      style={{ width: 16, height: 16 }}
                    />
                    +{getNumbersInFormatOnlyMillions(reward.gobblRewards)}
                  </Box>
                  <Box
                    style={{
                      width: "20%",
                      fontFamily: "'Rubik'",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#64FF99",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                      style={{ width: 16, height: 16 }}
                    />
                    +{reward.tonRewards}
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {/* background */}
          <Box
            style={{
              width: "100%",
              height: "100%",
              background: "linear-gradient(180deg, #FF97FF 0%, #CC37CC 100%)",
              borderRadius: "24px",
              padding: "1px",
              zIndex: -2,
              marginBottom: "15px",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(180deg, rgba(120, 72, 255, 0.5) 0%, rgba(52, 109, 255, 0.5) 100%)",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "15px",
                zIndex: -2,
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, #463188 0%, #254287 100%)",
                  borderRadius: "24px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: -3,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ width: "90%" }}>
          <Box
            style={{
              width: "100%",
              minHeight: 134,
              height: "100%",
              position: "relative",
              background: "transparent",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "32px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "15px 0%",
                background: "transparent",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#FAFF00",
                  textAlign: "left",
                  marginRight: "auto",
                  marginLeft: "15px",
                  marginBottom: "10px",
                }}
              >
                My Referrals
              </Typography>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <InfiniteScroll
                    dataLength={allReferrals.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={isMoreDataAvailable}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        Yay! You have seen it all
                      </p>
                    }
                  >
                    {allReferrals.map((ele, i) => (
                      <Box
                        key={i}
                        style={{
                          width: "100%",
                          minHeight: 55,
                          height: "100%",
                          borderRadius: "12px",
                          padding: "1px",
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            minHeight: 53,
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 15px",
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Box
                              style={{
                                width: 40,
                                height: 40,
                                position: "relative",
                              }}
                            >
                              <img
                                src={
                                  ele.profilePic !== ""
                                    ? ele.profilePic
                                    : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/profileImages/Gobbl.png"
                                }
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                }}
                              />

                              {ele.isPremium && (
                                <img
                                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/crown.svg"
                                  style={{
                                    width: 20,
                                    height: 16,
                                    position: "absolute",
                                    left: 3,
                                    top: -12,
                                    zIndex: 2,
                                  }}
                                />
                              )}
                            </Box>
                            <Box
                              style={{
                                fontFamily: "'Karla'",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "16px",
                                color: "#FFFFFF",
                                opacity: 0.8,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "3px",
                              }}
                            >
                              @{ele.username ? ele.username : "Gobbl User"}
                              <Box
                                style={{
                                  width: "max-content",
                                  minWidth: 75,
                                  height: 18,
                                  // background: "#9EAB08",
                                  background:
                                    ele.leagueLevel === 1
                                      ? "#9EAB08"
                                      : ele.leagueLevel === 2
                                      ? "#1CB172"
                                      : ele.leagueLevel === 3
                                      ? "#0DB2BC"
                                      : ele.leagueLevel === 4
                                      ? "#5339EF"
                                      : ele.leagueLevel === 5
                                      ? "#AA2CD6"
                                      : ele.leagueLevel === 6
                                      ? "#D62C88"
                                      : ele.leagueLevel === 7
                                      ? "#FF5C00"
                                      : ele.leagueLevel === 8
                                      ? "#D1BD07"
                                      : ele.leagueLevel === 9
                                      ? "#59B200"
                                      : "#D6672C",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0 5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <Box
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    fontFamily: "Karla",
                                    fontWeight: 700,
                                    fontSize: "12px",
                                    color: "white",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <img
                                    src={LEAGUE_TASKS_DATA[0].img}
                                    style={{
                                      width: 12,
                                      height: 12,
                                      objectFit: "contain",
                                      transform: "rotate(12deg)",
                                      // transform:
                                      //   ele.leagueLevel % 2 == 0
                                      //     ? "rotate(-15deg)"
                                      //     : "rotate(15deg)",
                                    }}
                                  />
                                  {LEAGUE_TASKS_DATA[ele.leagueLevel].title}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {ele.points && (
                            <Box
                              style={{
                                minWidth: 100,
                                width: "fit-content",
                                borderRadius: "6px",
                                padding: "1px",
                                position: "relative",
                              }}
                            >
                              <Box
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "6px",
                                }}
                              >
                                <Box
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "6px",
                                    padding: "5px 5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: "3px",
                                  }}
                                >
                                  <Box
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "3px",
                                      fontSize: 12,
                                      lineHeight: "115%",
                                      fontWeight: 500,
                                      color: "#64FF99",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <img
                                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                                      style={{ width: 15, height: 15 }}
                                    />
                                    +{getNumbersInFormat(ele.points)}
                                  </Box>
                                  <Box
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "3px",
                                      fontSize: 12,
                                      lineHeight: "115%",
                                      fontWeight: 500,
                                      color: "#64FF99",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <img
                                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                                      style={{ width: 15, height: 15 }}
                                    />
                                    +{ele.TON} TON
                                  </Box>
                                </Box>
                              </Box>
                              {/* background */}
                              <Box
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  background: "#64FF99",
                                  borderRadius: "6px",
                                  padding: "1px",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  zIndex: -2,
                                }}
                              >
                                <Box
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    background: "#2B2D25",
                                    borderRadius: "6px",
                                    zIndex: -2,
                                  }}
                                >
                                  <Box
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      background:
                                        "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                                      borderRadius: "6px",
                                      zIndex: -3,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </InfiniteScroll>

                  {allReferrals.length === 0 && (
                    <Box style={{ textAlign: "center", opacity: 0.8 }}>
                      No data found.
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            {/* background */}
            <Box
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(149, 149, 149, 0.3) 50%, rgba(227, 227, 227, 0.3) 100%)",
                borderRadius: "24px",
                padding: "1px",
                zIndex: -4,
                marginBottom: "15px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#161811",
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                  zIndex: -4,
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "24px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -5,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Referral;
