import {
  Box,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Language, LocationOn } from "@mui/icons-material";
import { useSelector } from "react-redux";

const BgBadge = ({ level, bg1, bg2 }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      style={{
        width: md ? 30 : 49,
        height: md ? 36 : 58,
        position: "absolute",
        top: -0,
        right: 0,
        zIndex: 2,
      }}
    >
      <Box
        style={{
          position: "absolute",
          width: 27,
          height: 27,
          bottom: md ? -30 : -32.5,
          left: md ? 2.1 : 12,
          backgroundColor: "#000",
          transform: "rotate(45deg)",
        }}
      />
      <Typography
        variant="inherit"
        style={{
          width: "100%",
          textAlign: "center",
          position: "absolute",
          top: md ? 11 : 18,
          color: "#000",
          fontSize: md ? 20 : "33px",
          fontStyle: "italic",
          fontFamily: "Karla",
          fontWeight: 800,
          lineHeight: "27.909%",
          letterSpacing: "0.44px",
          zIndex: 3,
        }}
      >
        {level}
      </Typography>
      <Box
        style={{
          width: md ? 30 : 61,
          height: md ? 36 : 61,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          style={{
            position: "absolute",
            width: md ? 42 : 61,
            height: md ? 42 : 61,
            background: `linear-gradient(180deg, ${bg1} 0%, ${bg2} 100%)`,
            bottom: md ? 11 : 15,
            left: md ? -5.7 : -5,
            transform: "rotate(-45deg)",
          }}
        />
      </Box>
    </Box>
  );
};

const TrumpCard = ({ item, dishPoints }) => {
  const theme = useTheme();

  // Responsive
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // States
  const [flag, setFlag] = useState("");
  const [level, setLevel] = useState(item?.level);

  useEffect(() => {
    setLevel(item?.level);
  }, [item?.level]);

  const getColors = (level) => {
    switch (level) {
      case 2:
        return { bg1: "#DC9E66", bg2: "#814900", icon: "#DCA26C" };
      case 3:
        return { bg1: "#FDECC1", bg2: "#8F7635", icon: "#CBA56F" };
      case 4:
        return { bg1: "#5ECEB3", bg2: "#22836C", icon: "#2DBE8A" };
      case 5:
        return { bg1: "#FFCAA8", bg2: "#DD8355", icon: "#E88957" };
      case 6:
        return { bg1: "#E2E2E2", bg2: "#818181", icon: "#AFAFAF" };
      case 7:
        return { bg1: "#FADE7D", bg2: "#A77901", icon: "#D6A422" };
      default:
        return { bg1: "#00F0FF", bg2: "#008FBD", icon: "#00DFEE" };
    }
  };

  // Get Dish Card Points
  const getPoints = () => {
    if (dishPoints) {
      return dishPoints;
    } else {
      return item?.points ? item?.points : item?.score;
    }
  };

  const colors = useMemo(() => getColors(level), [level]);
  const bg1Color = colors?.bg1;
  const bg2Color = colors?.bg2;
  const iconColor = colors?.icon;

  let countryName = useMemo(() => {
    if (item) {
      return item.country.toLowerCase().replace(/\s+/g, "");
    } else {
      return false;
    }
  }, [item]);

  return (
    // item && (
    <Box
      style={{
        width: "100%",
        height: "100%",
        maxWidth: 180,
        maxHeight: 275,
        margin: "0 auto",
        flexShrink: 0,
        boxShadow: "0px 8px 11px 0px rgba(0, 0, 0, 0.4)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <BgBadge level={level} bg1={bg1Color} bg2={bg2Color} />
      <Box
        style={{
          backgroundColor: "#000",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            padding: md ? "10px 0 20px 10px" : "15px 0 35px 15px",
            position: "relative",
          }}
        >
          <Box
            style={{
              minWidth: md ? 87 : 143,
              height: md ? 87 : 143,
            }}
          >
            <img
              src={`https://onerare-bucket.s3.ap-south-1.amazonaws.com/tgDishes150/${item?.tokenId}.png`}
              style={{
                minWidth: md ? 87 : 143,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
          <Box
            style={{
              width: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  paddingLeft: md ? 7 : "10px",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: md ? 14 : 25,
                  fontStyle: "italic",
                  fontFamily: "Karla",
                  fontWeight: 800,
                  height: md ? 23 : "40px",
                  marginBottom: md ? 0 : "5px",
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/points.svg"
                  alt="points_label"
                  width={md ? 15 : 22}
                  height={md ? 15 : 22}
                />
                {getPoints()}
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: md ? "3px" : "5px",
                  padding: md ? "0 3px" : "5px 7px",
                  backgroundColor: "#2B2B2B",
                  color: "#fff",
                  fontSize: md ? 7 : 10,
                  lineHeight: "85%",
                  fontStyle: "italic",
                  fontFamily: "Karla",
                  fontWeight: 800,
                  height: md ? 17 : "23.353px",
                  marginTop: "3px",
                  zIndex: 4,
                }}
              >
                {countryName ? (
                  <img
                    src={`https://onerare-bucket.s3.ap-south-1.amazonaws.com/tgFlags/${countryName}.png`}
                    alt={item?.country}
                    loading="eager"
                    width={md ? 10 : 18}
                    height={md ? 8 : 12.5}
                  />
                ) : (
                  <img
                    src={
                      "https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/flags/Flag_Icon.svg"
                    }
                    alt={item?.country}
                    loading="eager"
                    width={md ? 10 : 18}
                    height={md ? 8 : 12.5}
                  />
                )}
                {item?.country}
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: md ? "3px" : "5px",
                  padding: md ? "0 3px" : "5px 7px",
                  backgroundColor: "#2B2B2B",
                  color: "#fff",
                  fontSize: md ? 7 : 10,
                  lineHeight: "95%",
                  fontStyle: "italic",
                  fontFamily: "Karla",
                  fontWeight: 800,
                  height: md ? 17 : "23.353px",
                  marginTop: md ? "1px" : "3px",
                  zIndex: 4,
                }}
              >
                <LocationOn
                  style={{
                    width: md ? 10 : 18,
                    height: md ? 10 : 20,
                    color: iconColor,
                  }}
                />
                {item?.region}
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: md ? "3px" : "5px",
                  padding: md ? "0 3px" : "5px 7px",
                  backgroundColor: "#2B2B2B",
                  color: "#fff",
                  fontSize: md ? 7 : 10,
                  lineHeight: "90%",
                  fontStyle: "italic",
                  fontFamily: "Karla",
                  fontWeight: 800,
                  height: md ? 17 : "23.353px",
                  marginTop: md ? "1px" : "3px",
                  zIndex: 4,
                }}
              >
                <Language
                  style={{
                    width: md ? 10 : 18,
                    height: md ? 10 : 20,
                    color: iconColor,
                  }}
                />
                {item?.continent}
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              gap: md ? "3px" : "5px",
              position: "absolute",
              left: md ? 10 : 15,
              bottom: md ? 3 : 9,
            }}
          >
            <img
              src={
                item?.diet === "NON VEGETARIAN"
                  ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/images/non-veg.svg"
                  : item?.diet === "VEGAN"
                  ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/images/vegan.svg"
                  : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/images/veg.svg"
              }
              alt={item?.diet}
              title={item?.diet}
              style={{ objectFit: "contain" }}
              width={md ? 13 : 18}
              height={md ? 13 : 18}
            />
            <img
              src={`https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/images/${item?.method?.toLowerCase()}2.svg`}
              alt={item?.method}
              title={item?.method}
              style={{ objectFit: "contain" }}
              width={md ? 13 : 18}
              height={md ? 13 : 18}
            />
          </Box>
        </Box>
        <Box
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          <img
            src={
              //   item &&
              `https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/level_bg${level}.svg`
            }
            alt="level_label"
            width={180}
            height={84}
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <Box style={{}}>
            <Typography
              variant="inherit"
              style={{
                width: "89%",
                height: md ? 50 : 86,
                display: "flex",
                alignItems: "center",
                paddingLeft: md ? 10 : "15px",
                color: "#000",
                fontSize: md ? 14 : 25,
                fontStyle: "italic",
                fontFamily: "Karla",
                fontWeight: 800,
                lineHeight: "96%",
                textTransform: "uppercase",
                position: "relative",
              }}
            >
              {item?.name}
            </Typography>
            <Typography
              variant="inherit"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingLeft: md ? 10 : "15px",
                backgroundColor: "#2B2B2B",
                color: "#fff",
                fontSize: md ? 10 : 17,
                fontStyle: "italic",
                fontFamily: "Karla",
                fontWeight: 800,
                height: md ? 24 : "35px",
                textTransform: "uppercase",
              }}
            >
              {item?.brand}
            </Typography>
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: md ? 10 : "15px",
                gap: md ? "3px" : "5px",
              }}
            >
              <Box
                sx={{
                  width: "62px",
                  height: 60,
                  borderRadius: "5px",
                  paddingTop: "7px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#363636",
                  position: "relative",
                  "&:before": {
                    border: md ? "2px solid #4e4e4e" : "3px solid #4e4e4e",
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#616161",
                    top: 0,
                    bottom: md ? "4px" : "10px",
                    borderRadius: md ? "5px" : "7px",
                    zIndex: 0,
                  },
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/bucket.svg"
                  alt="bucket_label"
                  style={{
                    position: "relative",
                    width: 30,
                    height: 30,
                  }}
                />
                <Typography
                  variant="inherit"
                  style={{
                    position: "relative",
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: md ? 14 : "25px",
                    fontStyle: "italic",
                    fontFamily: "Karla",
                    fontWeight: 800,
                    letterSpacing: "0.26px",
                    marginTop: "-4px",
                  }}
                >
                  {item?.number_of_ingredients}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "62px",
                  height: 60,
                  borderRadius: "5px",
                  paddingTop: "7px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#363636",
                  position: "relative",
                  "&:before": {
                    border: md ? "2px solid #4e4e4e" : "3px solid #4e4e4e",
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#616161",
                    top: 0,
                    bottom: md ? "4px" : "10px",
                    borderRadius: md ? "5px" : "7px",
                    zIndex: 0,
                  },
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/spice.svg"
                  alt="spice_label"
                  style={{
                    position: "relative",
                    width: 30,
                    height: 30,
                  }}
                />
                <Typography
                  variant="inherit"
                  style={{
                    position: "relative",
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: md ? 14 : "25px",
                    fontStyle: "italic",
                    fontFamily: "Karla",
                    fontWeight: 800,
                    letterSpacing: "0.26px",
                    marginTop: "-4px",
                  }}
                >
                  {item?.spice_meter}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "62px",
                  height: 60,
                  borderRadius: "5px",
                  paddingTop: "7px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#363636",
                  position: "relative",
                  "&:before": {
                    border: md ? "2px solid #4e4e4e" : "3px solid #4e4e4e",
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#616161",
                    top: 0,
                    bottom: md ? "4px" : "10px",
                    borderRadius: md ? "5px" : "7px",
                    zIndex: 0,
                  },
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/skill.svg"
                  alt="skill_label"
                  style={{
                    position: "relative",
                    width: 30,
                    height: 30,
                  }}
                />
                <Typography
                  variant="inherit"
                  style={{
                    position: "relative",
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: md ? 14 : "25px",
                    fontStyle: "italic",
                    fontFamily: "Karla",
                    fontWeight: 800,
                    letterSpacing: "0.26px",
                    marginTop: "-4px",
                  }}
                >
                  {item?.skill_meter}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "62px",
                  height: 60,
                  borderRadius: "5px",
                  paddingTop: "7px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#363636",
                  position: "relative",
                  "&:before": {
                    border: md ? "2px solid #4e4e4e" : "3px solid #4e4e4e",
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    backgroundColor: "#616161",
                    top: 0,
                    bottom: md ? "4px" : "10px",
                    borderRadius: md ? "5px" : "7px",
                    zIndex: 0,
                  },
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/trumpCard/time.svg"
                  alt="time_label"
                  style={{
                    position: "relative",
                    width: 30,
                    height: 30,
                  }}
                />
                <Typography
                  variant="inherit"
                  style={{
                    position: "relative",
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: md ? 14 : "25px",
                    fontStyle: "italic",
                    fontFamily: "Karla",
                    fontWeight: 800,
                    letterSpacing: "0.26px",
                    marginTop: "-4px",
                  }}
                >
                  {item?.time_meter}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          position: "relative",
          width: "100%",
          minHeight: md ? 5 : 8,
          background: `linear-gradient(180deg, ${bg1Color} 0%, ${bg2Color} 100%)`,
          zIndex: 5,
        }}
      />
    </Box>
    // )
  );
};

export default TrumpCard;
