import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateIsTapAvailable, setEnergyLeft } from "../reducers/UiReducers";

const useEnergyRestoreHook = (hookInit = false) => {
  const dispatch = useDispatch();

  const energyLeft = useSelector((state) => state.ui.energyLeft);
  const playValues = useSelector((state) => state.ui.playValues);
  const isTapAvailable = useSelector((state) => state.ui.isTapAvailable);
  const dataLoaded = useSelector((state) => state.ui.dataLoaded);

  //1. Energy Restore per second
  useEffect(() => {
    // console.count("energyUpdated");
    const interval = setTimeout(() => {
      if (dataLoaded) {
        let newEnergyValue = Math.min(
          energyLeft + playValues.recharge,
          playValues.energy
        );
        dispatch(setEnergyLeft(newEnergyValue));
      }
    }, 1000); // Restore energy points every second
    return () => clearInterval(interval);
  }, [energyLeft, dataLoaded, playValues.recharge, playValues.energy]);

  //2. Updating isTapAvailableFlag
  useEffect(() => {
    if (energyLeft < playValues.tap + 1 && isTapAvailable) {
      console.count("flagUpdated");
      dispatch(updateIsTapAvailable(false));
    }
    if (energyLeft > playValues.tap + 1 && !isTapAvailable) {
      console.count("flagUpdated");
      dispatch(updateIsTapAvailable(true));
    }
  }, [energyLeft, playValues, isTapAvailable]);
};

export default useEnergyRestoreHook;
