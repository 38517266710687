import React, { useState } from "react";
import SpinWheel from "../components/Wheel";
import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateRefetchData } from "../reducers/UiReducers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Spin = ({ openPopup, closePopup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openTooltip, setOpenTooltip] = useState(false);

  const spinsReceived = useSelector((state) => state.ui.spinsReceived);

  const handleCloseSpinPopup = async () => {
    dispatch(updateRefetchData());
    closePopup();
  };
  return (
    <Dialog
      open={openPopup}
      onClose={handleCloseSpinPopup}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      maxWidth="lg"
      fullWidth={false}
    >
      <Box
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: "10%",
              marginTop: "15vh",
              borderRadius: "33px 33px 0px 0px",
              zIndex: 1111,
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100vh",
                background:
                  "url(https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_bg.webp)",
                backgroundSize: "100%",
                backgroundPosition: "center center",
                // backdropFilter: "blur(5px)",
              }}
            />
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "15px 5% 0",
                position: "relative",
                zIndex: 1111,
              }}
            >
              <Box style={{ position: "relative" }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onMouseEnter={() => setOpenTooltip(true)}
                  onMouseLeave={() => setOpenTooltip(false)}
                >
                  <circle cx="8" cy="8" r="7.5" stroke="#D1FF1A" />
                  <path
                    d="M8.022 4.932C7.7 4.932 7.448 4.848 7.266 4.68C7.08867 4.50733 7 4.26933 7 3.966C7 3.672 7.08867 3.43867 7.266 3.266C7.448 3.08867 7.7 3 8.022 3C8.33933 3 8.589 3.08867 8.771 3.266C8.953 3.43867 9.044 3.672 9.044 3.966C9.044 4.26933 8.953 4.50733 8.771 4.68C8.589 4.848 8.33933 4.932 8.022 4.932ZM7.196 12.968V6.15H8.848V12.968H7.196Z"
                    fill="#D1FF1A"
                  />
                </svg>
                {openTooltip && (
                  <Box
                    style={{
                      position: "absolute",
                      left: 5,
                      top: 34,
                      width: 300,
                      height: 165,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/images/tooltip_bg.svg"
                      style={{
                        width: "118%",
                        height: "122%",
                        position: "absolute",
                        top: -20,
                        left: -28,
                        pointerEvents: "none",
                        filter: "drop-shadow(0px 25px 25px rgba(0, 0, 0, 0.6))",
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "'Karla'",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "14px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        position: "relative",
                      }}
                    >
                      Ready for some awesome rewards?
                      <br />
                      Give our wheel a spin & see what you win!
                      <br />
                      <br />
                      Refer your friends & win more spins:
                      <br />
                      1 Friend = 1 Extra Spin
                      <br />
                      3 Friends = 2 Extra Spins
                      <br />
                      5 Friends = 3 Extra Spins
                      <br />
                      Start spinning and win big!
                    </Typography>
                  </Box>
                )}
              </Box>
              <img
                src={
                  spinsReceived === 0
                    ? `https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/wheel_progress1.png`
                    : `https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/wheel_progress${spinsReceived}.png`
                }
                style={{
                  width: 187,
                  height: 20,
                }}
              />
              <Button
                style={{
                  minWidth: "auto",
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                }}
                onClick={handleCloseSpinPopup}
              >
                <Close
                  style={{
                    fontSize: 24,
                    fill: "#faff00",
                    zIndex: 1,
                  }}
                />
              </Button>
            </Box>
            <SpinWheel />
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/spin_page_bg.svg"
              style={{
                minWidth: "100vw",
                height: "85vh",
                position: "absolute",
                left: 0,
                bottom: 0,
                objectFit: "cover",
                objectPosition: "top",
                // boxShadow: "0px -19px 24px rgba(0, 0, 0, 0.5)",
                borderRadius: "33px 33px 0px 0px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Spin;
