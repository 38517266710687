import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardData,
  updateUserWalletAddressAPI,
} from "../actions/serverActions";

import { getNumbersInFormat } from "../actions/helperFn";
import { AccessTime, InfoOutlined } from "@mui/icons-material";
import {
  useIsConnectionRestored,
  TonConnectButton,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
import { useServerAuth } from "../hooks/useServerAuth";
import WalletSuccessPopup from "../components/WalletSuccessPopup";
import {
  updateUserWalletAddress,
  createWithdrawTonRequest,
} from "../reducers/UiReducers";
import TimeAgo from "react-timeago";

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState({});
  const [error, setError] = useState("");
  const [walletSuccessPopup, setWalletSuccessPopup] = useState(false);
  const [errorColor, setErrorColor] = useState("RED");

  const userAddress = useSelector((state) => state.ui.userAddress);
  const screenLoaded = useSelector((state) => state.ui.screenLoaded);

  const tonBalance = useSelector((state) => state.ui.tonBalance);
  const pendingTON = useSelector((state) => state.ui.pendingTON);
  const withdrawnTON = useSelector((state) => state.ui.withdrawnTON);

  const tonPendingTimestamp = useSelector(
    (state) => state.ui.tonPendingTimestamp
  );
  const tonSuccessTimestamp = useSelector(
    (state) => state.ui.tonSuccessTimestamp
  );

  const userFriendlyAddress = useTonAddress();
  const connectionRestored = useIsConnectionRestored();

  const wallet = useTonWallet();
  const { accountSC } = useServerAuth();

  // API call: to fetch tasks
  useEffect(() => {
    async function asyncFn() {
      let res = await getDashboardData();
      if (res) {
        setDashboardData(res);
      }
    }
    asyncFn();
  }, []);

  useEffect(() => {
    async function asyncFn() {
      if (
        screenLoaded &&
        accountSC &&
        connectionRestored &&
        userFriendlyAddress &&
        userAddress != userFriendlyAddress
      ) {
        await updateUserWalletAddressAPI(
          userFriendlyAddress,
          wallet.name,
          accountSC
        );
        dispatch(updateUserWalletAddress(userFriendlyAddress));
        setWalletSuccessPopup(true);
      }
    }

    asyncFn();
  }, [
    screenLoaded,
    accountSC,
    userFriendlyAddress,
    userAddress,
    wallet?.name,
    connectionRestored,
  ]);

  //1. When tonBalance<1
  //2. When tonBalance>=1
  //3. When wallet not connected
  const handleClaimButton = async () => {
    if (tonBalance > 1) {
      setErrorColor("GREEN");
    } else {
      setErrorColor("RED");
    }

    if (!userFriendlyAddress) {
      setError("Connect your wallet first to withdraw");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      if (tonBalance < 1) {
        setError("Minimum balance required: 1.00 TON");
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError("Withdrawl request has been submitted.");
        dispatch(createWithdrawTonRequest(accountSC));
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "fit-content",
        height: "100vh",
        position: "relative",
        background: "#161811",
        paddingTop: "25px",
        paddingBottom: "60px",
        // zIndex: 0,
        // overflow: "hidden",
      }}
    >
      {walletSuccessPopup && (
        <WalletSuccessPopup
          walletSuccessPopup={walletSuccessPopup}
          setWalletSuccessPopup={setWalletSuccessPopup}
        />
      )}
      {dashboardData && (
        <Box style={{ width: "100%", height: "100%" }}>
          <Box
            style={{
              width: "90%",
              height: 114,
              borderRadius: "24px",
              padding: "1px",
              position: "relative",
              marginTop: "15px",
              marginLeft: "5%",
              background: "transparent",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "15px 25px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Karla",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#FFFFFF",
                    zIndex: 1,
                  }}
                >
                  Total TON
                </Typography>
                <Box
                  style={{
                    fontFamily: "'Rubik'",
                    fontWeight: 600,
                    fontSize: 24,
                    lineHeight: "119.71%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    color: "#D1FF1A",
                    zIndex: 1,
                  }}
                >
                  <img
                    src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                    style={{ width: 24, height: 24 }}
                  />
                  {tonBalance} TON
                </Box>
                <Button
                  // disabled={true}
                  style={{
                    width: "82px",
                    height: "27px",
                    background: tonBalance < 1 ? "#bdbdbd" : "#D1FF1A",
                    borderRadius: "8px",
                    fontWeight: 600,
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    textTransform: "capitalize",
                    zIndex: 1,
                  }}
                  onClick={handleClaimButton}
                >
                  Claim
                </Button>
                {/* background */}
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(-90deg, #00CCCC 0%, #009999 50%, #6666FF 100%)",
                    borderRadius: "24px",
                    padding: "1px",
                    marginBottom: "15px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#161811",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                        borderRadius: "24px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* error */}
            {error && (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 72,
                  background: errorColor === "GREEN" ? "green" : "#FF4C4C",
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.45)",
                  borderRadius: "16px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                  zIndex: 2,
                  fontFamily: "'Karla'",
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#FFFFFF",
                  position: "absolute",
                  left: 0,
                  top: 114,
                }}
              >
                <InfoOutlined style={{ fontSize: 18 }} />
                {error}
              </Box>
            )}
          </Box>

          {/* wallet */}
          <Box
            style={{
              width: "90%",
              height: "100%",
              maxHeight: 70,
              background: "transparent",
              borderRadius: "24px",
              marginTop: "10px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              // padding: "15px",
              marginLeft: "5%",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                padding: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "5px",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Karla",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#FFFFFF",
                }}
              >
                {userFriendlyAddress
                  ? "Your TON wallet"
                  : "Wallet address not found"}
              </Typography>
              <TonConnectButton />
            </Box>
            {/* background */}
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "linear-gradient(180deg, #FF97FF 0%, #CC37CC 100%)",
                borderRadius: "24px",
                padding: "1px",
                marginBottom: "15px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, rgba(120, 72, 255, 0.5) 0%, rgba(52, 109, 255, 0.5) 100%)",
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(180deg, #463188 0%, #254287 100%)",
                    borderRadius: "24px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* ton withdraw  */}
          {/* {tonDetailData} */}
          <Box
            style={{
              width: "90%",
              height: "100%",
              maxHeight: 70,
              marginLeft: "5%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                borderRadius: "24px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "'Karla'",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "14px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  zIndex: 1,
                }}
              >
                TON Withdrawn
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  zIndex: 1,
                  fontFamily: "'Rubik'",
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#64FF99",
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                  style={{ width: 18, height: 18 }}
                />
                {withdrawnTON} TON
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  zIndex: 1,
                  minHeight: 15,
                }}
              >
                {tonSuccessTimestamp === -1
                  ? ""
                  : [
                      <AccessTime style={{ fontSize: 16, color: "#fff" }} />,
                      <Typography
                        style={{
                          fontFamily: "'Karla'",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          opacity: 0.8,
                        }}
                      >
                        <TimeAgo date={tonSuccessTimestamp} />
                      </Typography>,
                    ]}
              </Box>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, #089B89 0%, #036860 100%)",
                  borderRadius: "24px",
                  padding: "1px",
                  marginBottom: "15px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(180deg, rgba(8, 155, 137, 0.4) 0%, rgba(3, 104, 96, 0.4) 100%)",
                    borderRadius: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "15px",
                    position: "relative",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      background:
                        "linear-gradient(180deg, #0f473c 0%, #103a32 100%)",
                      borderRadius: "24px",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                borderRadius: "24px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "'Karla'",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "14px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  zIndex: 1,
                }}
              >
                Pending TON
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  zIndex: 1,
                  fontFamily: "'Rubik'",
                  fontWeight: 500,
                  fontSize: "18px",
                  color: "#FF4C4C",
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                  style={{ width: 18, height: 18 }}
                />
                {pendingTON} TON
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  zIndex: 1,
                  minHeight: 15,
                }}
              >
                {tonPendingTimestamp === -1
                  ? ""
                  : [
                      <AccessTime style={{ fontSize: 16, color: "#fff" }} />,
                      <Typography
                        style={{
                          fontFamily: "'Karla'",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          opacity: 0.8,
                        }}
                      >
                        <TimeAgo date={tonPendingTimestamp} />
                      </Typography>,
                    ]}
              </Box>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, #089B89 0%, #036860 100%)",
                  borderRadius: "24px",
                  padding: "1px",
                  marginBottom: "15px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(180deg, rgba(8, 155, 137, 0.4) 0%, rgba(3, 104, 96, 0.4) 100%)",
                    borderRadius: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "15px",
                    position: "relative",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      background:
                        "linear-gradient(180deg, #0f473c 0%, #103a32 100%)",
                      borderRadius: "24px",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* total gobbl */}
          <Box
            style={{
              width: "90%",
              height: "100%",
              maxHeight: 70,
              background: "transparent",
              borderRadius: "24px",
              marginTop: "10px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              // padding: "15px",
              marginLeft: "5%",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                padding: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "5px",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Karla",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#FFFFFF",
                }}
              >
                Total GOBBL
              </Typography>
              <Typography
                style={{
                  fontFamily: "'Rubik'",
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "100%",
                  color: "#D1FF1A",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <img
                  src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                  style={{
                    width: 24,
                    height: 24,
                  }}
                />

                {dashboardData.totalScore &&
                  getNumbersInFormat(dashboardData.totalScore)}
              </Typography>
            </Box>
            {/* background */}
            <Box
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(-90deg, #00CCCC 0%, #009999 50%, #6666FF 100%)",
                borderRadius: "24px",
                padding: "1px",
                marginBottom: "15px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#161811",
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "24px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* users data */}
          <Box
            style={{
              width: "90%",
              minHeight: 275,
              zIndex: 2,
              position: "relative",
              marginTop: "10px",
              marginLeft: "5%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                minHeight: 60,
                background:
                  "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
                borderRadius: "16px",
                padding: "1px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  minHeight: 58,
                  background: "#2B2D25",
                  borderRadius: "16px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 58,
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "0 15px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#FAFF00",
                    }}
                  >
                    Total Feeds
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Karla",
                      fontWeight: 400,
                      fontSize: "22px",
                      lineHeight: "100%",
                      color: "#FFFFFF",
                    }}
                  >
                    {dashboardData.totalTaps &&
                      getNumbersInFormat(dashboardData.totalTaps)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                height: "100%",
                minHeight: 60,
                background:
                  "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
                borderRadius: "16px",
                padding: "1px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  minHeight: 58,
                  background: "#2B2D25",
                  borderRadius: "16px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 58,
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "0 15px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#FAFF00",
                    }}
                  >
                    Total Players
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Karla",
                      fontWeight: 400,
                      fontSize: "22px",
                      lineHeight: "100%",
                      color: "#FFFFFF",
                    }}
                  >
                    {dashboardData?.totalUsers}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                height: "100%",
                minHeight: 60,
                background:
                  "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
                borderRadius: "16px",
                padding: "1px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  minHeight: 58,
                  background: "#2B2D25",
                  borderRadius: "16px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 58,
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "0 15px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#FAFF00",
                    }}
                  >
                    Daily Users
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "'Karla'",
                      fontWeight: 400,
                      fontSize: "22px",
                      lineHeight: "100%",
                      color: "#FFFFFF",
                    }}
                  >
                    {dashboardData?.totalActivePlayers}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
