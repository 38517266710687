import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const LoadingScreen = ({ text }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(390));

  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        background: "linear-gradient(180deg, #4886FF 0%, #03429F 100%)",
        padding: "0% 0 10%",
        zIndex: 1,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        overflow: "hidden",
        background:
          "url(https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_bg.webp)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Box
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_up.png"
          style={{
            width: "100%",
            height: 145,
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: xs ? 360 : 400,
          position: "relative",
          margin: xs ? "-25px 0" : "",
        }}
      >
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/loading_gobbl.webp"
          style={{
            minWidth: xs ? 340 : 380,
            maxWidth: xs ? 340 : 380,
            minHeight: xs ? 340 : 380,
            maxHeight: xs ? 340 : 380,
            objectFit: "contain",
            position: "absolute",
          }}
        />

        <object
          type="image/svg+xml"
          data="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/app-loader.svg"
          style={{
            position: "absolute",
            width: xs ? 100 : 110,
            height: xs ? 100 : 110,
            bottom: xs ? 100 : 110,
            zIndex: 11,
          }}
        />
      </Box>
      <img
        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_table.webp"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          bottom: 0,
        }}
      />
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "7px",
          position: "relative",
        }}
      >
        <Typography
          style={{
            fontFamily: "'Rubik'",
            fontWeight: 900,
            fontSize: 44,
            lineHeight: "100%",
            textAlign: "center",
            color: "#FAFF00",
          }}
        >
          $GOBBL
        </Typography>
        <Typography
          style={{
            fontFamily: "'Rubik'",
            fontWeight: 900,
            fontSize: 28,
            lineHeight: "100%",
            textAlign: "center",
            color: "#FAFF00",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
