import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ProgressBar from "./ProgressBar";

const TapEnergyBar = ({}) => {
  const energyLeft = useSelector((state) => state.ui.energyLeft);
  const playValues = useSelector((state) => state.ui.playValues);

  const energyFullpercentage = useMemo(() => {
    return (energyLeft * 100) / playValues.energy;
  }, [energyLeft, playValues.energy]);

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "3px",
        }}
      >
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/booster/hunger_pangs.png"
          style={{
            width: 33,
            height: 34,
            objectFit: "contain",
          }}
        />

        <Typography
          style={{
            width: "100%",
            fontFamily: "'Rubik'",
            fontWeight: 500,
            fontSize: "24px",
            color: "#FAFF00",
          }}
        >
          {energyLeft}
          <span style={{ fontSize: 17 }}>/{playValues.energy}</span>
        </Typography>
      </Box>

      <Box
        style={{
          width: "85%",
        }}
      >
        <ProgressBar value={energyFullpercentage} />
      </Box>
    </Box>
  );
};

export default TapEnergyBar;
