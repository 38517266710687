import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { TAP_DATA } from "../utils/constants";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useDispatch, useSelector } from "react-redux";
import SuccessSnackbar from "../components/SuccessSnackbar";
import LoadingScreen from "../components/LoadingScreen";
import {
  handleTapClickRedux,
  updateDailyBoxOpen,
  updateEnergyLeft,
} from "../reducers/UiReducers";
import useSound from "use-sound";
import TapEnergyBar from "../components/TapEnergyBar";
import TapScoreSection from "../components/TapScoreSection";
import Spin from "./Spin";
import DailyBox from "./DailyBox";
import Boost from "./Boost";
import UserDashboard from "../components/UserDashboard";
import HowToPlayPopup from "../components/HowToPlayPopup";

const Home = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(390));
  const dispatch = useDispatch();

  const { viberate } = useTelegramSDK();

  const myContainer = useRef(null);
  const multiTapFlag = useSelector((state) => state.ui.multiTapFlag);
  const spinsLeft = useSelector((state) => state.ui.spinsLeft);

  const nextClaimTimestamp = useSelector(
    (state) => state.ui.nextClaimTimestamp
  );

  const isMuted = useSelector((state) => state.ui.isMuted);
  const isTapAvailable = useSelector((state) => state.ui.isTapAvailable);
  const playLevels = useSelector((state) => state.ui.playLevels);
  const playValues = useSelector((state) => state.ui.playValues);
  const screenLoaded = useSelector((state) => state.ui.screenLoaded);
  const dailyBoxOpen = useSelector((state) => state.ui.dailyBoxOpen);

  const [isClickInProgress, setIsClickInProgress] = useState(false);
  const [clickTimer, setClickTimer] = useState(null);

  // Features toggle states
  const [openSpinFeature, setOpenSpinFeature] = useState(false);
  const [openBoostFeature, setOpenBoostFeature] = useState(false);
  const [howToPlayPopup, setHowToPlayPopup] = useState(false);

  const [play, { stop }] = useSound(
    "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/audio/tap2.mp3",
    { volume: isMuted ? 0 : 0.75, soundEnabled: !isMuted }
  );

  useEffect(() => {
    const handleTouch = (e) => {
      e.preventDefault();
      window.scroll(0, 0);
    };

    const container = document.querySelector(".myContainer");

    if (container) {
      container.addEventListener("touchmove", handleTouch, { passive: false });
      container.addEventListener("touchstart", handleTouch, { passive: false });
      container.addEventListener("touchend", handleTouch, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("touchmove", handleTouch);
        container.removeEventListener("touchstart", handleTouch);
        container.removeEventListener("touchend", handleTouch);
      }
    };
  }, [screenLoaded]);

  useEffect(() => {
    return () => {
      clearTimeout(clickTimer);
    };
  }, [clickTimer]);

  const handleEnergyReduceOnTap = () => {
    if (!multiTapFlag) {
      let tapPoints = playValues.tap;
      dispatch(updateEnergyLeft(-tapPoints));
    }
  };

  const handleTouch = useCallback(
    (e) => {
      e.preventDefault();
      window.moveTo(0, 0);

      let tapPoints = multiTapFlag ? playValues.tap * 5 : playValues.tap;

      if (isTapAvailable || multiTapFlag) {
        play();
        if (!isClickInProgress) {
          setIsClickInProgress(true);
        }
        viberate("medium");
        dispatch(handleTapClickRedux(tapPoints));
        handleEnergyReduceOnTap();

        for (let i = 0; i < e.touches.length; i++) {
          const touch = e.touches[i];
          const rect = e.currentTarget.getBoundingClientRect();
          const x = touch.clientX - rect.left;
          const y = touch.clientY - rect.top;

          const clickDiv = document.createElement("div");
          clickDiv.textContent = `+${tapPoints}`;
          clickDiv.style.position = "absolute";
          clickDiv.style.top = `${y - 40}px`;
          clickDiv.style.left = `${x - 15}px`;
          clickDiv.style.animation = "float 0.75s ease-out";
          clickDiv.style.color = "#64FF99";
          clickDiv.style.fontWeight = "700";
          clickDiv.style.fontSize = "4vh";
          clickDiv.style.zIndex = "99";
          clickDiv.style.fontFamily = "Rubik";
          clickDiv.style.userSelect = "none";
          clickDiv.style.WebkitTextStroke = "1px black";

          e.currentTarget.appendChild(clickDiv);

          clickDiv.addEventListener("animationend", () => {
            clickDiv.remove();
          });
        }
      }
    },
    [isTapAvailable, multiTapFlag, playValues.tap]
  );

  const handleTouchEnd = () => {
    setClickTimer(
      setTimeout(() => {
        if (isClickInProgress) {
          setIsClickInProgress(false);
          stop();
        }
      }, 400)
    );
  };

  return (
    <Box width="100%" height="100%" style={{ zIndex: 0 }}>
      <SuccessSnackbar text="Booster claimed successfully!" />
      {screenLoaded && (
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            background: "linear-gradient(180deg, #4886FF 0%, #03429F 100%)",
            padding: "50px 0",
            zIndex: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
            overflow: "hidden",
            background:
              "url(https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_bg.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <Box
            style={{ height: 0, position: "absolute", width: "100%", top: 25 }}
          >
            <UserDashboard />
          </Box>
          <TapScoreSection />
          <Box
            className="myContainer"
            ref={myContainer}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "45vh",
              position: "relative",
            }}
            onTouchStart={handleTouch}
            onTouchEnd={handleTouchEnd}
          >
            <Box
              style={{
                width: "120%",
                minWidth: xs ? 450 : 500,
                minHeight: xs ? 450 : 500,
                height: "100%",
                zIndex: 0,
                marginBottom: xs ? "10px" : "-5%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <img
                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_gobbl.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  minWidth: xs ? 450 : 500,
                  minHeight: xs ? 450 : 500,
                  objectFit: "contain",
                  position: "absolute",
                  zIndex: 1,
                  opacity: isClickInProgress ? 1 : 0,
                }}
              />
              <img
                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  minWidth: xs ? 450 : 500,
                  minHeight: xs ? 450 : 500,
                  objectFit: "contain",
                  position: "absolute",
                }}
              />
            </Box>
            <img
              src={TAP_DATA[playLevels.tap].dish}
              style={{
                width: "64%",
                height: "100%",
                objectFit: "contain",
                position: "absolute",
                bottom: "-5%",
                zIndex: 1,
              }}
            />
          </Box>
          <img
            src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_table.webp"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              bottom: 0,
            }}
          />
          <TapEnergyBar />

          {/* <Button
            style={{
              fontFamily: "'Rubik'",
              fontWeight: 600,
              fontSize: "11.3023px",
              lineHeight: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              textAlign: "center",
              color: "#D1FF1A",
              textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
              position: "absolute",
              right: -5,
              top: 160,
              minWidth: 0,
              borderRadius: "12px",
            }}
            onClick={() => dispatch(updateIsMuted(!isMuted))}
          >
            <img
              src={
                isMuted
                  ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/sound_off.png"
                  : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/sound_on.png"
              }
              style={{ width: 55, height: 55 }}
            />
          </Button> */}
          <Box
            onClick={() => {
              viberate("heavy");
              setOpenSpinFeature(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              position: "absolute",
              left: "3%",
              top: 155,
              minWidth: 0,
              width: 45,
              borderRadius: "12px",
            }}
          >
            {spinsLeft > 0 && (
              <span
                style={{
                  width: "7px",
                  height: "7px",
                  borderRadius: "50%",
                  background: "#FF4C4C",
                  position: "absolute",
                  top: 3,
                  right: 7,
                  zIndex: 3,
                }}
              />
            )}
            <img
              src="/images/spin_icon.png"
              style={{ width: 48, height: 48 }}
            />
            <Typography
              style={{
                fontFamily: "'Rubik'",
                fontWeight: 600,
                fontSize: "11.3023px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#FAFF00",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                marginTop: "-7px",
              }}
            >
              TON SPIN
            </Typography>
          </Box>
          <Box
            onClick={() => {
              viberate("heavy");
              dispatch(updateDailyBoxOpen(true));
            }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              position: "absolute",
              left: "3%",
              top: 225,
              minWidth: 0,
              width: 45,
              borderRadius: "12px",
            }}
          >
            {nextClaimTimestamp < Date.now() && (
              <span
                style={{
                  width: "7px",
                  height: "7px",
                  borderRadius: "50%",
                  background: "#FF4C4C",
                  position: "absolute",
                  top: 3,
                  right: 7,
                  zIndex: 3,
                }}
              />
            )}
            <img
              src="/images/dailybox_icon.png"
              style={{ width: 48, height: 48 }}
            />
            <Typography
              style={{
                fontFamily: "'Rubik'",
                fontWeight: 600,
                fontSize: "11.3023px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#FAFF00",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                marginTop: "-7px",
              }}
            >
              DAILY BOX
            </Typography>
          </Box>
          <Box
            onClick={() => {
              viberate("heavy");
              setHowToPlayPopup(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              position: "absolute",
              right: "3%",
              top: 110,
              minWidth: 0,
              width: 45,
              borderRadius: "12px",
            }}
          >
            <img
              src="/images/howToPlay_icon.png"
              style={{ width: 32, height: 32 }}
            />
          </Box>
          <Box
            onClick={() => {
              viberate("heavy");
              setOpenBoostFeature(true);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              position: "absolute",
              right: "3%",
              top: 155,
              minWidth: 0,
              width: 45,
              borderRadius: "12px",
            }}
          >
            <img
              src="/images/boost_icon.png"
              style={{ width: 48, height: 48 }}
            />
            <Typography
              style={{
                fontFamily: "'Rubik'",
                fontWeight: 600,
                fontSize: "11.3023px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#FAFF00",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                marginTop: "-7px",
              }}
            >
              BOOST
            </Typography>
          </Box>
          <a
            href="https://telegram.me/gobblnews/"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "0px",
              position: "absolute",
              right: "3%",
              top: 225,
              minWidth: 0,
              width: 45,
              borderRadius: "12px",
            }}
          >
            <img
              src="/images/news_icon.png"
              style={{ width: 48, height: 48 }}
            />
            <Typography
              style={{
                fontFamily: "'Rubik'",
                fontWeight: 600,
                fontSize: "11.3023px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#FAFF00",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
                marginTop: "-7px",
              }}
            >
              NEWS
            </Typography>
          </a>

          <Spin
            openPopup={openSpinFeature}
            closePopup={() => {
              setOpenSpinFeature(false);
            }}
          />
          <DailyBox
            openPopup={dailyBoxOpen}
            closePopup={() => {
              dispatch(updateDailyBoxOpen(false));
            }}
          />
          <HowToPlayPopup
            openPopup={howToPlayPopup}
            closePopup={() => setHowToPlayPopup(false)}
          />
          <Boost
            openPopup={openBoostFeature}
            closePopup={() => {
              setOpenBoostFeature(false);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Home;
