import { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useServerAuth } from "../hooks/useServerAuth";
import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import { signUpFoodFiveWithTG } from "../actions/serverActions";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useMediaQuery as useQuery } from "react-responsive";
import SuccessSnackbar from "../components/SuccessSnackbar";
import { useDispatch } from "react-redux";
import { setSuccessPopup } from "../reducers/UiReducers";
import { BackButton } from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import constants from "../utils/constants";

const DeviceRotatePopup = () => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        background: "#000",
        zIndex: 111111,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none",
        cursor: "pointer",
      }}
    >
      <object
        data="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/rotate_phone.svg"
        type="image/svg+xml"
        style={{ zIndex: 1 }}
      />
      <img
        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/rotate_phone.webp"
        style={{ position: "absolute", maxWidth: "90vw", zIndex: 0 }}
      />
    </Box>
  );
};

function FoodFury() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isPortrait = useQuery({ query: "(orientation: portrait)" });
  const isLandscape = useQuery({ query: "(orientation: landscape)" });

  const { telegramUserId, telegramReferId, telegramFullName } =
    useTelegramSDK(true);
  const { accountSC, authloaded } = useServerAuth();
  const [startGame, setStartGame] = useState(true);
  const [rotatePopup, setRotatePopup] = useState(false);

  const build =
    constants.net === 1
      ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/webgl-builds/FOODFURY/Development/FoodFuryTelegram/Build/"
      : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/webgl-builds/FOODFURY/Production/FoodFuryTelegram/Build/";

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
    loadingProgression,
    unload,
  } = useUnityContext({
    loaderUrl: build + "FoodFuryTelegram.loader.js",
    dataUrl: build + "FoodFuryTelegram.data.unityweb",
    frameworkUrl: build + "FoodFuryTelegram.framework.js.unityweb",
    codeUrl: build + "FoodFuryTelegram.wasm.unityweb",
    streamingAssetsUrl:
      "https://onerare-bucket.s3.ap-south-1.amazonaws.com/webgl-builds/FOODFURY/Production/FoodFuryTelegram/StreamingAssets",
    cacheControl: () => "no-store",
  });

  const UnityHandler_CallReactClipboard = useCallback(
    async (url) => {
      try {
        console.log(
          "CallReactClipboard:",
          `t.me/GobblUpBot/game?startapp=${url}`
        );
        navigator.clipboard.writeText(`t.me/GobblUpBot/game?startapp=${url}`);
        dispatch(setSuccessPopup(true));
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
    [authloaded, isLoaded]
  );

  const UnityHandler_SendTG_URL = useCallback(
    async (url) => {
      try {
        console.log("SendTG_URL:");
        window.location.href = `https://t.me/share/url?url=t.me/FoorFury_Bot/ffapp?startapp=${telegramUserId}`;
      } catch (err) {
        console.error("Failed to redirect: ", err);
      }
    },
    [authloaded, isLoaded]
  );

  const UnityHandler_Quit = useCallback(async () => {
    console.log("Call from UNITY - Quit");
    await unload().then(() => navigate("/tap"));
  }, [authloaded, isLoaded]);

  useEffect(() => {
    addEventListener("CallReactClipboard", UnityHandler_CallReactClipboard);
    addEventListener("SendTG_URL", UnityHandler_SendTG_URL);
    addEventListener("Quit", UnityHandler_Quit);
    return () => {
      removeEventListener(
        "CallReactClipboard",
        UnityHandler_CallReactClipboard
      );
      removeEventListener("SendTG_URL", UnityHandler_SendTG_URL);
      removeEventListener("Quit", UnityHandler_Quit);
    };
  }, [
    addEventListener,
    removeEventListener,
    UnityHandler_CallReactClipboard,
    UnityHandler_SendTG_URL,
  ]);

  useEffect(() => {
    setStartGame(true);
    (async () => {
      if (isLoaded) {
        const res = await signUpFoodFiveWithTG(
          telegramUserId,
          "TELEGRAM",
          telegramReferId
        );
        const userData = {
          _id: res._id,
          userid: telegramUserId,
          referalUrl: `https://t.me/gobbl_mini_bot/game?startapp=${telegramUserId}`,
          userName: telegramFullName,
        };
        sendMessage(
          "LoginManager",
          "SetPlayerIdAndReferalUrl_JS",
          JSON.stringify(userData)
        );
      }
    })();
  }, [isLoaded, telegramUserId, accountSC]);

  useEffect(() => {
    if (isPortrait) {
      setRotatePopup(true);
      sendMessage("Managers", "OnOrientationUpdated", "portrait");
    } else if (isLandscape) {
      setRotatePopup(false);
      sendMessage("Managers", "OnOrientationUpdated", "landscape");
    }
  }, [isPortrait, isLandscape]);

  return (
    <Box
      minHeight="100vh"
      width="100%"
      bgcolor="#000"
      position="relative"
      zIndex={11}
    >
      <SuccessSnackbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "10px",
          left: "30%",
          right: "30%",
        }}
        text="Invite link copied!"
      />
      {/* <BackButton
        onClick={async () => {
          await unload();
          window.history.back();
        }}
      /> */}
      {rotatePopup && <DeviceRotatePopup />}
      <div
        style={{
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          textAlign: "center",
          color: "white",
          opacity: !startGame ? 0 : 1,
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        {/* <div id="widget"></div> */}
        <div
          // className="loader"
          style={{
            display: !isLoaded ? "flex" : "none",
            minWidth: "100vw",
            height: "100vh",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "3vh",
              position: "relative",
              zIndex: 1,
            }}
          >
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/foodfury_logo.webp"
              width={160}
              height={160}
              alt="FoodVerse FOOD5"
              style={{
                // maxWidth: "45vw",
                width: "100%",
                margin: "auto 0",
                objectFit: "contain",
                position: "relative",
                zIndex: 2,
              }}
            />
            {/* progressbar */}
            <div
              style={{
                width: "70vw",
                minWidth: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2vw",
                position: "relative",
                marginBottom: "6vh",
                position: "relative",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderRadius: "5vh",
                  padding: "0.35vh",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#3F3F3F",
                    border: "2px solid #000000",
                    boxShadow: "0px 4px 0px #000000",
                    height: "4vh",
                    borderRadius: "3vh",
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      width:
                        Math.round(loadingProgression * 100) > 25
                          ? `${Math.round(loadingProgression * 100)}%`
                          : "25%",
                      height: "100%",
                      backgroundColor: "#EBEB06",
                      position: "relative",
                      zIndex: 2,
                      borderRadius: "5vh",
                    }}
                  >
                    <img
                      src="/images/foodfury_bike.svg"
                      alt="FoodVerse FOOD5"
                      style={{
                        position: "absolute",
                        bottom: "-2vh",
                        right: "-15vh",
                        right: "-2vh",
                        maxHeight: "22vh",
                        maxWidth: "22vh",
                        zIndex: 2,
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3
                style={{
                  position: "absolute",
                  color: "#FFFF00",
                  marginRight: "auto",
                  marginBottom: "-0px",
                  whiteSpace: "nowrap",
                  right: "-6.5vw",
                  fontFamily: "'Days One'",
                  fontStyle: "normal",
                  fontSize: "5vh",
                  lineHeight: "5vh",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {Math.round(loadingProgression * 100)}%
              </h3>
            </div>
            <img
              src="/images/foodfury_bg_stars.svg"
              style={{
                width: "85%",
                height: "85%",
                position: "absolute",
              }}
            />
          </div>
          <img
            src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/foodfury_bg.webp"
            className="loader_bg"
            alt="FoodFury"
            loading="eager"
            // priority={true}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </div>
        <Unity
          unityProvider={unityProvider}
          devicePixelRatio={window?.devicePixelRatio || 2}
          style={{
            height: "100vh",
            width: "100%",
            opacity: isLoaded ? 1 : 0,
          }}
        />
      </div>
    </Box>
  );
}

export default FoodFury;
