class EthersServiceProvider {
  provider;
  currentUserId;
  tgUsername = "Foodie";
  photoUrl =
    "https://onerare-bucket.s3.ap-south-1.amazonaws.com/profileImages/Gobbl.png";
  authLoadedStatus = false;

  constructor() {}

  async setCurrentUserId(value) {
    this.currentUserId = value;
  }
  async setTGUsername(value) {
    this.tgUsername = value;
  }
  async setTGPhotoUrl(value) {
    this.photoUrl = value;
  }

  async setAuthLoadedStatus(flag) {
    this.authLoadedStatus = flag;
  }
}

const ethersServiceProvider = new EthersServiceProvider();

export default ethersServiceProvider;
