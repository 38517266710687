// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAQlZz8VB5r6AL1M33t90oVCDMt_xahg8c",
  authDomain: "onerare-otp.firebaseapp.com",
  projectId: "onerare-otp",
  storageBucket: "onerare-otp.appspot.com",
  messagingSenderId: "1006547548137",
  appId: "1:1006547548137:web:e82c2a3bda06a53463bd78",
  measurementId: "G-Z3W77ET80X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
