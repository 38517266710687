import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowForwardIos, Close } from "@mui/icons-material";
import BoostDetailsPopup from "../components/BoostDetailsPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  ENERGY_LIMIT_DATA,
  RECHARGE_SPEED_DATA,
  TAP_DATA,
} from "../utils/constants";

import useTelegramSDK from "../hooks/useTelegramSDK";
import { useNavigate } from "react-router-dom";

import {
  getNumbersInFormatOnlyMillions,
  getTimeLeftFormatter,
  numberWithCommas,
} from "../actions/helperFn";
import { useServerAuth } from "../hooks/useServerAuth";
import {
  setEnergyLeft,
  setSuccessPopup,
  updateFullHungerStamp,
  updateMultiTap,
  updateMultiTapStamp,
  upgradeBoosterRedux,
} from "../reducers/UiReducers";
import { upgradeTimeBoosterToBackend } from "../actions/serverActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BoosterCard = ({
  title,
  img,
  price,
  level,
  type,
  description1,
  description2,
  isFull,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const xs = useMediaQuery(theme.breakpoints.down(390));
  const { viberate } = useTelegramSDK();
  const navigate = useNavigate();
  const { accountSC } = useServerAuth();

  const [open, setOpen] = useState(false);

  const handlePurchaseButton = async () => {
    // Upgrade booster by type to backendAPI
    let dataObj = {
      userId: accountSC,
      type: type,
      taskId: level,
    };
    let res = await dispatch(upgradeBoosterRedux(dataObj));

    if (res) {
      navigate("/tap");
      setOpen(false);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: xs ? 55 : 60,
        minHeight: xs ? 55 : 60,
        background:
          "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
        borderRadius: "8px",
        padding: "1px",
        transition: "height 250ms",
      }}
      onClick={() => {
        if (!open && !isFull) {
          viberate("light");
          setOpen(true);
        }
      }}
    >
      <BoostDetailsPopup
        title={title}
        description1={description1}
        description2={description2}
        img={img}
        price={price}
        level={level + 1}
        openPopup={open}
        closePopup={() => {
          viberate("light");
          setOpen(false);
        }}
        onClick={handlePurchaseButton}
      />
      <Box
        style={{
          width: "100%",
          height: "100%",
          background: "#2B2D25",
          borderRadius: "8px",
        }}
      >
        <Box
          style={{
            width: "100%",
            minHeight: "100%",
            background:
              "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "15px",
            padding: "0 15px",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: 41,
              height: 41,
              borderRadius: "12px",
              padding: "1px",
              transition: "height 200ms",
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
              }}
            >
              <img
                src={img}
                style={{
                  width: 41,
                  height: 42,
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "120%",
                color: isFull ? "#bdbdbd" : "#FFFFFF",
              }}
            >
              {title}
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  fontFamily: "'Karla'",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: isFull ? "#bdbdbd" : "#FFFFFF",
                }}
              >
                {isFull && "FULLY UPGRADED"}
                {!isFull && (
                  <span>
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.webp"
                      style={{ width: 24, height: 24, marginRight: 2 }}
                    />
                    {getNumbersInFormatOnlyMillions(price)}
                  </span>
                )}
              </Typography>
              <Box
                style={{
                  width: "0px",
                  height: "23px",
                  opacity: 0.2,
                  border: "0.5px solid #FFFFFF",
                }}
              />
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: isFull ? "#bdbdbd" : "#FFFFFF",
                }}
              >
                {level} LEVEL
              </Typography>
            </Box>
          </Box>
          {!isFull && (
            <ArrowForwardIos
              style={{
                fill: "#faff00",
                position: "absolute",
                margin: "auto 0",
                right: 10,
              }}
              onClick={isFull ? null : () => setOpen(true)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Boost = ({ openPopup, closePopup }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(390));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { viberate } = useTelegramSDK();
  const { accountSC } = useServerAuth();

  const score = useSelector((state) => state.ui.score);
  const playLevels = useSelector((state) => state.ui.playLevels);
  const multiTapStamp = useSelector((state) => state.ui.multiTapStamp);
  const fullHungerStamp = useSelector((state) => state.ui.fullHungerStamp);
  const multiTapFlag = useSelector((state) => state.ui.multiTapFlag);

  const playValues = useSelector((state) => state.ui.playValues);

  const [multiTapPopup, setMultiTapPopup] = useState(false);
  const [fullHungerPopup, setFullHungerPopup] = useState(false);

  const handleMultiTapFn = async () => {
    enableMultiTap();
    setMultiTapPopup(false);
    navigate("/tap");
  };

  const handleFullHungerFn = async () => {
    claimFullEnergy();
    setFullHungerPopup(false);
    navigate("/tap");
  };

  const surgeClaimsAvailableCount = useMemo(() => {
    if (multiTapStamp.length === 3) {
      if (multiTapStamp[2] + 21600000 < Date.now()) {
        return 3;
      } else {
        return 0;
      }
    } else {
      return 3 - multiTapStamp.length;
    }
  }, [multiTapStamp, multiTapFlag]);

  const multiTapCloseCondition = useMemo(() => {
    return surgeClaimsAvailableCount === 0 ? true : false;
  }, [surgeClaimsAvailableCount]);

  const hungerClaimsAvailableCount = useMemo(() => {
    if (fullHungerStamp.length === 3) {
      if (fullHungerStamp[2] + 21600000 < Date.now()) {
        return 3;
      } else {
        return 0;
      }
    } else {
      return 3 - fullHungerStamp.length;
    }
  }, [fullHungerStamp]);

  const fullHungerCloseCondition = useMemo(() => {
    return hungerClaimsAvailableCount === 0 ? true : false;
  }, [hungerClaimsAvailableCount]);

  const timeToNextClaimSurge = useMemo(() => {
    if (multiTapStamp.length === 3) {
      if (multiTapStamp[2] + 21600000 < Date.now()) {
        return 0;
      } else {
        return Date.now() - multiTapStamp[2];
      }
    } else {
      return 0;
    }
  }, [multiTapStamp, multiTapFlag]);

  const timeToNextClaimHunger = useMemo(() => {
    if (fullHungerStamp.length === 3) {
      if (fullHungerStamp[2] + 21600000 < Date.now()) {
        return 0;
      } else {
        return Date.now() - fullHungerStamp[2];
      }
    } else {
      return 0;
    }
  }, [fullHungerStamp]);

  // FUNCTION:: Enable MultiTap Booster for 20 Sec
  const enableMultiTap = async () => {
    let nextUnlockTime = Date.now() - 21600000;
    let dataObj = {
      userId: accountSC,
      type: "tap",
      now: Date.now(),
    };
    await upgradeTimeBoosterToBackend(dataObj);
    if (!multiTapFlag && multiTapStamp.length < 3) {
      //ADD Timestamp into BOOSTER

      const tempBoosterStamp = [...multiTapStamp, Date.now()];
      await dispatch(updateMultiTapStamp(tempBoosterStamp));
      await dispatch(updateMultiTap(true));
      dispatch(setSuccessPopup(true));
      setTimeout(() => {
        dispatch(updateMultiTap(false));
      }, 20000);
    }

    if (multiTapStamp.length === 3 && nextUnlockTime > multiTapStamp[2]) {
      //RESET BOOSTER
      await dispatch(updateMultiTapStamp([Date.now()]));
      await dispatch(updateMultiTap(true));
      dispatch(setSuccessPopup(true));
      setTimeout(() => {
        dispatch(updateMultiTap(false));
      }, 20000);
    }
    closePopup();
  };

  // FUNCTION:: Claim max energy upto 3 times
  const claimFullEnergy = async () => {
    let nextUnlockTime = Date.now() - 21600000;
    let dataObj = {
      userId: accountSC,
      type: "energy",
      now: Date.now(),
    };
    await upgradeTimeBoosterToBackend(dataObj);
    if (fullHungerStamp.length < 3) {
      //ADD Timestamp into Hunger Booster
      const tempBoosterStamp = [...fullHungerStamp, Date.now()];
      await dispatch(updateFullHungerStamp(tempBoosterStamp));
      dispatch(setEnergyLeft(playValues.energy));

      dispatch(setSuccessPopup(true));
    }

    if (fullHungerStamp.length === 3 && nextUnlockTime > fullHungerStamp[2]) {
      //RESET BOOSTER
      await dispatch(updateFullHungerStamp([Date.now()]));
      dispatch(setEnergyLeft(playValues.energy));
      dispatch(setSuccessPopup(true));
    }
    closePopup();
  };

  return (
    <Dialog
      open={openPopup}
      onClose={closePopup}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      maxWidth="lg"
      fullWidth={false}
      style={{
        zIndex: 111,
      }}
    >
      <Box
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              width: "100%",
              minHeight: 550,
              position: "relative",
              background: "linear-gradient(180deg, #4886FF 0%, #03429F 100%)",
              paddingTop: "25px",
              marginTop: "auto",
              borderRadius: "33px 33px 0px 0px",
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: 1,
                bottom: 0,
                width: "calc(100% - 2px)",
                height: "calc(100% - 1px)",
                background: "#2B2D25",
                borderRadius: "33px 33px 0px 0px",
              }}
            />
            <BoostDetailsPopup
              title="GOBBL SURGE"
              description1="5X your Gobbl income for 20 seconds, while your hunger stays same."
              description2=""
              img="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/upgrade.png"
              price={0}
              openPopup={multiTapPopup}
              closePopup={() => {
                viberate("light");
                setMultiTapPopup(false);
              }}
              onClick={handleMultiTapFn}
            />
            <BoostDetailsPopup
              title="HUNGER MAX"
              description1="Max out Gobbl’s hunger & feed more."
              description2={<br />}
              img="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/hunger.png"
              price={0}
              openPopup={fullHungerPopup}
              closePopup={() => {
                viberate("light");
                setFullHungerPopup(false);
              }}
              onClick={handleFullHungerFn}
            />

            <Box
              style={{
                width: "100%",
                padding: "1px 0 0",
                marginTop: "-3px",
                zIndex: 2,
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  minHeight: 500,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  padding: xs ? "0 5%" : "0 5%",
                  marginTop: "-10px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "00",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <Button
                    style={{
                      minWidth: "auto",
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                    }}
                    onClick={closePopup}
                  >
                    <Close
                      style={{
                        fontSize: 24,
                        fill: "#faff00",
                        zIndex: 1,
                      }}
                    />
                  </Button>
                </Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "150%",
                    color: "#FAFF00",
                  }}
                >
                  Your Daily Boosters
                </Typography>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "25px",
                  }}
                >
                  <Button
                    style={{
                      width: "50%",
                      height: xs ? 55 : 60,
                      background:
                        "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                      borderRadius: "8px",
                      padding: "1px",
                    }}
                    onClick={() =>
                      !multiTapCloseCondition ? setMultiTapPopup(true) : null
                    }
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: !multiTapPopup && "#2B2D25",
                        borderRadius: "8px",
                        padding: "0 10px",
                      }}
                    >
                      <img
                        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/upgrade.png"
                        style={{ width: 41, height: 42, objectFit: "contain" }}
                      />
                      <Box
                        style={{
                          fontWeight: 700,
                          fontSize: 10,
                          lineHeight: "100%",
                          color: "#FAFF00",
                          fontFamily: "Karla",
                          textAlign: "left",
                        }}
                      >
                        GOBBL SURGE
                        <br />
                        <Typography
                          style={{
                            fontWeight: 800,
                            fontSize: "24px",
                            lineHeight: "28px",
                            color: multiTapCloseCondition
                              ? "#bdbdbd"
                              : "#64FF99",
                          }}
                        >
                          {surgeClaimsAvailableCount}/3
                        </Typography>
                        {timeToNextClaimSurge !== 0 && (
                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: 10,
                              lineHeight: "100%",
                              color: "#ffffff",
                              fontFamily: "Karla",
                              textAlign: "left",
                            }}
                          >
                            in {getTimeLeftFormatter(timeToNextClaimSurge)} hrs
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Button>
                  <Button
                    style={{
                      width: "50%",
                      height: xs ? 55 : 60,
                      background:
                        "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                      borderRadius: "8px",
                      padding: "1px",
                    }}
                    onClick={() =>
                      !fullHungerCloseCondition
                        ? setFullHungerPopup(true)
                        : null
                    }
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: !fullHungerPopup && "#2B2D25",
                        borderRadius: "8px",
                        padding: "0 10px",
                      }}
                    >
                      <img
                        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/hunger.png"
                        style={{ width: 41, height: 42, objectFit: "contain" }}
                      />
                      <Box
                        style={{
                          fontWeight: 700,
                          fontSize: 10,
                          lineHeight: "100%",
                          color: "#FAFF00",
                          fontFamily: "Karla",
                          textAlign: "left",
                        }}
                      >
                        HUNGER MAX
                        <br />
                        <Typography
                          style={{
                            fontWeight: 800,
                            fontSize: "24px",
                            lineHeight: "28px",
                            color: fullHungerCloseCondition
                              ? "#bdbdbd"
                              : "#64FF99",
                          }}
                        >
                          {hungerClaimsAvailableCount}/3
                        </Typography>
                        {timeToNextClaimHunger !== 0 && (
                          <Typography
                            style={{
                              fontWeight: 700,
                              fontSize: 10,
                              lineHeight: "100%",
                              color: "#ffffff",
                              fontFamily: "Karla",
                              textAlign: "left",
                            }}
                          >
                            in {getTimeLeftFormatter(timeToNextClaimHunger)} hrs
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Button>
                </Box>
                <Box
                  style={{
                    width: "66%",
                    height: "0px",
                    opacity: 0.2,
                    border: "1px solid #FFFFFF",
                    margin: xs ? "3% auto" : "5% auto",
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "120%",
                    color: "#FAFF00",
                  }}
                >
                  Boosters
                </Typography>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <BoosterCard
                    title="FEED+1"
                    img="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/booster/upgrade_level.png"
                    price={
                      TAP_DATA[playLevels.tap + 1]
                        ? TAP_DATA[playLevels.tap + 1].coins
                        : 0
                    }
                    level={playLevels.tap + 1}
                    type={"tap"}
                    description1="Increase earnings per feed and unlock new dishes."
                    description2="+1 per feed for each level."
                    isFull={TAP_DATA[playLevels.tap + 1] ? false : true}
                  />
                  <BoosterCard
                    title="EXPAND TUMMY"
                    img="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/booster/expand_tummy.png"
                    price={
                      ENERGY_LIMIT_DATA[playLevels.energy + 1]
                        ? ENERGY_LIMIT_DATA[playLevels.energy + 1].coins
                        : 0
                    }
                    level={playLevels.energy + 1}
                    type={"energy"}
                    description1="Size up Gobbl’s stomach so he can eat more at one time."
                    description2="+500 Tummy Expand for each level."
                    isFull={
                      ENERGY_LIMIT_DATA[playLevels.energy + 1] ? false : true
                    }
                  />
                  <BoosterCard
                    title="HUNGER PANGS"
                    img="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/booster/hunger_pangs.png"
                    price={
                      RECHARGE_SPEED_DATA[playLevels.recharge + 1]
                        ? RECHARGE_SPEED_DATA[playLevels.recharge + 1].coins
                        : 0
                    }
                    level={playLevels.recharge + 1}
                    type={"recharge"}
                    description1="Get Gobbl hungry faster! More Hunger Pangs mean Gobbl feeds more."
                    description2="+1 per second."
                    isFull={
                      RECHARGE_SPEED_DATA[playLevels.recharge + 1]
                        ? false
                        : true
                    }
                  />
                </Box>
              </Box>
            </Box>

            {/* <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/spin_page_bg.svg"
              style={{
                minWidth: "100vw",
                height: "85vh",
                position: "absolute",
                left: 0,
                bottom: 0,
                objectFit: "cover",
                objectPosition: "top",
                boxShadow: "0px -19px 24px rgba(0, 0, 0, 0.5)",
                borderRadius: "33px 33px 0px 0px",
              }}
            /> */}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Boost;
