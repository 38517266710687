import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ProgressBar from "./ProgressBar";
import { LEAGUE_TASKS_DATA } from "../utils/constants";
import { numberWithCommas } from "../actions/helperFn";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

const TapScoreSection = ({}) => {
  let homeScore = 1000;

  const score = useSelector((state) => state.ui.score);
  const leagueLevel = useSelector((state) => state.ui.leagueLevel);

  const leagueLevelData = useMemo(() => {
    return leagueLevel === 14
      ? LEAGUE_TASKS_DATA[leagueLevel]
      : LEAGUE_TASKS_DATA[leagueLevel];
  }, [leagueLevel]);

  return (
    <Box
      style={{
        width: "90%",
        height: "87px",
        padding: "1px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        style={{
          fontFamily: "Rubik",
          fontWeight: 600,
          fontSize: "40px",
          lineHeight: "100%",
          color: "#FAFF00",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
          style={{
            width: 35,
            height: 35,
          }}
        />
        {numberWithCommas(score)}
      </Typography>

      <Link to="/league" prefetch="false">
        <Box
          style={{
            width: "max-content",
            minWidth: 115,
            height: 28,
            background:
              leagueLevel === 1
                ? "#9EAB08"
                : leagueLevel === 2
                ? "#1CB172"
                : leagueLevel === 3
                ? "#0DB2BC"
                : leagueLevel === 4
                ? "#5339EF"
                : leagueLevel === 5
                ? "#AA2CD6"
                : leagueLevel === 6
                ? "#D62C88"
                : leagueLevel === 7
                ? "#FF5C00"
                : leagueLevel === 8
                ? "#D1BD07"
                : leagueLevel === 9
                ? "#59B200"
                : "#D6672C",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "5px",
            borderRadius: "8px",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "2px",
              fontFamily: "'Karla'",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "16px",
              color: "#FFFFFF",
              whiteSpace: "nowrap",
            }}
          >
            <img
              src={leagueLevelData.img}
              style={{
                width: 26,
                height: 24,
                objectFit: "contain",
                transform:
                  leagueLevel % 2 == 0 ? "rotate(12deg)" : "rotate(-12deg)",
              }}
            />
            Level {leagueLevel + 1}: {leagueLevelData.title}
          </Box>
          <KeyboardArrowRight style={{ color: "#fff" }} />
        </Box>
      </Link>
    </Box>
  );
};

export default TapScoreSection;
