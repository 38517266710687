import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grow,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import Slider from "react-slick";

import { useTheme } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const onboardingData = [
  {
    title: "GOBBL TO GLORY",
    description:
      "Join the biggest food airdrop to launch $GOBBL, powering the world's first foodchain.",
    src: "/images/onboard_slider1.png",
  },
  {
    title: "build your food empire",
    description:
      "Earn daily profits by unlocking Restaurants. The key?  Dishes, Chips  & ofcourse, the Chef!",
    src: "/images/onboard_slider2.png",
  },
  {
    title: "SERVE PIPIN' HOT DISHES",
    description:
      "Collect & level up Dish Cards for supreme powers- from restaurant upgrades to game battles.",
    src: "/images/onboard_slider3.png",
  },
  {
    title: "BATTLE & EARN CHIPS",
    description:
      "Game to earn Chips with every victory- race to deliver in Food Fury, or battle your wits in Food5.",
    src: "/images/onboard_slider4.png",
  },
  {
    title: "BECOME A CHEF LEGEND",
    description:
      "Rise through the kitchen ranks to Chef Legend. Tap to the top for exclusive privileges- Lets go!",
    src: "/images/onboard_slider5.png",
  },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 5,
      }}
      onClick={onClick}
    >
      <Button
        style={{
          position: "absolute",
          maxWidth: 24,
          minWidth: 24,
          top: -150,
          right: 40,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: !onClick && 0,
        }}
      >
        <img
          src="/images/next_icon.svg"
          style={{ maxWidth: 24, minWidth: 24, zIndex: 5 }}
        />
      </Button>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 5,
      }}
      onClick={onClick}
    >
      <Button
        style={{
          position: "absolute",
          maxWidth: 24,
          minWidth: 24,
          top: -150,
          left: 40,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: !onClick && 0,
        }}
      >
        <img
          src="/images/prev_icon.svg"
          style={{ maxWidth: 24, minWidth: 24, zIndex: 5 }}
        />
      </Button>
    </div>
  );
}

const HowToPlayPopup = ({ openPopup, closePopup }) => {
  const theme = useTheme();
  const sliderRef = useRef();

  const xs = useMediaQuery(theme.breakpoints.down(390));

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "ease",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Dialog
      open={openPopup}
      onClose={closePopup}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      maxWidth="lg"
      fullWidth={false}
      style={{
        zIndex: 14,
      }}
    >
      <Box
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",

              position: "relative",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: "10%",
              marginTop: "15vh",
              borderRadius: "33px 33px 0px 0px",
              zIndex: 1111,
              overflow: "hidden",
              paddingBottom: "60px",
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "15px 5% 0",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Button
                style={{
                  minWidth: "auto",
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                }}
                onClick={closePopup}
              >
                <Close
                  style={{
                    fontSize: 24,
                    fill: "#faff00",
                    zIndex: 1,
                  }}
                />
              </Button>
            </Box>

            <Box
              style={{
                width: "100%",
                height: "100%",
                zIndex: 2,
              }}
            >
              <Slider {...settings} ref={sliderRef}>
                {onboardingData.map((data, i) => (
                  <Box
                    key={i}
                    style={{
                      width: "100%",
                      zIndex: 2,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      style={{
                        maxWidth: 240,
                        fontFamily: "'Rubik'",
                        fontWeight: 900,
                        fontSize: "32px",
                        lineHeight: "36px",
                        textAlign: "center",
                        color: "#FAFF00",
                        margin: "15px auto 20px",
                        textTransform: "uppercase",
                      }}
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      style={{
                        maxWidth: 290,
                        margin: "0 auto",
                        fontFamily: "'Karla'",
                        fontWeight: 400,
                        fontSize: 18,
                        lineHeight: "22px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        position: "relative",
                      }}
                    >
                      {data.description}
                    </Typography>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        onClick={() => {
                          //Indexing starts from 0
                          if (i === 4) {
                            closePopup();
                          } else {
                            sliderRef.current.slickGoTo(i + 1);
                          }
                        }}
                        style={{
                          width: "fit-content",
                          height: 36,
                          background: "#64ff99",
                          borderRadius: "12px",
                          fontFamily: "Rubik",
                          fontWeight: 700,
                          fontSize: "16px",
                          textAlign: "center",
                          color: "#000000",
                          marginTop: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        {i === 0 && "START"}
                        {i != 0 && i != 4 && "NEXT"}
                        {i === 4 && "START MY GOBBL JOURNEY"}
                      </Button>
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                        height: 305,
                        position: "relative",
                      }}
                    >
                      <img
                        src={data.src}
                        style={{
                          width: "100vw",
                          height: i == 1 ? 220 : i == 2 ? 200 : 250,
                          objectFit: i == 4 ? "cover" : "contain",
                          position: "absolute",
                          bottom: i == 2 || i == 4 ? 80 : 40,
                          // marginLeft: i == 3 && "-20px",
                        }}
                      />
                      {(i == 1 || i == 2 || i == 3) && (
                        <svg
                          width="33"
                          height="45"
                          viewBox="0 0 33 45"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            transform: "rotate(-15deg)",
                            bottom: 98,
                            left: i == 1 || i == 2 ? 25 : 100,
                          }}
                        >
                          <path
                            d="M8.94533 44.6641C9.42035 44.9458 10.0338 44.7891 10.3155 44.3141L14.9066 36.5731C15.1883 36.0981 15.0316 35.4847 14.5566 35.2029C14.0816 34.9212 13.4681 35.0779 13.1864 35.5529L9.10545 42.4338L2.22459 38.3529C1.74957 38.0711 1.1361 38.2278 0.854374 38.7029C0.572646 39.1779 0.729343 39.7913 1.20437 40.0731L8.94533 44.6641ZM32.0539 1.91521C32.5584 1.69049 32.7852 1.09935 32.5605 0.594845C32.3358 0.0903434 31.7446 -0.13647 31.2401 0.0882444L32.0539 1.91521ZM10.4243 43.5565C7.97804 33.9793 8.87007 12.2417 32.0539 1.91521L31.2401 0.0882444C6.83868 10.9571 5.90003 33.9252 8.48655 44.0515L10.4243 43.5565Z"
                            fill="#64FF99"
                          />
                        </svg>
                      )}
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Box>

            <img
              src="/images/spin_page_bg.svg"
              style={{
                minWidth: "100vw",
                height: "85vh",
                position: "absolute",
                left: 0,
                top: 0,
                objectFit: "cover",
                objectPosition: "top",
                boxShadow: "0px -19px 24px rgba(0, 0, 0, 0.5)",
                borderRadius: "33px 33px 0px 0px",
                zIndex: 0,
              }}
            />
          </div>
        </Box>
      </Box>
    </Dialog>
  );
};

export default HowToPlayPopup;
