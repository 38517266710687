import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useTelegramSDK from "../hooks/useTelegramSDK";
import useGameHook from "../hooks/useGameHook";
import { useDispatch, useSelector } from "react-redux";
import useEnergyRestoreHook from "../hooks/useEnergyRestoreHook";
import { removeSignUpGift } from "../reducers/UiReducers";
import { useLogClickEvent } from "../hooks/useLogClickEvent";

const Navbar = ({ bg }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { pathname } = useLocation();
  const { logClickEvent } = useLogClickEvent();
  const { viberate } = useTelegramSDK();
  const hook1 = useEnergyRestoreHook();
  const {} = useGameHook(true);
  const screenLoaded = useSelector((state) => state.ui.screenLoaded);
  const signUpGift = useSelector((state) => state.ui.signUpGift);

  const navLinks = [
    {
      url: "/dishes",
      image: "/images/nav_dishes.png",
      name: "Dishes",
    },
    {
      url: "/",
      image: "/images/nav_games.png",
      name: "Games",
      event: true,
    },
    {
      url: "/tap",
      image:
        "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.webp",
      name: "Tap",
    },
    {
      url: "/tasks",
      image: "/images/nav_tasks.png",
      name: "Tasks",
    },
    {
      url: "/referral",
      image: "/images/nav_invite.png",
      name: "Invite",
    },
  ];

  return (
    <>
      {screenLoaded && (
        <Box
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: 65,
            zIndex: 15,
            background: bg,
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: "0 5px",
              background:
                bg &&
                "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
            }}
          >
            {navLinks.map((nav, i) => (
              <Box
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "relative",
                  borderRadius: "32px",
                }}
                onClick={() => {
                  viberate("light");
                  if (signUpGift?.length === 0) {
                    navigate(nav.url);
                    nav.event && logClickEvent("Games_Tab");
                  }
                }}
              >
                {pathname === nav.url && (
                  <img
                    src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/nav_active.svg"
                    style={{
                      position: "absolute",
                      width: 78,
                      height: 78,
                      zIndex: 0,
                      top: -15,
                    }}
                  />
                )}
                {nav?.new && (
                  <img
                    src="/images/new_icon.svg"
                    style={{
                      position: "absolute",
                      width: 60,
                      height: 23,
                      zIndex: 2,
                      top: 3,
                      left: -2,
                      pointerEvents: "none",
                    }}
                  />
                )}
                <img
                  src={nav.image}
                  style={{
                    height: 34,
                    objectFit: "contain",
                    zIndex: 1,
                    background: "transparent",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "'Rubik'",
                    fontWeight: pathname === nav.url ? 700 : 400,
                    fontSize: "11px",
                    color: pathname === nav.url ? "#64FF99" : "#FFFFFF",
                    zIndex: 1,
                    background: "transparent",
                  }}
                >
                  {nav.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Navbar;
