import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewTonWithdrawToBackend,
  getUserLeaderboardData,
  upgradeBoosterToBackend,
} from "../actions/serverActions";

const initialState = {
  chips: 0,
  score: 0,
  tapScore: 0,
  totalGobbls: 0,
  tonBalance: 0,
  pendingTON: 0,
  withdrawnTON: 0,
  tonPendingTimestamp: -1,
  tonSuccessTimestamp: -1,
  leagueLevel: 0,
  energyLeft: 500,
  spinsLeft: 0,
  spinsReceived: 0,
  boxClaimedFlag: false,
  boxUnlockTimer: 0,
  username: "",
  profilePic: "",
  userAddress: "",
  energyLastUpdatedAt: 0,
  multiTapFlag: false,
  multiTapStamp: [],
  fullHungerStamp: [],

  specialTasksStatus: [],
  leagueTasksStatus: [],
  refTasksStatus: [],
  dailyTaskStatus: [],

  referralCount: 0,
  referralPoints: 0,

  withdrawActivities: [],
  signUpGift: [],

  playLevels: {
    tap: 0,
    energy: 0,
    recharge: 0,
  },
  playValues: {
    tap: 0,
    energy: 0,
    recharge: 0,
  },

  successPopup: false,
  errorMsg: "",
  errorPopup: false,

  taskRewards: [],
  dataLoaded: false,
  screenLoaded: false,
  isMuted: true,
  isBanned: false,
  isTapAvailable: true,
  refetchData: 0,

  //Daily Boxes
  claimsThisWeek: 0,
  nextClaimTimestamp: 0,

  dailyBoxOpen: false,
};

// Function:: get backend data and update redux
export const getBackendDataToRedux = createAsyncThunk(
  "getBackendDataToRedux",
  async (userId) => {
    try {
      let response = await getUserLeaderboardData(userId);
      console.log(response);

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  }
);

// Function: To upgrade booster
export const upgradeBoosterRedux = createAsyncThunk(
  "upgradeBoosterRedux",
  async (dataObj) => {
    try {
      let response = await upgradeBoosterToBackend(dataObj);

      if (response?.error === false) {
        return {
          type: dataObj?.type,
          points: response.msg,
        };
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  }
);

// Function: To create TON Withdraw Request
export const createWithdrawTonRequest = createAsyncThunk(
  "createWithdrawTonRequest",
  async (userId) => {
    try {
      let response = await createNewTonWithdrawToBackend(userId);

      if (response?.error === false) {
        return response.msg;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  }
);

const UiReducer = createSlice({
  name: "ui",
  initialState,

  reducers: {
    updateIsTapAvailable(state, action) {
      state.isTapAvailable = action.payload;
    },
    updateDailyBoxOpen(state, action) {
      state.dailyBoxOpen = action.payload;
    },

    updateTaskRewards(state, action) {
      state.taskRewards = action.payload;
    },

    activeSignupGift(state, action) {
      state.signUpGift = action.payload;
    },

    handleTapClickRedux(state, action) {
      state.score += action.payload;
      state.tapScore += action.payload;
    },
    handleUpdateTapScoreRedux(state, action) {
      state.score += action.payload;
      state.tapScore += action.payload;
    },
    updateScore(state, action) {
      state.score = action.payload;
    },
    updateTapScore(state, action) {
      state.tapScore = action.payload;
    },

    updateUserWalletAddress(state, action) {
      state.userAddress = action.payload;
    },

    updateReferralCount(state, action) {
      state.referralCount = action.payload;
    },
    updateBoxClaimedFlag(state, action) {
      state.boxClaimedFlag = action.payload;
    },
    updateSpinLeftCount(state, action) {
      state.spinsLeft = action.payload;
    },

    updateReferralPoints(state, action) {
      state.referralPoints = action.payload;
    },
    updateTonBalance(state, action) {
      state.tonBalance = action.payload;
    },
    updateFullHungerStamp(state, action) {
      state.fullHungerStamp = action.payload;
    },
    updateMultiTapStamp(state, action) {
      state.multiTapStamp = action.payload;
    },
    updateScreenLoaded(state, action) {
      state.screenLoaded = action.payload;
    },
    updateIsMuted(state, action) {
      state.isMuted = action.payload;
    },
    updateMultiTap(state, action) {
      state.multiTapFlag = action.payload;
    },
    updateSpecialTaskStatusState(state, action) {
      state.specialTasksStatus = action.payload;
    },
    updateLeagueTaskStatusState(state, action) {
      state.leagueTasksStatus = action.payload;
    },
    updateRefTaskStatusState(state, action) {
      state.refTasksStatus = action.payload;
    },
    updateLeagueLevel(state, action) {
      state.leagueLevel = action.payload;
    },
    updateEnergyLeft(state, action) {
      state.energyLeft += action.payload;
    },
    setEnergyLeft(state, action) {
      state.energyLeft = action.payload;
    },
    updatePlayLevels(state, action) {
      state.playLevels = action.payload;
    },
    updatePlayValues(state, action) {
      state.playValues = action.payload;
    },

    setSuccessPopup(state, action) {
      state.successPopup = action.payload;
    },
    setErrorPopup(state, action) {
      state.errorPopup = action.payload.flag;
      state.errorMsg = action.payload.msg;
    },

    setIsBannedFlag(state, action) {
      state.isBanned = action.payload;
    },
    updateRefetchData(state, action) {
      state.refetchData += 1;
    },
    removeSignUpGift(state, action) {
      state.signUpGift = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBackendDataToRedux.fulfilled, (state, action) => {
      const response = action.payload;
      if (response) {
        state.chips = response.chips;
        state.score = response.score;
        state.tapScore = response.tapScore;
        state.tonBalance = response.totalTON;
        state.pendingTON = response.pendingTON;
        state.withdrawnTON = response.withdrawnTON;
        state.tonPendingTimestamp = response.lastPendingActivity;
        state.tonSuccessTimestamp = response.lastSuccessActivity;

        state.userAddress = response.externalWalletAddress;

        state.leagueLevel = response.leagueLevel;
        state.spinsLeft = response.spins?.spinsLeft;
        state.spinsReceived = response.spins?.spinsReceived;
        state.boxClaimedFlag = response.boxClaimed;
        state.boxUnlockTimer = response.nextBoxUnlockTime;

        state.playLevels = response.playLevels;
        state.playValues = response.playValues;

        //Tasks states
        state.specialTasksStatus = response.specialTasksStatus;
        state.leagueTasksStatus = response.leagueTasksStatus;
        state.refTasksStatus = response.refTasksStatus;
        state.dailyTaskStatus = response.dailyTaskStatus;

        //Handle referrals
        state.referralCount = response.totalReferrals;
        state.referralPoints = response.referralPoints;

        //Daily boxes
        state.claimsThisWeek = response.claimsThisWeek;
        state.nextClaimTimestamp = response.nextClaimTimestamp;

        //timeStamps
        state.energyLastUpdatedAt = response.energyLastUpdatedAt;
        state.multiTapStamp = response.multiTapStamp;
        state.fullHungerStamp = response.fullHungerStamp;

        // Handle energy left
        if (
          response.playValues &&
          response.energyLastUpdatedAt &&
          response.energyLeft
        ) {
          //Calculate time difference
          let currentTimestamp = Number(Date.now());

          const lastUpdated = Number(response.energyLastUpdatedAt);
          const timeDiffInSeconds = Math.max(
            0,
            (currentTimestamp - lastUpdated) / 1000
          );

          let newEnergyValue = Math.min(
            parseInt(response.energyLeft) +
              response.playValues.recharge * parseInt(timeDiffInSeconds),
            response.playValues.energy
          );

          state.energyLeft = Number.isInteger(newEnergyValue)
            ? newEnergyValue
            : 0;
        }
        // Basic data
        state.username = response.username;
        state.profilePic = response.profilePic;

        state.isBanned = response.banned;
        state.dataLoaded = true;
      }
    });

    builder.addCase(upgradeBoosterRedux.fulfilled, (state, action) => {
      const response = action.payload;
      if (response?.type === "tap") {
        state.score = state.score - response.points;
        state.playLevels = {
          ...state.playLevels,
          tap: state.playLevels.tap + 1,
        };
      }
      if (response?.type === "recharge") {
        state.score = state.score - response.points;
        state.playLevels = {
          ...state.playLevels,
          recharge: state.playLevels.recharge + 1,
        };
      }
      if (response?.type === "energy") {
        state.score = state.score - response.points;
        state.playLevels = {
          ...state.playLevels,
          energy: state.playLevels.energy + 1,
        };
      }
    });

    builder.addCase(createWithdrawTonRequest.fulfilled, (state, action) => {
      const response = action.payload;
      console.log(response);
      if (response !== null) {
        state.tonBalance = response.totalTON;
        state.pendingTON = response.pendingTON;
        state.withdrawnTON = response.withdrawnTON;
        state.tonPendingTimestamp = response.lastPendingActivity;
        state.tonSuccessTimestamp = response.lastSuccessActivity;
      }
    });
  },
});

const { actions } = UiReducer;

export const {
  updateScore,
  updateTapScore,
  updateTonBalance,
  updateUserWalletAddress,
  updateBoxClaimedFlag,
  updateSpinLeftCount,
  updateReferralPoints,
  updateReferralCount,
  updateScreenLoaded,
  updateIsMuted,
  updateMultiTap,
  updateLeagueLevel,
  updateEnergyLeft,
  setEnergyLeft,
  updatePlayLevels,
  updatePlayValues,
  setSuccessPopup,
  updateFullHungerStamp,
  updateMultiTapStamp,
  updateSpecialTaskStatusState,
  updateLeagueTaskStatusState,
  updateRefTaskStatusState,
  handleTapClickRedux,
  handleUpdateTapScoreRedux,
  updateIsTapAvailable,
  setIsBannedFlag,
  updateRefetchData,
  activeSignupGift,
  setErrorPopup,
  updatePopupErrorMsg,
  removeSignUpGift,
  updateTaskRewards,
  updateDailyBoxOpen,
} = actions;

export default UiReducer;
