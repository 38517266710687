import { Squircle } from "corner-smoothing";
import { Children } from "react";
import styled from "styled-components";

const StyledSquircle = styled.div`
  display: inline-block;
  width: 85%;

  /* Border color */

  background: linear-gradient(45deg, red, red);
  boxshadow: 0px 4px 0px rgba(139, 139, 139, 0.3);
  ::before {
    /* Background color  */
    background: #fff;
  }
`;

// Create a Squircle with the `Squircle` component:
// background: linear-gradient(180deg, #ff97ff 0%, #cc37cc 100%);
export const SmoothCardComponent = ({ children }) => {
  return (
    <Squircle cornerRadius={25} borderWidth={4} as={StyledSquircle}>
      {children}
    </Squircle>
  );
};
