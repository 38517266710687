import React, { useMemo, useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import ProgressBar from "../components/ProgressBar";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LEAGUE_TASKS_DATA } from "../utils/constants";
import Slider from "react-slick";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useDispatch, useSelector } from "react-redux";
import { updateRefetchData, updateTaskRewards } from "../reducers/UiReducers";
import { useServerAuth } from "../hooks/useServerAuth";
import {
  getNumbersInFormatOnlyMillions,
  numberWithCommas,
} from "../actions/helperFn";
import { setLeagueClaimToBackend } from "../actions/serverActions";
import { useTheme } from "@mui/styles";
import TaskRewardPopup from "../components/TaskRewardPopup";

const StarSvg = ({ color, size, style }) => {
  return (
    <svg
      className="star"
      width={size ? size : "20"}
      height={size ? size : "20"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute", zIndex: -1, ...style }}
    >
      <path
        d="M15.9972 32C15.6561 31.9998 15.3225 31.9002 15.0371 31.7134C14.7517 31.5265 14.5269 31.2605 14.3903 30.9479L11.9855 25.4252C10.9306 22.9978 8.99416 21.0611 6.56709 20.006L1.05202 17.6037C0.739339 17.4672 0.473261 17.2425 0.286397 16.957C0.0995322 16.6715 1.35528e-07 16.3377 1.32697e-07 15.9966C1.29867e-07 15.6554 0.0995321 15.3216 0.286397 15.0361C0.473261 14.7506 0.739339 14.5259 1.05202 14.3894L6.57399 11.9843C8.99687 10.9304 10.9305 8.99746 11.9855 6.57479L14.3903 1.05208C14.5269 0.739479 14.7517 0.473481 15.0371 0.286628C15.3225 0.0997753 15.6561 0.000168638 15.9972 -1.32703e-07C16.3385 7.57583e-05 16.6724 0.0996276 16.9581 0.286476C17.2437 0.473324 17.4687 0.73937 17.6055 1.05208L20.009 6.57479C21.0643 8.99981 23.0002 10.9341 25.426 11.9871L30.948 14.3922C31.2607 14.5287 31.5267 14.7534 31.7136 15.0389C31.9005 15.3243 32 15.6581 32 15.9993C32 16.3405 31.9005 16.6743 31.7136 16.9598C31.5267 17.2452 31.2607 17.47 30.948 17.6064L25.426 20.0102C22.9994 21.0647 21.0634 23.001 20.009 25.428L17.6055 30.9507C17.4683 31.2629 17.2431 31.5283 16.9575 31.7147C16.6719 31.901 16.3382 32.0002 15.9972 32Z"
        fill={color ? color : "#D1FF19"}
      />
    </svg>
  );
};

function NextArrow(props) {
  const { viberate } = useTelegramSDK();
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={() => {
        if (onClick) {
          viberate("light");
          onClick();
        }
      }}
    >
      <Button
        style={{
          position: "absolute",
          top: 10,
          right: 0,
          minWidth: 30,
          maxWidth: 30,
          minHeight: 30,
          maxHeight: 30,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          filter: "drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.25))",
          zIndex: 5,
          opacity: !onClick && 0.5,
        }}
      >
        <img
          src="/images/next_icon.svg"
          style={{ maxWidth: 24, minWidth: 24 }}
        />
      </Button>
    </div>
  );
}

function PrevArrow(props) {
  const { viberate } = useTelegramSDK();
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={() => {
        if (onClick) {
          viberate("light");
          onClick();
        }
      }}
    >
      <Button
        style={{
          position: "absolute",
          top: 10,
          left: 0,
          minWidth: 30,
          maxWidth: 30,
          minHeight: 30,
          maxHeight: 30,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          filter: "drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.25))",
          zIndex: 5,
          opacity: !onClick && 0.5,
        }}
      >
        <img
          src="/images/prev_icon.svg"
          style={{ maxWidth: 24, minWidth: 24 }}
        />
      </Button>
    </div>
  );
}

const League = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(390));
  const dispatch = useDispatch();
  const tapScore = useSelector((state) => state.ui.tapScore);
  const leagueLevel = useSelector((state) => state.ui.leagueLevel);

  const { accountSC } = useServerAuth();

  const [currentSlideNumber, setCurrentSlideNumber] = useState(
    leagueLevel === 14 ? 14 : leagueLevel
  );

  const settings = {
    initialSlide: leagueLevel === 14 ? 14 : leagueLevel,
    fade: true,
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const isLeagueLocked = useMemo(() => {
    let oldTapRequired = LEAGUE_TASKS_DATA[currentSlideNumber].startRequired;

    return tapScore < oldTapRequired;
  }, [currentSlideNumber, tapScore]);

  const claimButtonVisibleStatus = useMemo(() => {
    let isEligible =
      tapScore >= LEAGUE_TASKS_DATA[currentSlideNumber].tapRequired &&
      leagueLevel === currentSlideNumber &&
      leagueLevel !== 14;

    if (isEligible) {
      return true;
    } else {
      return false;
    }
  }, [currentSlideNumber, leagueLevel]);

  const getProgressBarValue = useMemo(() => {
    let n = tapScore - LEAGUE_TASKS_DATA[currentSlideNumber].startRequired;
    let d =
      LEAGUE_TASKS_DATA[currentSlideNumber].tapRequired -
      LEAGUE_TASKS_DATA[currentSlideNumber].startRequired;
    let percentage = (100 * n) / d;
    return percentage;
  }, [currentSlideNumber, tapScore]);

  // Claim Rewards
  const onClickClaimLeague = async (currentSlide) => {
    // Update status to progress
    // Upgrade booster by type to backendAPI
    console.log("Calling here");
    let dataObj = {
      userId: accountSC,
      type: "league",
      taskId: currentSlide,
    };
    let res = await setLeagueClaimToBackend(dataObj);
    if (res?.error === false) {
      await dispatch(updateTaskRewards(res.result));
      await dispatch(updateRefetchData());
    }

    if (res) {
      await dispatch(updateRefetchData());
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "calc(100vh - 0px)",
        height: "100%",
        position: "relative",
        background: "#161811",
        paddingBottom: "60px",
        zIndex: 0,
        overflow: "hidden",
      }}
    >
      <TaskRewardPopup />
      <Box
        style={{
          width: "94%",
          height: "calc(100vh - 10%)",
          marginLeft: "3%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "5px",
          paddingBottom: 35,
        }}
      >
        <Box
          style={{
            width: "90%",
            minHeight: 380,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            margin: "auto",
          }}
        >
          <Box style={{ width: "100%", position: "relative", zIndex: 1 }}>
            <Slider
              {...settings}
              beforeChange={(currentSlide, nextSlide) =>
                setCurrentSlideNumber(nextSlide)
              }
            >
              {LEAGUE_TASKS_DATA.slice(0, -1).map((ele, i) => (
                <Box
                  key={i}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "5px",
                    position: "relative",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "38px",
                      display: "flex",
                      alignItems: "center",
                      color: "#64FF99",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {ele.title}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "19px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      opacity: 0.8,
                    }}
                  >
                    Your level determines your $GOBBL
                    <br />
                    token allocation.
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      minHeight: 300,
                      maxHeight: 300,
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ele.img}
                      alt="Gobbl"
                      style={{
                        minHeight: 300,
                        maxHeight: 300,
                        width:
                          i == 0 || i == 4 || i == 5
                            ? "80%"
                            : i == 2 || i == 3 || i == 6
                            ? "85%"
                            : "100%",
                        objectFit: "contain",
                        margin: "auto",
                        marginTop: i == 4 ? "10px" : i == 5 ? "0px" : "5px",
                        opacity: isLeagueLocked ? 0.5 : 1,
                      }}
                    />
                    {isLeagueLocked && (
                      <img
                        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/league_lock.svg"
                        style={{
                          width: 90,
                          height: 120,
                          position: "absolute",
                        }}
                      />
                    )}
                  </Box>
                  {!isLeagueLocked && (
                    <Box
                      style={{
                        width: "100%",
                        height: 55,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: (i == 4 || i == 5) && "-10px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        {ele.id != 14 && (
                          <Typography
                            style={{
                              width: "100%",
                              fontFamily: "Rubik",
                              fontWeight: 500,
                              fontSize: "22px",
                              color: "#D1FF1A",
                            }}
                          >
                            {tapScore > LEAGUE_TASKS_DATA[ele.id].tapRequired
                              ? getNumbersInFormatOnlyMillions(
                                  LEAGUE_TASKS_DATA[ele.id].tapRequired
                                )
                              : getNumbersInFormatOnlyMillions(tapScore)}
                            <span style={{ fontSize: 17 }}>
                              /
                              {numberWithCommas(
                                LEAGUE_TASKS_DATA[ele.id].tapRequired
                              )}
                            </span>
                          </Typography>
                        )}

                        {ele.id === 14 && (
                          <Typography
                            style={{
                              width: "100%",
                              fontFamily: "'Rubik'",
                              fontWeight: 500,
                              fontSize: "22px",
                              color: "#D1FF1A",
                            }}
                          >
                            <span style={{ fontSize: 22, color: "#D1FF1A" }}>
                              {numberWithCommas(tapScore)}
                            </span>
                          </Typography>
                        )}
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                        }}
                      >
                        <ProgressBar value={getProgressBarValue} />
                      </Box>
                    </Box>
                  )}

                  {isLeagueLocked && (
                    <Box
                      style={{
                        width: "100%",
                        height: 55,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "100%",
                            fontFamily: "'Rubik'",
                            fontWeight: 500,
                            fontSize: "24px",
                            lineHeight: "28px",
                            color: "#D1FF1A",
                          }}
                        >
                          From{" "}
                          {numberWithCommas(
                            LEAGUE_TASKS_DATA[ele.id].startRequired
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Slider>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 65,
              zIndex: 1110,
            }}
          >
            {leagueLevel > currentSlideNumber && currentSlideNumber !== 14 && (
              <img
                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/promoted.svg"
                style={{
                  width: 195,
                  height: 51,
                  transform: "rotate(-6deg)",
                  marginTop: 15,
                }}
              />
            )}
            {claimButtonVisibleStatus && (
              <Button
                style={{
                  width: "195px",
                  height: 40,
                  background: "#FAFF00",
                  borderRadius: "12px",
                  fontFamily: "Rubik",
                  fontWeight: 700,
                  fontSize: "16px",
                  textAlign: "center",
                  color: "#000000",
                  marginTop: 15,
                }}
                onClick={() => onClickClaimLeague(currentSlideNumber)}
              >
                REWARD:{" "}
                {LEAGUE_TASKS_DATA[currentSlideNumber].rewards.map((ele) => {
                  return (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={
                          ele.type === "DISH"
                            ? "/images/task_dish_icon.svg"
                            : ele.type === "CHIPS"
                            ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                            : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                        }
                        style={{ width: 16, height: 16 }}
                      />
                      <Typography
                        style={{
                          padding: "0 2px",
                          fontFamily: "Rubik",
                          fontWeight: 700,
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "2px",
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        {getNumbersInFormatOnlyMillions(ele.amount)}
                      </Typography>
                    </Box>
                  );
                })}
              </Button>
            )}
          </Box>
          <Box>
            {claimButtonVisibleStatus && (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <StarSvg
                  size={22}
                  color="#D1FF1A"
                  style={{
                    top: "20%",
                    right: "18%",
                  }}
                />
                <StarSvg
                  size={15}
                  color="#fff"
                  style={{
                    top: "17%",
                    right: "14%",
                  }}
                />
                <StarSvg
                  size={15}
                  color="#D1FF1A"
                  style={{
                    bottom: "25%",
                    right: "19%",
                  }}
                />
                <StarSvg
                  size={15}
                  color="#fff"
                  style={{
                    top: "25%",
                    left: "14%",
                  }}
                />
                <StarSvg
                  size={22}
                  color="#D1FF1A"
                  style={{
                    bottom: "17%",
                    left: "8%",
                  }}
                />
                <StarSvg
                  size={15}
                  color="#fff"
                  style={{
                    bottom: "22%",
                    left: "14%",
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1px",
              position: "absolute",
              bottom: -60,
            }}
          >
            {LEAGUE_TASKS_DATA.map((_, i) => (
              <Box
                style={{
                  width: "fit-content",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: "1px",
                }}
              >
                <svg
                  width={
                    leagueLevel === i ? "16.5" : leagueLevel > i ? "15" : "14"
                  }
                  height={
                    leagueLevel === i ? "16.5" : leagueLevel > i ? "15" : "14"
                  }
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7505 11.2516V12.938V13.438H14.2505H3.7505H3.2505V12.938V11.2518C2.49132 10.8476 1.8701 10.2209 1.47271 9.45223C1.02409 8.58443 0.888114 7.58858 1.08767 6.63229C1.28724 5.676 1.81015 4.81764 2.5684 4.20171C3.32654 3.58585 4.27367 3.24994 5.25041 3.25048M14.7505 11.2516L5.25083 3.25048C5.25069 3.25048 5.25055 3.25048 5.25041 3.25048M14.7505 11.2516C15.5563 10.8219 16.2052 10.1423 16.5965 9.31069C17.0353 8.37817 17.1204 7.31799 16.8359 6.32743C16.5514 5.33687 15.9168 4.4834 15.05 3.92584C14.2959 3.44074 13.4108 3.20838 12.5221 3.25618C12.2418 2.64699 11.8072 2.11869 11.2584 1.72544L10.97 2.12797L11.2584 1.72544C10.6 1.25369 9.81044 1 9.0005 1C8.19057 1 7.40098 1.25369 6.74262 1.72544C6.19372 2.11874 5.75903 2.64715 5.47875 3.25645C5.40273 3.25242 5.32659 3.25043 5.25041 3.25048M14.7505 11.2516L5.25041 3.25048M14.749 14.0666L14.7526 13.563H14.249H3.752H3.24841L3.25202 14.0666C3.25575 14.5878 3.2702 15.0357 3.34123 15.4075C3.41493 15.7934 3.55593 16.1344 3.83645 16.4145C4.16451 16.7426 4.57403 16.8787 5.03797 16.9411C5.47977 17.0005 6.03801 17.0005 6.71518 17.0005H6.7505H11.2505H11.2858C11.963 17.0005 12.5212 17.0005 12.963 16.9411C13.4268 16.8787 13.8362 16.7427 14.1642 16.4149C14.445 16.1347 14.586 15.7935 14.6598 15.4075C14.7308 15.0357 14.7453 14.5878 14.749 14.0666Z"
                    stroke={
                      leagueLevel === i
                        ? "#FF9CFF"
                        : leagueLevel > i
                        ? "#FAFF00"
                        : ""
                    }
                    fill={
                      leagueLevel === i
                        ? "#FAFF00"
                        : leagueLevel > i
                        ? ""
                        : "#a1a1a1"
                    }
                  />
                </svg>
                {LEAGUE_TASKS_DATA.length !== i + 1 && (
                  <span
                    style={{
                      marginTop: "4px",
                      width: xs ? 3.5 : 4.5,
                      border: "0.7px solid #a1a1a1",
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
          {!claimButtonVisibleStatus && (
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/league_bg.svg"
              style={{
                position: "absolute",
                top: "-8%",
                zIndex: -1,
                minWidth: "200%",
                marginLeft: "-12%",
              }}
              className="rotate_img"
            />
          )}
          {claimButtonVisibleStatus && (
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/levelUp_bg.svg"
              style={{
                position: "absolute",
                top: "-8%",
                zIndex: -1,
                minWidth: "200%",
                marginLeft: "-12%",
              }}
              className="rotate_img"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default League;
