import axios from "axios";
import constants from "../utils/constants";
import CryptoJS from "crypto-js";

let apiUrl = constants.api_url;

// Encryption function
export const getCipherText = (inputBodyData) => {
  let secretKey = process.env.REACT_APP_CIPHER_KEY;

  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(inputBodyData), key, {
    mode: CryptoJS.mode.ECB,
  });

  const encryptedText = encrypted.toString();

  return { data: encryptedText };
};

// Get HMAC message
const getHmacMessageFromBody = (inputBodyData) => {
  const apiSecret = process.env.REACT_APP_HMAC_KEY;

  if (apiSecret) {
    const currentTimestamp = (Date.now() / 1000).toString();

    const hmacHash = CryptoJS.HmacSHA256(
      inputBodyData + currentTimestamp,
      apiSecret
    ).toString();

    return {
      hmacHash: hmacHash,
      currentTimestamp: currentTimestamp,
    };
  } else {
    return null;
  }
};
// *************** HMAC BASED APIS **************************

//1. USER:: GET User Leader Data by address
export const getUserLeaderboardData = async (userId) => {
  try {
    let requestParams = `userId=${userId}`;
    let url = `${apiUrl}/user/getUserData?${requestParams}`;
    // HMAC Response
    let hmacResponse = getHmacMessageFromBody(requestParams);

    if (!hmacResponse) {
      return null;
    }
    let axiosHeaders = {
      HMAC: hmacResponse.hmacHash,
      Timestamp: hmacResponse.currentTimestamp,
    };

    let response = await axios
      .get(url, { headers: axiosHeaders })
      .then((res) => res.data);

    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

//2. USER:: SignUp and Login POST Login user using telegram
export const loginTelegramUserFromBackendServer = async (
  method,
  handle,
  referId,
  isPremiumFlag,
  writePM
) => {
  let url = `${apiUrl}/user/signUpUser`;
  let data = {
    handle: handle,
    via: method,
    type: "TAP",
    referId: referId,
    isPremium: isPremiumFlag,
    canMessage: writePM,
  };

  console.log("data", data);
  //Encrypted data
  let encryptedData = getCipherText(data);

  let response = await axios
    .post(url, encryptedData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    console.log("error", response);
    return { error: true, result: response.result };
  }
};

//3. REFERRAL:: GET User referrals Data by address
export const getReferralsData = async (telegramId, pageNo) => {
  try {
    let requestParams = `telegramId=${telegramId}&&page=${pageNo}`;

    let url = `${apiUrl}/user/getReferredUsers?${requestParams}`;

    let response = await axios.get(url).then((res) => res.data);

    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

//4. USER:: Update TapScore to backend
export const updateTapScoreToBackendAPI = async (
  tapScore,
  energyLeft,
  energyLastUpdatedAt,
  userId
) => {
  let url = `${apiUrl}/user/updateUserTapScore`;

  let updateObj = {
    userId: userId,
    tapScore: tapScore,
    energyLeft: energyLeft,
    energyLastUpdatedAt: energyLastUpdatedAt,
  };

  //Encrypted data
  let encryptedData = getCipherText(updateObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return true;
  } else {
    console.log("error", response);
    return false;
  }
};

//5. USER:: Update username to backend
export const updateUsernameToBackendAPI = async (dataObj, userId) => {
  let url = `${apiUrl}/user/updateUsername`;

  let updateObj = { ...dataObj, userId: userId };
  //Encrypted data
  let encryptedData = getCipherText(updateObj);

  let response = await axios
    .post(url, encryptedData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return true;
  } else {
    console.log("error", response);
    return false;
  }
};

//6. DASHBOARD:: GET All Users Data
export const getDashboardData = async (userId) => {
  try {
    let requestParams = `userId=${userId}`;
    let url = `${apiUrl}/user/getDashboardData?${requestParams}`;

    // HMAC Response
    let hmacResponse = getHmacMessageFromBody(requestParams);

    if (!hmacResponse) {
      return null;
    }
    let axiosHeaders = {
      HMAC: hmacResponse.hmacHash,
      Timestamp: hmacResponse.currentTimestamp,
    };

    let response = await axios
      .get(url, { headers: axiosHeaders })
      .then((res) => res.data);
    console.log("res", response);
    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

// Boosters API
export const upgradeBoosterToBackend = async (dataObj) => {
  let url = `${apiUrl}/user/upgradeUserLevels`;

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, msg: response.result };
  } else {
    return { error: true, msg: 0 };
  }
};
export const upgradeTimeBoosterToBackend = async (dataObj) => {
  let url;
  if (dataObj.type === "tap") {
    url = `${apiUrl}/user/claimMultiTap`;
  } else {
    url = `${apiUrl}/user/claimFullHunger`;
  }

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, msg: response.result };
  } else {
    return { error: true, msg: 0 };
  }
};

// Tasks API
export const getTasksData = async (userId) => {
  try {
    let requestParams = `userId=${userId}`;
    let url = `${apiUrl}/user/getAllTasks?${requestParams}`;

    let response = await axios.get(url).then((res) => res.data);

    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const setTaskStatusToBackend = async (dataObj) => {
  let url = `${apiUrl}/user/setTaskCompleted`;

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: 0 };
  }
};
export const setDailyTaskStatusToBackend = async (dataObj) => {
  let url = `${apiUrl}/user/setDailyTaskCompleted`;

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: 0 };
  }
};

export const setLeagueClaimToBackend = async (dataObj) => {
  let url = `${apiUrl}/user/updateLeagueRefTasks`;

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
  if (!hmacResponse) {
    return false;
  }

  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: 0 };
  }
};

//7. USER:: Update wallet address of user
export const updateUserWalletAddressAPI = async (
  walletAdd,
  walletName,
  userId
) => {
  let url = `${apiUrl}/user/attachAddress`;

  let updateObj = {
    address: walletAdd,
    wallerProvider: walletName,
    userId: userId,
  };

  console.log(updateObj);
  //Encrypted data
  let encryptedData = getCipherText(updateObj);
  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
  if (!hmacResponse) {
    return false;
  }

  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return true;
  } else {
    console.log("error", response);
    return false;
  }
};

//8. USER:: Claim Spin Reward
export const claimSpinRewardAPI = async (userId) => {
  try {
    let url = `${apiUrl}/chest/spinWheel`;

    let updateObj = {
      userId: userId,
    };

    //Encrypted data
    let encryptedData = getCipherText(updateObj);
    // HMAC Response
    let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
    if (!hmacResponse) {
      return false;
    }

    let axiosHeaders = {
      HMAC: hmacResponse.hmacHash,
      Timestamp: hmacResponse.currentTimestamp,
    };

    let response = await axios
      .post(url, encryptedData, { headers: axiosHeaders })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });

    return response;
  } catch (err) {
    return { error: true };
  }
};

//10. USER:: TON Withdraw Request
export const createNewTonWithdrawToBackend = async (userId) => {
  let url = `${apiUrl}/user/withdrawTON`;

  let updateObj = {
    userId: userId,
  };

  //Encrypted data
  let encryptedData = getCipherText(updateObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
  if (!hmacResponse) {
    return false;
  }

  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, msg: response.result };
  } else {
    return { error: true, msg: 0 };
  }
};

//FoodFury: Game SignUp using telegram
export const signUpFoodFiveWithTG = async (handle, method, referId) => {
  let url = `${apiUrl}/foodfury/signUpPlayer`;
  let data = {
    via: method,
    handle: handle,
    referId: referId,
  };
  console.log(data);
  //Encrypted data
  let encryptedData = getCipherText(data);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => res.data);

  if (response && !response.error) {
    return response.result;
  } else {
    return false;
  }
};

//Dishes and Restaurant Section - Get userDishes
export const getUserDishes = async (userId, pageNo) => {
  try {
    let requestParams = `userId=${userId}&page=${pageNo}`;
    let url = `${apiUrl}/user/getUserAllDishes?${requestParams}`;

    let response = await axios.get(url).then((res) => res.data);

    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

// Level up dish
export const upgradeSingleDish = async (dishId, userId) => {
  let url = `${apiUrl}/user/upgradeDish`;

  let dataObj = {
    userId: userId,
    dishId: dishId,
  };
  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: 0 };
  }
};

//Daily Boxes API
//9. USER:: Claim Daily Box
export const claimDailyBoxReward = async (userId) => {
  let url = `${apiUrl}/chest/claimDailyBox`;

  let updateObj = {
    userId: userId,
  };

  //Encrypted data
  let encryptedData = getCipherText(updateObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));
  if (!hmacResponse) {
    return false;
  }

  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: 0 };
  }
};

//Shop APIs
export const buyClocheBackendAPI = async (userId, chestType) => {
  let url = `${apiUrl}/chest/buyChest`;

  let dataObj = {
    userId: userId,
    chestType: chestType,
  };
  console.log(dataObj);

  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: response.result };
  }
};

//get User Cloches
export const getUsersClochesData = async (userId) => {
  try {
    let requestParams = `userId=${userId}`;
    let url = `${apiUrl}/chest/getUserChests?${requestParams}`;

    let response = await axios.get(url).then((res) => res.data);

    if (response && response.result) {
      return response.result;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

//get User Cloches
export const unlockChestBackendAPI = async (userId, chestType, chips) => {
  let url = `${apiUrl}/chest/unlockChestWithChips`;

  let dataObj = {
    userId: userId,
    chestId: chestType,
    chips: chips,
  };

  console.log(dataObj);
  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: response.result };
  }
};

export const unlockChestFreeBackendAPI = async (userId, chestType) => {
  let url = `${apiUrl}/chest/unlockChest`;

  let dataObj = {
    userId: userId,
    chestId: chestType,
  };

  console.log(dataObj);
  //Encrypted data
  let encryptedData = getCipherText(dataObj);

  // HMAC Response
  let hmacResponse = getHmacMessageFromBody(JSON.stringify(encryptedData));

  if (!hmacResponse) {
    return null;
  }
  let axiosHeaders = {
    HMAC: hmacResponse.hmacHash,
    Timestamp: hmacResponse.currentTimestamp,
  };

  let response = await axios
    .post(url, encryptedData, { headers: axiosHeaders })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  if (response && !response.error) {
    return { error: false, result: response.result };
  } else {
    return { error: true, result: response.result };
  }
};
