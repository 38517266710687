import {
  Box,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
  Button,
  Fade,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ClocheBuyPopup from "./ClocheBuyPopup";
import { Squircle } from "corner-smoothing";
import { SmoothCardComponent } from "./SmoothCardComponent";
import {
  buyClocheBackendAPI,
  getUsersClochesData,
  unlockChestBackendAPI,
  unlockChestFreeBackendAPI,
} from "../actions/serverActions";
import { useServerAuth } from "../hooks/useServerAuth";
import ErrorSnackbar from "./ErrorSnackbar";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useDispatch, useSelector } from "react-redux";
import { setErrorPopup, updateRefetchData } from "../reducers/UiReducers";
import TimerComp from "./TimerComponent";

const ShopComponent = ({ setTabValue }) => {
  const theme = useTheme();
  const { accountSC } = useServerAuth();
  const { viberate } = useTelegramSDK();
  const dispatch = useDispatch();

  const refetchData = useSelector((state) => state.ui.refetchData);
  const chips = useSelector((state) => state.ui.chips);

  const [closhesData, setClochesData] = useState([]);
  const [claimedDishes, setClaimedDishes] = useState([]);
  // 1 for cloche buy | 2 for cloche unlock | 3 for cloche dish unlocked
  const [clocheBuyPopupStep, setClocheBuyPopupStep] = useState(0);
  // 1 for 1 dish | 2 for 3 dish | 3 for 5 dish
  const [clocheNumber, setClocheNumber] = useState(-1);

  useEffect(() => {
    async function asyncFn() {
      if (accountSC) {
        let res = await getUsersClochesData(accountSC);
        if (res) {
          setClochesData([...res]);
        }
      }
    }
    asyncFn();
  }, [accountSC, refetchData]);

  const handleBuyCloche = async (chestIndex) => {
    //Cloche data
    let currentClocheData = closhesData[chestIndex];

    if (currentClocheData.status === "AVAILABLE") {
      let res = await buyClocheBackendAPI(accountSC, currentClocheData.type);
      if (res?.error === false) {
        dispatch(updateRefetchData());
        console.log("Success");
        setClocheNumber(chestIndex);
        setClocheBuyPopupStep(1);
        viberate("medium");
      } else {
        dispatch(setErrorPopup({ flag: true, msg: res.result }));
        viberate("heavy");
      }
    }

    if (currentClocheData.status === "LOCKED") {
      let tempChipsRequired = chipsRequired(chestIndex);
      if (tempChipsRequired > chips) {
        dispatch(setErrorPopup({ flag: true, msg: "Insufficient Chips" }));
      } else {
        setClocheNumber(chestIndex);
        setClocheBuyPopupStep(2);
      }
    }

    if (currentClocheData.status === "UNLOCKED") {
      //Cloche data
      let currentClocheData = closhesData[chestIndex];

      let res = await unlockChestFreeBackendAPI(
        accountSC,
        currentClocheData._id
      );
      if (res?.error === false) {
        setClaimedDishes(res.result.dishes);
        dispatch(updateRefetchData());
        console.log("Success");
        setClocheBuyPopupStep(3);
        viberate("medium");
      } else {
        dispatch(setErrorPopup({ flag: true, msg: res.result }));
        viberate("heavy");
      }
    }
  };

  const handleUnlockCloche = async () => {
    //Cloche data
    let currentClocheData = closhesData[clocheNumber];

    let chipsNeeded = chipsRequired(clocheNumber);
    let res = await unlockChestBackendAPI(
      accountSC,
      currentClocheData._id,
      chipsNeeded
    );
    if (res?.error === false) {
      setClaimedDishes(res.result.dishes);
      dispatch(updateRefetchData());
      console.log("Success");
      setClocheBuyPopupStep(3);
      viberate("medium");
    } else {
      dispatch(setErrorPopup({ flag: true, msg: res.result }));
      viberate("heavy");
    }
  };

  const handleClosePopup = () => {
    setClocheBuyPopupStep(false);
    setClocheNumber(-1);
  };

  const chipsRequired = (indexNo) => {
    //Cloche data
    let currentClocheData = closhesData[indexNo];
    if (currentClocheData.unlockTime === 0) {
      return currentClocheData.price;
    } else {
      let chipsToReduce = parseInt(
        (Date.now() - currentClocheData.creationTime) / 60000
      );
      console.log(chipsToReduce);
      return currentClocheData.price - 2 * chipsToReduce;
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <ErrorSnackbar />
      {clocheBuyPopupStep > 0 && (
        <ClocheBuyPopup
          cloche={clocheNumber}
          openPopupStep={clocheBuyPopupStep}
          setOpenPopupStep={setClocheBuyPopupStep}
          handleClosePopup={handleClosePopup}
          handleUnlockCloche={handleUnlockCloche}
          clocheData={closhesData[clocheNumber]}
          claimedDishes={claimedDishes}
          setTabValue={setTabValue}
        />
      )}
      {closhesData.map((ele, index) => {
        return (
          <SmoothCardComponent key={index}>
            <Box style={{ height: 120 }}>
              <Box
                style={{
                  width: 60,
                  height: 16,
                  position: "absolute",
                  right: 10,
                  top: 20,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <img src="/images/lock_button.svg" />
                  {ele.unlockTime != 0 ? (
                    <TimerComp
                      endTime={ele.unlockTime}
                      color={"white"}
                      fontSize={12}
                      fontWeight={400}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background:
                    ele.status != "LOCKED"
                      ? "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)"
                      : "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
                  boxShadow: "0px 4px 0px rgba(139, 139, 139, 0.3)",
                  borderRadius: "16px",
                  padding: "15px 20px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "'Rubik'",
                      fontWeight: 900,
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#FAFF00",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {ele.type} CLOCHE
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "'Rubik'",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "#FFFFFF",
                      }}
                    >
                      Contains
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "2px 2px",
                        width: "67px",
                        height: "18px",
                        border: "1px solid #FAFF00",
                        borderRadius: "8px",
                        fontFamily: "'Rubik'",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "#FFFFFF",
                      }}
                    >
                      {ele.reward} {ele.reward > 1 ? "Dishes" : "Dish"}
                    </Typography>
                  </Box>
                  <Button
                    style={{
                      width: "fit-content",
                      minWidth: 80,
                      height: "27px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      background: "#64FF99",
                      boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.25)",
                      borderRadius: "8px",
                      fontFamily: "'Rubik'",
                      fontWeight: 700,
                      fontSize: ele.unlockTime !== 0 ? 11 : 14,
                      lineHeight: "19px",
                      color: "#000000",
                      marginTop: "10px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => handleBuyCloche(index)}
                  >
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                      style={{ width: 16, height: 16 }}
                    />
                    {ele.status === "UNLOCKED" && "unlock now"}
                    {ele.unlockTime === 0 && chipsRequired(index)}
                    {ele.status === "LOCKED" &&
                      chipsRequired(index) + " to unlock"}
                  </Button>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={`/images/${index + 1}_dish.png`}
                    style={{
                      width: 60 + index * 25,

                      position: "absolute",
                      bottom: -5,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </SmoothCardComponent>
        );
      })}
    </Box>
  );
};

export default ShopComponent;
