import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import StickyBox from "react-sticky-box";
import useTelegramSDK from "../hooks/useTelegramSDK";
import TrumpCard from "../components/TrumpCard";
import UserDashboard from "../components/UserDashboard";
import LevelUpPopup from "../components/LevelUpPopup";
import { getUserDishes, upgradeSingleDish } from "../actions/serverActions";
import { useServerAuth } from "../hooks/useServerAuth";
import { updateRefetchData } from "../reducers/UiReducers";
import { useDispatch, useSelector } from "react-redux";
import { setErrorPopup } from "../reducers/UiReducers";
import ErrorSnackbar from "../components/ErrorSnackbar";
import ShopComponent from "../components/ShopComponent";
import InfiniteScroll from "react-infinite-scroll-component";
const tabs = [
  { no: 0, name: "My Dishes" },
  { no: 1, name: "Shop" },
  { no: 2, name: "Restaurants" },
];

const Dishes = () => {
  const { viberate } = useTelegramSDK();
  const { accountSC } = useServerAuth();

  const dispatch = useDispatch();

  const chips = useSelector((state) => state.ui.chips);
  const refetchData = useSelector((state) => state.ui.refetchData);

  const [tabValue, setTabValue] = useState(0);
  const [levelUpPopup, setLevelUpPopup] = useState(false);
  const [userDishes, setUserDishes] = useState([]);
  const [totalDishes, setTotalDishes] = useState(0);
  const [dishData, setDishData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  //1.  To Manage initial loading of the application
  useEffect(() => {
    async function asyncFn() {
      if (accountSC) {
        // //1.  Load Dishes
        console.log(refetchData);
        console.log("refetchData");
        await callQuery(true);
      }
    }

    asyncFn();
  }, [accountSC, refetchData]);

  const callQuery = async (fromBeginning = false) => {
    viberate("light");
    setPageLoaded(false);
    let currDataLength = 0;
    console.log(fromBeginning);
    console.log("fromBeginning");
    if (fromBeginning) {
      currDataLength = 0;
    } else {
      currDataLength = userDishes.length;
    }

    let pageNo = Math.ceil(currDataLength / 10);
    let res = await getUserDishes(accountSC, pageNo + 1);

    if (res) {
      setTotalDishes(res.dishCount);
      if (fromBeginning) {
        setUserDishes([...res.dishes]);
      } else {
        setUserDishes([...userDishes, ...res.dishes]);
      }

      setPageLoaded(true);
    }
  };

  //Dish Upgrade function
  const handleUpgradeDishFn = async (dishObj) => {
    viberate("heavy");
    if (dishObj.upgradeFee > chips) {
      await dispatch(setErrorPopup({ flag: true, msg: "INSUFFICIENT CHIPS" }));
      return;
    }
    let res = await upgradeSingleDish(dishObj.tokenId, accountSC);
    if (res?.error === false) {
      setDishData(res.result);
      setLevelUpPopup(true);
    }
  };

  const handleClosePopup = async () => {
    viberate("light");
    setLevelUpPopup(false);
    dispatch(updateRefetchData());
  };

  const isMoreDataAvailable = useMemo(() => {
    return userDishes.length < totalDishes;
  }, [totalDishes, userDishes]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        background: "#161811",
        paddingTop: "15px",
        position: "relative",
        overflowY: "hidden",
      }}
    >
      <ErrorSnackbar />
      {levelUpPopup && (
        <LevelUpPopup handleClosePopup={handleClosePopup} item={dishData} />
      )}

      <StickyBox offsetTop={10} style={{ zIndex: 2 }}>
        <UserDashboard />
        <Box
          style={{
            width: "95%",
            height: 52,
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "5px 10px",
            margin: "5% 2.5% 7%",
            position: "relative",
            background: "rgba(0,0,0,0)",
            zIndex: -1,
          }}
        >
          <Box
            style={{
              width: "100%",
              height: 52,
              background: "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
              borderRadius: "6px",
              padding: "1px",
              top: 0,
              left: 0,
              position: "absolute",
              zIndex: -2,
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "#161811",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px",
              }}
            />
          </Box>

          {tabs.map((ele, i) => (
            <Button
              key={ele.no}
              style={{
                width: "100%",
                height: "100%",
                fontWeight: 400,
                fontFamily: "'Rubik'",
                fontSize: "16px",
                lineHeight: "11px",
                textAlign: "center",
                color: tabValue === ele.no ? "#000000" : "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: tabValue === ele.no ? "#FF9CFF" : "",
                borderRadius: "8px",
                position: "relative",
                zIndex: 3,
                textTransform: "capitalize",
              }}
              onClick={() => {
                viberate("light");
                setTabValue(ele.no);
              }}
            >
              {ele.name}
            </Button>
          ))}
        </Box>
      </StickyBox>
      {/* Section text */}
      <Typography
        style={{
          fontFamily: "Karla",
          fontWeight: 500,
          fontSize: "12.2105px",
          lineHeight: "14px",
          textAlign: "center",
          color: "#FFFFFF",
          marginBottom: "20px",
        }}
      >
        {tabValue === 0 && (
          <>
            Collect Dishes to play games & unlock restaurants.
            <br />
            Dishes level up all the way to 7, giving you glorious
            <br />
            powers in the Gobbl kitchen.
          </>
        )}
        {tabValue === 1 && (
          <>
            Buy Dish Packs to amp up your game!
            <br />
            Use your Chip earnings to unlock Dish Cloches
            <br />
            that power up your kitchen.
          </>
        )}
        {tabValue === 2 && (
          <>
            Collect Dishes to kickstart your own Restaurant &
            <br />
            earn daily profits. The higher you earn, the bigger
            <br />
            your GOBBL Airdrop will be, Chef!
          </>
        )}
      </Typography>

      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "25px",
          position: "relative",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            background: "transparent",
            paddingBottom: "60px",
          }}
        >
          {tabValue === 0 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "32px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "25px 0%",
                background: "transparent",
                position: "relative",
                zIndex: 1,
                overflowY: "auto",
                height: "67vh",
                paddingBottom: 150,
              }}
            >
              <Box
                style={{
                  width: "fit-content",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "25px",
                  rowGap: "35px",
                }}
              >
                {userDishes.map((dish, i) => (
                  <Box
                    style={{
                      width: 153,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                    key={i}
                  >
                    <Box
                      style={{
                        width: "fit-content",
                        transform: "scale(0.85)",
                        margin: "-13.35% -12%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "red",
                      }}
                    >
                      <TrumpCard
                        key={i}
                        item={dish}
                        dishPoints={dish.dishPoints}
                      />
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        style={{
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "center",
                          padding: "0 5px",
                          gap: "5px",
                          width: "66px",
                          height: "22px",
                          border: "0.6px solid #FFFFFF",
                          borderRadius: "8px",
                        }}
                      >
                        <img
                          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/cards_icon.svg"
                          style={{ width: 16, height: 16 }}
                        />
                        <Typography
                          style={{
                            fontFamily: "'Karla'",
                            fontWeight: 700,
                            fontSize: "12.2105px",
                            lineHeight: "14px",
                            color: "#D1FF1A",
                            margin: "0 auto",
                          }}
                        >
                          {dish.quantity}
                          <span style={{ color: "#fff" }}>
                            /{dish.qtyForUpgrade ? dish.qtyForUpgrade : "-"}
                          </span>
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                          fontFamily: "Roboto",
                          fontWeight: 600,
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#FFFFFF",
                          marginLeft: "auto",
                        }}
                      >
                        FEES:
                        <img
                          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                          style={{ width: 16, height: 16 }}
                        />
                        {dish.upgradeFee ? dish.upgradeFee : 0}
                      </Typography>
                    </Box>
                    <Button
                      onClick={() => handleUpgradeDishFn(dish)}
                      style={{
                        width: "100%",
                        height: "28px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        background:
                          dish.quantity >= dish.qtyForUpgrade
                            ? "#FAFF00"
                            : "#B2B2B2",
                        boxShadow:
                          dish.quantity >= dish.qtyForUpgrade
                            ? "0px 3px 0px #917D13"
                            : "0px 3px 0px #868686",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Rubik'",
                          fontWeight: 600,
                          fontSize: "14px",
                          textAlign: "center",
                          background:
                            "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          textFillColor:
                            dish.quantity >= dish.qtyForUpgrade
                              ? "transparent"
                              : "",
                          WebkitTextFillColor:
                            dish.quantity >= dish.qtyForUpgrade
                              ? "transparent"
                              : "#868686",
                        }}
                      >
                        {dish.qtyForUpgrade ? "LEVEL UP" : "Fully Upgraded"}
                      </Typography>
                    </Button>
                  </Box>
                ))}
              </Box>
              {isMoreDataAvailable && (
                <Box display={"flex"} justifyContent={"center"} pt={2}>
                  <Button
                    onClick={() => callQuery(false)}
                    style={{
                      width: "100px",
                      height: "32px",
                      fontFamily: "Rubik",
                      fontWeight: 400,
                      fontSize: "13px",
                      textAlign: "center",
                      color: "white",
                      border: "1px solid #bdbdbd",
                      borderRadius: "12px",
                      textTransform: "none",
                    }}
                  >
                    Load more
                  </Button>
                </Box>
              )}
              {/* When no dishes and loading done */}
              {pageLoaded && userDishes.length === 0 && (
                <Box
                  style={{
                    position: "relative",
                    zIndex: 1,
                    textAlign: "center",
                    marginTop: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "'Roboto'",
                      fontWeight: 500,
                      fontSize: "18px",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    Nothing cookin’ here, Chef!
                  </Typography>
                  <Button
                    style={{
                      width: "193px",
                      height: "51px",
                      background: "#64FF99",
                      borderRadius: "12px",
                      fontFamily: "'Rubik'",
                      fontWeight: 700,
                      fontSize: "16px",
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    BUY DISHES
                  </Button>
                </Box>
              )}

              {!pageLoaded && (
                <Box>
                  <CircularProgress size={18} />
                </Box>
              )}
            </Box>
          )}

          {tabValue === 1 && (
            <Box style={{ overflowY: "auto", height: "70vh" }}>
              <ShopComponent setTabValue={setTabValue} />
            </Box>
          )}
          {tabValue === 2 && (
            <Box
              style={{
                position: "relative",
                zIndex: 1,
                textAlign: "center",
                marginTop: "50px",
                fontSize: 20,
              }}
            >
              Coming Soon
            </Box>
          )}
          {/* background card*/}
          {tabValue !== 1 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(149, 149, 149, 0.3) 50%, rgba(227, 227, 227, 0.3) 100%)",
                borderRadius: "24px 24px 0 0",
                padding: "1px 1px 0",
                zIndex: 0,
                marginBottom: "15px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#161811",
                  borderRadius: "24px 24px 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                  zIndex: 0,
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                    borderRadius: "24px 24px 0 0",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dishes;
