import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, Grow, Slide, Typography } from "@mui/material";
import ClaimRewardPopup from "../components/ClaimRewardPopup";
import { useServerAuth } from "../hooks/useServerAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  claimDailyBoxReward,
  getDailyBoxesClaimStatus,
} from "../actions/serverActions";
import useTelegramSDK from "../hooks/useTelegramSDK";
import TimerComp from "../components/TimerComponent";
import {
  updateBoxClaimedFlag,
  updateRefetchData,
} from "../reducers/UiReducers";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getTimeLeftClock } from "../actions/helperFn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rewardsData = [
  {
    day: 1,
    type: "GOBBL",
    amount: "10,000",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/GobblUpAssets/Gobbl-token.png",
  },
  {
    day: 2,
    type: "CARD",
    amount: "DISH",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish_icon.png",
  },
  {
    day: 3,
    type: "TON",
    amount: "0.001",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.svg",
  },
  {
    day: 4,
    type: "CARD",
    amount: "DISH",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish_icon.png",
  },
  {
    day: 5,
    type: "GOBBL",
    amount: "25,000",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/GobblUpAssets/Gobbl-token.png",
  },
  {
    day: 6,
    type: "CHIPS",
    amount: "500",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png",
  },
];

const DailyBoxCard = ({ day, isClaimed, isClaimable, type, amount, img }) => {
  return (
    <Box
      style={{
        minWidth: 100,
        maxWidth: 100,
        height: 100,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "3px",
        marginTop: "5px",
      }}
    >
      <Box
        style={{
          width: "50px",
          height: "22px",
          position: "absolute",
          left: -5,
          top: -5,
          background: isClaimed
            ? "#64FF99"
            : isClaimable
            ? "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)"
            : "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
          borderRadius: "8px",
          transform: "rotate(-15deg)",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "'Rubik'",
            fontWeight: 600,
            fontSize: "12.5px",
            background: "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: isClaimed
              ? "transparent"
              : isClaimable
              ? "#FAFF00"
              : "#fff",
            backgroundClip: "text",
            textFillColor: isClaimed
              ? "transparent"
              : isClaimable
              ? "#FAFF00"
              : "#fff",
          }}
        >
          DAY {day}
        </Typography>
      </Box>
      {isClaimed && (
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/check.svg"
          style={{
            width: 55,
            height: 55,
            zIndex: 1,
            marginTop: "-10px",
          }}
        />
      )}
      {!isClaimed && (
        <img
          src={img}
          style={{
            width: 38,
            height: 38,
            zIndex: 1,
            objectFit: "contain",
          }}
        />
      )}
      {!isClaimed && (
        <Typography
          style={{
            fontFamily: "'Rubik'",
            fontWeight: 800,
            fontSize: "17px",
            lineHeight: "100%",
            textAlign: "center",
            color: "#FDFFF5",
            textShadow: "0px 1px 0px #000000",
            WebkitTextFillColor: "white",
            WebkitTextStrokeWidth: "1px",
            WebkitTextStrokeColor: "black",
            zIndex: 1,
          }}
        >
          {amount}
        </Typography>
      )}
      {!isClaimed && (
        <Typography
          style={{
            fontFamily: "'Rubik'",
            fontWeight: 600,
            fontSize: "10px",
            lineHeight: "9px",
            textAlign: "center",
            color: isClaimable ? "#000000" : "#fff",
            zIndex: 1,
          }}
        >
          {type}
        </Typography>
      )}

      {isClaimed && (
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
          }}
        >
          <path
            d="M48 0.5H52C63.3278 0.5 71.7734 0.501062 78.2679 1.37422C84.748 2.24545 89.2107 3.97666 92.617 7.38299C96.0233 10.7893 97.7546 15.252 98.6258 21.7321C99.4989 28.2266 99.5 36.6722 99.5 48V52C99.5 63.3278 99.4989 71.7734 98.6258 78.2679C97.7546 84.748 96.0233 89.2107 92.617 92.617C89.2107 96.0233 84.748 97.7546 78.2679 98.6258C71.7734 99.4989 63.3278 99.5 52 99.5H48C36.6722 99.5 28.2266 99.4989 21.7321 98.6258C15.252 97.7546 10.7893 96.0233 7.38299 92.617C3.97666 89.2107 2.24545 84.748 1.37422 78.2679C0.501062 71.7734 0.5 63.3278 0.5 52V48C0.5 36.6722 0.501062 28.2266 1.37422 21.7321C2.24545 15.252 3.97666 10.7893 7.38299 7.38299C10.7893 3.97666 15.252 2.24545 21.7321 1.37422C28.2266 0.501062 36.6722 0.5 48 0.5Z"
            fill="url(#paint0_linear_659_292)"
            fillOpacity="0.1"
            stroke="url(#paint1_linear_659_292)"
          />
          <path
            d="M47 3.5H53C63.385 3.5 71.1235 3.50106 77.0734 4.301C83.0088 5.099 87.0894 6.68376 90.2028 9.7972C93.3162 12.9106 94.901 16.9912 95.699 22.9266C96.4989 28.8765 96.5 36.615 96.5 47V53C96.5 63.385 96.4989 71.1235 95.699 77.0734C94.901 83.0088 93.3162 87.0894 90.2028 90.2028C87.0894 93.3162 83.0088 94.901 77.0734 95.699C71.1235 96.4989 63.385 96.5 53 96.5H47C36.615 96.5 28.8765 96.4989 22.9266 95.699C16.9912 94.901 12.9106 93.3162 9.7972 90.2028C6.68376 87.0894 5.099 83.0088 4.301 77.0734C3.50106 71.1235 3.5 63.385 3.5 53V47C3.5 36.615 3.50106 28.8765 4.301 22.9266C5.099 16.9912 6.68376 12.9106 9.7972 9.7972C12.9106 6.68376 16.9912 5.099 22.9266 4.301C28.8765 3.50106 36.615 3.5 47 3.5Z"
            fill="url(#paint2_linear_659_292)"
            stroke="url(#paint3_linear_659_292)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_659_292"
              x1="100"
              y1="-15.7812"
              x2="-25.6963"
              y2="53.1269"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDFFF5" stopOpacity="0.8" />
              <stop offset="1" stopColor="#FDFFF5" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_659_292"
              x1="84.6875"
              y1="-29.3134"
              x2="-47.3602"
              y2="7.27879"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCCC" />
              <stop offset="0.499" stopColor="#009999" />
              <stop offset="1" stopColor="#6666FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_659_292"
              x1="50"
              y1="3"
              x2="50"
              y2="97"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#089B89" />
              <stop offset="1" stopColor="#036860" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_659_292"
              x1="82.6063"
              y1="-24.5546"
              x2="-41.5186"
              y2="9.84206"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCCC" />
              <stop offset="0.499" stopColor="#009999" />
              <stop offset="1" stopColor="#6666FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {isClaimable && (
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
        >
          <path
            d="M0.5 48C0.5 36.6722 0.501062 28.2266 1.37422 21.7321C2.24545 15.252 3.97666 10.7893 7.38299 7.38299C10.7893 3.97666 15.252 2.24545 21.7321 1.37422C28.2266 0.501062 36.6722 0.5 48 0.5H52C63.3278 0.5 71.7734 0.501062 78.2679 1.37422C84.748 2.24545 89.2107 3.97666 92.617 7.38299C96.0233 10.7893 97.7546 15.252 98.6258 21.7321C99.4989 28.2266 99.5 36.6722 99.5 48V52C99.5 63.3278 99.4989 71.7734 98.6258 78.2679C97.7546 84.748 96.0233 89.2107 92.617 92.617C89.2107 96.0233 84.748 97.7546 78.2679 98.6258C71.7734 99.4989 63.3278 99.5 52 99.5H48C36.6722 99.5 28.2266 99.4989 21.7321 98.6258C15.252 97.7546 10.7893 96.0233 7.38299 92.617C3.97666 89.2107 2.24545 84.748 1.37422 78.2679C0.501062 71.7734 0.5 63.3278 0.5 52V48Z"
            fill="url(#paint0_linear_6505_92)"
            fillOpacity="0.1"
            stroke="url(#paint1_linear_6505_92)"
          />
          <path
            d="M3.5 47C3.5 36.615 3.50106 28.8765 4.301 22.9266C5.099 16.9912 6.68376 12.9106 9.7972 9.7972C12.9106 6.68376 16.9912 5.099 22.9266 4.301C28.8765 3.50106 36.615 3.5 47 3.5H53C63.385 3.5 71.1235 3.50106 77.0734 4.301C83.0088 5.099 87.0894 6.68376 90.2028 9.7972C93.3162 12.9106 94.901 16.9912 95.699 22.9266C96.4989 28.8765 96.5 36.615 96.5 47V53C96.5 63.385 96.4989 71.1235 95.699 77.0734C94.901 83.0088 93.3162 87.0894 90.2028 90.2028C87.0894 93.3162 83.0088 94.901 77.0734 95.699C71.1235 96.4989 63.385 96.5 53 96.5H47C36.615 96.5 28.8765 96.4989 22.9266 95.699C16.9912 94.901 12.9106 93.3162 9.7972 90.2028C6.68376 87.0894 5.099 83.0088 4.301 77.0734C3.50106 71.1235 3.5 63.385 3.5 53V47Z"
            fill="url(#paint2_linear_6505_92)"
            stroke="url(#paint3_linear_6505_92)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6505_92"
              x1="100"
              y1="-15.7812"
              x2="-25.6963"
              y2="53.1269"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDFFF5" stopOpacity="0.8" />
              <stop offset="1" stopColor="#FDFFF5" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6505_92"
              x1="84.6875"
              y1="-29.3134"
              x2="-47.3602"
              y2="7.27879"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCCC" />
              <stop offset="0.499" stopColor="#009999" />
              <stop offset="1" stopColor="#6666FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_6505_92"
              x1="50"
              y1="3"
              x2="50"
              y2="97"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#d1ff1a" />
              <stop offset="1" stopColor="#d1ff1a" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_6505_92"
              x1="82.6063"
              y1="-24.5546"
              x2="-41.5186"
              y2="9.84206"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCCC" />
              <stop offset="0.499" stopColor="#009999" />
              <stop offset="1" stopColor="#6666FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {!isClaimable && (
        <svg
          width="100"
          height="101"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -2,
          }}
        >
          <path
            d="M48 1.38281H52C63.3278 1.38281 71.7734 1.38387 78.2679 2.25703C84.748 3.12826 89.2107 4.85947 92.617 8.2658C96.0233 11.6721 97.7546 16.1348 98.6258 22.6149C99.4989 29.1094 99.5 37.555 99.5 48.8828V52.8828C99.5 64.2107 99.4989 72.6562 98.6258 79.1507C97.7546 85.6308 96.0233 90.0935 92.617 93.4998C89.2107 96.9062 84.748 98.6374 78.2679 99.5086C71.7734 100.382 63.3278 100.383 52 100.383H48C36.6722 100.383 28.2266 100.382 21.7321 99.5086C15.252 98.6374 10.7893 96.9062 7.38299 93.4998C3.97666 90.0935 2.24545 85.6308 1.37422 79.1507C0.501062 72.6562 0.5 64.2107 0.5 52.8828V48.8828C0.5 37.555 0.501062 29.1094 1.37422 22.6149C2.24545 16.1348 3.97666 11.6721 7.38299 8.2658C10.7893 4.85947 15.252 3.12826 21.7321 2.25703C28.2266 1.38387 36.6722 1.38281 48 1.38281Z"
            fill="url(#paint0_linear_659_222)"
            fillOpacity="0.1"
            stroke="url(#paint1_linear_659_222)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_659_222"
              x1="100"
              y1="-14.8984"
              x2="-25.6963"
              y2="54.0098"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDFFF5" stopOpacity="0.8" />
              <stop offset="1" stopColor="#FDFFF5" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_659_222"
              x1="84.6875"
              y1="-28.4306"
              x2="-47.3602"
              y2="8.1616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CCCC" />
              <stop offset="0.499" stopColor="#009999" />
              <stop offset="1" stopColor="#6666FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </Box>
  );
};

const DailyBox = ({ openPopup, closePopup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accountSC } = useServerAuth();
  const { viberate } = useTelegramSDK();

  const claimsThisWeek = useSelector((state) => state.ui.claimsThisWeek);
  const nextClaimTimestamp = useSelector(
    (state) => state.ui.nextClaimTimestamp
  );

  const [claimReward, setClaimReward] = useState(false);
  const [rewardObj, setRewardObj] = useState(null);

  const handleClaimDailyBoxReward = async () => {
    viberate("light");
    let res = await claimDailyBoxReward(accountSC);
    console.log(res);

    if (res && !res.error && res.result) {
      let localRewardData = rewardsData[res.result.day - 1];
      if (res.result.reward?.length === 2) {
        // Multi Reward
        setRewardObj({
          type: "MULTI",
          dishData: res.result.reward[0].data,
          amount: "50,000",
          img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png",
        });
      } else {
        // Single Reward
        let tempRewardData = {
          ...localRewardData,
          dishData: res.result.reward[0].data,
        };

        console.log(tempRewardData);

        setRewardObj(tempRewardData);
      }
      viberate("heavy");
      setClaimReward(true);
      await dispatch(updateRefetchData(true));

      setTimeout(() => {
        setClaimReward(false);
      }, 3000);
    }
  };

  const isClaimAvailable = useMemo(() => {
    return nextClaimTimestamp < Date.now();
  }, [nextClaimTimestamp, Date.now()]);

  const isLastClaimAvailable = useMemo(() => {
    return nextClaimTimestamp < Date.now() && claimsThisWeek === 6;
  }, [nextClaimTimestamp, claimsThisWeek]);

  const handleClosePopup = async () => {
    setClaimReward(false);
    await dispatch(updateRefetchData(true));
  };
  return (
    <Dialog
      open={openPopup}
      onClose={closePopup}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      maxWidth="lg"
      fullWidth={false}
    >
      <Box
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <ClaimRewardPopup
            claimReward={claimReward}
            onClose={handleClosePopup}
            rewardObj={rewardObj}
          />
          <Box
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: "10%",
              marginTop: "15vh",
              borderRadius: "33px 33px 0px 0px",
              zIndex: 1111,
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100vh",
                background:
                  "url(https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/home_bg.webp)",
                backgroundSize: "100%",
                backgroundPosition: "center center",
              }}
            />
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "15px 5% 0",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Button
                style={{
                  minWidth: "auto",
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                }}
                onClick={closePopup}
              >
                <Close
                  style={{
                    fontSize: 24,
                    fill: "#faff00",
                    zIndex: 1,
                  }}
                />
              </Button>
            </Box>
            <Box
              style={{
                position: "relative",
                zIndex: 1,
              }}
            >
              <Typography
                style={{
                  fontFamily: "'Rubik'",
                  fontWeight: 900,
                  fontSize: "32px",
                  lineHeight: "120%",
                  textAlign: "center",
                  color: "#FAFF00",
                }}
              >
                DAILY REWARD
              </Typography>
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "15px",
                  marginTop: "25px",
                }}
              >
                {rewardsData.map((ele, index) => {
                  return (
                    <DailyBoxCard
                      key={index}
                      day={ele.day}
                      isClaimed={claimsThisWeek >= ele.day}
                      isClaimable={
                        isClaimAvailable && ele.day === claimsThisWeek + 1
                      }
                      type={ele.type}
                      amount={ele.amount}
                      img={ele.img}
                    />
                  );
                })}

                {/* DailyBoxCard 7 */}
                <Box
                  style={{
                    maxWidth: 330,
                    minWidth: 330,
                    height: 100,
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "5px",
                    gridColumnStart: "1",
                    gridColumnEnd: "4",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "3px",
                    }}
                  >
                    <Box
                      style={{
                        width: 80,
                        height: 35,
                        position: "absolute",
                        left: -0.5,
                        top: -3,
                        background: isLastClaimAvailable
                          ? "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)"
                          : "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
                        borderRadius: "8px",
                        transform: "rotate(-15deg)",
                        zIndex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Rubik'",
                          fontWeight: 600,
                          fontSize: 20,
                          background:
                            "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: isLastClaimAvailable
                            ? "#FAFF00"
                            : "#fff",
                          backgroundClip: "text",
                          textFillColor: isLastClaimAvailable
                            ? "#FAFF00"
                            : "#fff",
                        }}
                      >
                        DAY 7
                      </Typography>
                    </Box>
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                      style={{
                        width: 38,
                        height: 38,
                        zIndex: 1,
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "'Rubik'",
                        fontWeight: 800,
                        fontSize: "17px",
                        lineHeight: "100%",
                        textAlign: "center",
                        color: "#FDFFF5",
                        textShadow: "0px 1px 0px #000000",
                        WebkitTextFillColor: "white",
                        WebkitTextStrokeWidth: "1px",
                        WebkitTextStrokeColor: "black",
                        zIndex: 1,
                      }}
                    >
                      50000
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "'Rubik'",
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "9px",
                        textAlign: "center",
                        color: isLastClaimAvailable ? "#000000" : "#fff",
                        zIndex: 1,
                      }}
                    >
                      GOBBL
                    </Typography>
                  </Box>
                  <img
                    src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/add_icon.svg"
                    style={{ width: 27, height: 27, marginTop: "30px" }}
                  />
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "3px",
                    }}
                  >
                    <img
                      src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish_icon.png"
                      style={{
                        width: 38,
                        height: 38,
                        zIndex: 1,
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "'Rubik'",
                        fontWeight: 800,
                        fontSize: "17px",
                        lineHeight: "100%",
                        textAlign: "center",
                        color: "#FDFFF5",
                        textShadow: "0px 1px 0px #000000",
                        WebkitTextFillColor: "white",
                        WebkitTextStrokeWidth: "1px",
                        WebkitTextStrokeColor: "black",
                        zIndex: 1,
                      }}
                    >
                      DISH
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "'Rubik'",
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "9px",
                        textAlign: "center",
                        color: isLastClaimAvailable ? "#000000" : "#fff",
                        zIndex: 1,
                      }}
                    >
                      CARD
                    </Typography>
                  </Box>
                  {isLastClaimAvailable && (
                    <svg
                      width="326"
                      height="100"
                      viewBox="0 0 326 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 0,
                      }}
                    >
                      <path
                        d="M48 0.5H278C289.328 0.5 297.773 0.501062 304.268 1.37422C310.748 2.24545 315.211 3.97666 318.617 7.38299C322.023 10.7893 323.755 15.252 324.626 21.7321C325.499 28.2266 325.5 36.6722 325.5 48V52C325.5 63.3278 325.499 71.7734 324.626 78.2679C323.755 84.748 322.023 89.2107 318.617 92.617C315.211 96.0233 310.748 97.7546 304.268 98.6258C297.773 99.4989 289.328 99.5 278 99.5H48C36.6722 99.5 28.2266 99.4989 21.7321 98.6258C15.252 97.7546 10.7893 96.0233 7.38299 92.617C3.97666 89.2107 2.24545 84.748 1.37422 78.2679C0.501062 71.7734 0.5 63.3278 0.5 52V48C0.5 36.6722 0.501062 28.2266 1.37422 21.7321C2.24545 15.252 3.97666 10.7893 7.38299 7.38299C10.7893 3.97666 15.252 2.24545 21.7321 1.37422C28.2266 0.501062 36.6722 0.5 48 0.5Z"
                        fill="url(#paint0_linear_669_381)"
                        fillOpacity="0.1"
                        stroke="url(#paint1_linear_669_381)"
                      />
                      <path
                        d="M47 3.5H279C289.385 3.5 297.124 3.50106 303.073 4.301C309.009 5.099 313.089 6.68376 316.203 9.7972C319.316 12.9106 320.901 16.9912 321.699 22.9266C322.499 28.8765 322.5 36.615 322.5 47V52.4162C322.5 62.8012 322.499 70.5397 321.699 76.4895C320.901 82.425 319.316 86.5055 316.203 89.6189C313.089 92.7324 309.009 94.3171 303.073 95.1152C297.124 95.9151 289.385 95.9161 279 95.9161H47C36.615 95.9161 28.8765 95.9151 22.9266 95.1152C16.9912 94.3171 12.9106 92.7324 9.7972 89.6189C6.68376 86.5055 5.099 82.425 4.301 76.4895C3.50106 70.5397 3.5 62.8012 3.5 52.4161V47C3.5 36.6149 3.50106 28.8765 4.301 22.9266C5.099 16.9912 6.68376 12.9106 9.7972 9.7972C12.9106 6.68376 16.9912 5.099 22.9266 4.301C28.8765 3.50106 36.615 3.5 47 3.5Z"
                        fill="#FAFF00"
                        stroke="url(#paint2_linear_669_381)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_669_381"
                          x1="326"
                          y1="-15.7812"
                          x2="198.932"
                          y2="211.311"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FDFFF5" stopOpacity="0.8" />
                          <stop
                            offset="1"
                            stopColor="#FDFFF5"
                            stopOpacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_669_381"
                          x1="276.081"
                          y1="-29.3134"
                          x2="20.8479"
                          y2="201.262"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#00CCCC" />
                          <stop offset="0.499" stopColor="#009999" />
                          <stop offset="1" stopColor="#6666FF" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_669_381"
                          x1="274"
                          y1="-24.3834"
                          x2="34.6637"
                          y2="202.809"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#00CCCC" />
                          <stop offset="0.499" stopColor="#009999" />
                          <stop offset="1" stopColor="#6666FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                  {!isLastClaimAvailable && (
                    <svg
                      width="326"
                      height="100"
                      viewBox="0 0 326 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                      }}
                    >
                      <path
                        d="M48 0.5H278C289.328 0.5 297.773 0.501062 304.268 1.37422C310.748 2.24545 315.211 3.97666 318.617 7.38299C322.023 10.7893 323.755 15.252 324.626 21.7321C325.499 28.2266 325.5 36.6722 325.5 48V52C325.5 63.3278 325.499 71.7734 324.626 78.2679C323.755 84.748 322.023 89.2107 318.617 92.617C315.211 96.0233 310.748 97.7546 304.268 98.6258C297.773 99.4989 289.328 99.5 278 99.5H48C36.6722 99.5 28.2266 99.4989 21.7321 98.6258C15.252 97.7546 10.7893 96.0233 7.38299 92.617C3.97666 89.2107 2.24545 84.748 1.37422 78.2679C0.501062 71.7734 0.5 63.3278 0.5 52V48C0.5 36.6722 0.501062 28.2266 1.37422 21.7321C2.24545 15.252 3.97666 10.7893 7.38299 7.38299C10.7893 3.97666 15.252 2.24545 21.7321 1.37422C28.2266 0.501062 36.6722 0.5 48 0.5Z"
                        fill="url(#paint0_linear_659_297)"
                        fillOpacity="0.1"
                        stroke="url(#paint1_linear_659_297)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_659_297"
                          x1="326"
                          y1="-15.7812"
                          x2="198.932"
                          y2="211.311"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FDFFF5" stopOpacity="0.8" />
                          <stop
                            offset="1"
                            stopColor="#FDFFF5"
                            stopOpacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_659_297"
                          x1="276.081"
                          y1="-29.3134"
                          x2="20.8479"
                          y2="201.262"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#00CCCC" />
                          <stop offset="0.499" stopColor="#009999" />
                          <stop offset="1" stopColor="#6666FF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </Box>
              </Box>

              {!isClaimAvailable && (
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    fontFamily: "'Rubik'",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#000000",
                    width: "281px",
                    height: "51px",
                    background: "#D9D9D9",
                    borderRadius: "12px",
                    zIndex: 1,
                    margin: "25px auto 0",
                  }}
                >
                  NEXT REWARD IN
                  <TimerComp endTime={nextClaimTimestamp} />
                </Button>
              )}
              {isClaimAvailable && (
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    fontFamily: "'Rubik'",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#000000",
                    width: "209px",
                    height: "51px",
                    background: "#64FF99",
                    borderRadius: "12px",
                    zIndex: 1,
                    margin: "25px auto 0",
                  }}
                  onClick={handleClaimDailyBoxReward}
                >
                  CLAIM
                </Button>
              )}
            </Box>
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/spin_page_bg.svg"
              style={{
                minWidth: "100vw",
                height: "85vh",
                position: "absolute",
                left: 0,
                bottom: 0,
                objectFit: "cover",
                objectPosition: "top",
                boxShadow: "0px -19px 24px rgba(0, 0, 0, 0.5)",
                borderRadius: "33px 33px 0px 0px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DailyBox;
