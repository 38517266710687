// 0 mainnet, 1 testnet
let network_type = 0;

let constants;
constants = {
  net: network_type,
  botUrl:
    network_type === 0
      ? "https://t.me/GobblUpBot/game?startapp="
      : "https://t.me/GobblTestBot/test?startapp=",
  api_url:
    network_type === 1
      ? "https://testapi.gobbl.io/api"
      : "https://api.gobbl.io/api",
  firebase_auth_url:
    network_type === 1 ? "https://test.gobbl.io" : "https://gobbl.io",
};
export default constants;

export const AUTH_TYPE_ENUM = {
  TELEGRAM: "TELEGRAM",
  GMAIL: "GMAIL",
  FACEBOOK: "FACEBOOK",
  TWITTER: "TWITTER",
  METAMASK: "METAMASK",
};

// League levels
export const LEAGUE_LEVEL_DATA = [
  {
    id: 0,
    title: "Trainee",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level0.png",
    tapsRequired: 0,
    coinsReward: 0,
  },
  {
    id: 1,
    title: "Apprentice",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level1.png",
    tapsRequired: 5000,
    coinsReward: 5000,
  },
  {
    id: 2,
    title: "Chef Assistant",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level2.png",
    tapsRequired: 50000,
    coinsReward: 10000,
  },
  {
    id: 3,
    title: "Junior Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level3.png",
    tapsRequired: 250000,
    coinsReward: 30000,
  },
  {
    id: 4,
    title: "Station Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level4.png",
    tapsRequired: 500000,
    coinsReward: 50000,
  },
  {
    id: 5,
    title: "Pastry Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level5.png",
    tapsRequired: 1000000,
    coinsReward: 100000,
  },
  {
    id: 6,
    title: "Sous Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level6.png",
    tapsRequired: 2500000,
    coinsReward: 250000,
  },
  {
    id: 7,
    title: "Head Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level7.png",
    tapsRequired: 5000000,
    coinsReward: 500000,
  },
  {
    id: 8,
    title: "Executive Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level8.png",
    tapsRequired: 10000000,
    coinsReward: 1000000,
  },
  {
    id: 9,
    title: "Master Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level9.png",
    tapsRequired: 50000000,
    coinsReward: 5000000,
  },
  {
    id: 10,
    title: "Celebrity Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level10.png",
    tapsRequired: 200000000,
    coinsReward: 7000000,
  },
  {
    id: 11,
    title: "Star Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level11.png",
    tapsRequired: 500000000,
    coinsReward: 9000000,
  },
  {
    id: 12,
    title: "Chef Owner",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level12.png",
    tapsRequired: 1000000000,
    coinsReward: 11000000,
  },
  {
    id: 13,
    title: "Chef Patron",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level13.png",
    tapsRequired: 2000000000,
    coinsReward: 13000000,
  },
  {
    id: 14,
    title: "Chef Legend",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level14.png",
    tapsRequired: 5000000000,
    coinsReward: 15000000,
  },
  {
    id: 15,
    title: "Chef Legend",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level14.png",
    tapsRequired: 500000000000,
    coinsReward: 0,
  },
];

// Tap data
export const TAP_DATA = [
  {
    level: 0,
    name: "PANCAKES",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish0.png",
    coins: 0,
    tapCount: 1,
  },
  {
    level: 1,
    name: "SALAD",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish11.png",
    coins: 500,
    tapCount: 2,
  },
  {
    level: 2,
    name: "BURGER",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish19.png",
    coins: 1000,
    tapCount: 3,
  },

  {
    level: 3,
    name: "FRIES",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish7.png",
    coins: 2000,
    tapCount: 4,
  },
  {
    level: 4,
    name: "POPCORN",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish20.png",
    coins: 4000,
    tapCount: 5,
  },
  {
    level: 5,
    name: "TACO",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish14.png",
    coins: 8000,
    tapCount: 6,
  },
  {
    level: 6,
    name: "HOT DOG",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish18.png",
    coins: 16000,
    tapCount: 7,
  },
  {
    level: 7,
    name: "SANDWICH",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish10.png",
    coins: 25000,
    tapCount: 8,
  },
  {
    level: 8,
    name: "FRIED CHICKEN",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish8.png",
    coins: 50000,
    tapCount: 9,
  },
  {
    level: 9,
    name: "STEAK",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish12.png",
    coins: 100000,
    tapCount: 10,
  },
  {
    level: 10,
    name: "RAMEN",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish9.png",
    coins: 200000,
    tapCount: 11,
  },
  {
    level: 11,
    name: "SUSHI",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish13.png",
    coins: 300000,
    tapCount: 12,
  },
  {
    level: 12,
    name: "DIMSUMS",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish2.png",
    coins: 400000,
    tapCount: 13,
  },
  {
    level: 13,
    name: "NOODLES",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish17.png",

    coins: 500000,
    tapCount: 14,
  },
  {
    level: 14,
    name: "DOSA",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish6.png",

    coins: 600000,
    tapCount: 15,
  },
  {
    level: 15,
    name: "WAFFLES",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish15.png",
    coins: 700000,
    tapCount: 16,
  },
  {
    level: 16,
    name: "BERRY PIE",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish16.png",
    coins: 800000,
    tapCount: 17,
  },
  {
    level: 17,
    name: "ICE CREAM",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish1.png",
    coins: 900000,
    tapCount: 18,
  },
  {
    level: 18,
    name: "DONUT",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish5.png",
    coins: 1000000,
    tapCount: 19,
  },
  {
    level: 19,
    name: "CHURROS",
    dish: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dish4.png",
    coins: 2000000,
    tapCount: 20,
  },
];

// Energy Limit
export const ENERGY_LIMIT_DATA = [
  { level: 0, coins: 0, energyLimit: 500 },
  { level: 1, coins: 500, energyLimit: 1000 },
  { level: 2, coins: 1000, energyLimit: 1500 },
  { level: 3, coins: 2000, energyLimit: 2000 },
  { level: 4, coins: 4000, energyLimit: 2500 },
  { level: 5, coins: 8000, energyLimit: 3000 },
  { level: 6, coins: 16000, energyLimit: 3500 },
  { level: 7, coins: 25000, energyLimit: 4000 },
  { level: 8, coins: 50000, energyLimit: 4500 },
  { level: 9, coins: 100000, energyLimit: 5000 },
  { level: 10, coins: 200000, energyLimit: 5500 },
  { level: 11, coins: 300000, energyLimit: 6000 },
  { level: 12, coins: 400000, energyLimit: 6500 },
  { level: 13, coins: 500000, energyLimit: 7000 },
  { level: 14, coins: 600000, energyLimit: 7500 },
  { level: 15, coins: 700000, energyLimit: 8000 },
  { level: 16, coins: 800000, energyLimit: 8500 },
  { level: 17, coins: 900000, energyLimit: 9000 },
  { level: 18, coins: 1000000, energyLimit: 9500 },
  { level: 19, coins: 2000000, energyLimit: 10000 },
];

// Recharge speed
export const RECHARGE_SPEED_DATA = [
  { level: 0, coins: 0, speed: 1 },
  { level: 1, coins: 2000, speed: 2 },
  { level: 2, coins: 10000, speed: 3 },
  { level: 3, coins: 100000, speed: 4 },
  { level: 4, coins: 250000, speed: 5 },
  { level: 5, coins: 500000, speed: 6 },
  { level: 6, coins: 1000000, speed: 7 },
  { level: 7, coins: 2000000, speed: 8 },
  { level: 8, coins: 3000000, speed: 9 },
  { level: 9, coins: 5000000, speed: 10 },
];

// League levels
export const REFERRAL_COUNT_DATA = [1, 3, 10, 25, 50, 100, 500, 1000, 100000];

// Special Tasks Data
export const SPECIAL_TASKS_DATA = [
  {
    id: 4,
    title: "Follow GOBBL on Instagram",
    pointsText: "25K",
    points: 25000,
    type: "SPECIAL",
    url: "https://www.instagram.com/gmgobbl/",
    androidUrl: "https://www.instagram.com/gmgobbl/",
  },
  {
    id: 3,
    title: "Join Telegram Channel",
    pointsText: "25K",
    points: 25000,
    type: "SPECIAL",
    url: "tg://resolve?domain=gobblnews",
    androidUrl: "tg://resolve?domain=gobblnews",
  },

  {
    id: 2,
    title: "Follow gmgobbl on X",
    pointsText: "25K",
    points: 25000,
    type: "SPECIAL",
    url: "https://x.com/gobblchain",
    androidUrl: "https://x.com/intent/follow?screen_name=gobblchain",
  },
  {
    id: 1,
    title: "Retweet this Tweet",
    pointsText: "50K",
    points: 50000,
    type: "SPECIAL",
    url: "https://x.com/gobblchain/status/1817128847508164848",
    androidUrl: "https://x.com/intent/retweet?tweet_id=1817128847508164848",
  },
  {
    id: 0,
    title: "Like this Tweet",
    pointsText: "25K",
    points: 25000,
    type: "SPECIAL",
    url: "https://x.com/gobblchain/status/1817128847508164848",
    androidUrl: "https://x.com/intent/like?tweet_id=1817128847508164848",
  },
];

// League Tasks Data
export const LEAGUE_TASKS_DATA = [
  {
    id: 0,
    title: "Trainee",
    startRequired: 0,
    tapRequired: 5000,
    pointsText: "1K",
    points: 1000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level0.png",
    rewards: [{ type: "GOBBL", amount: 5000 }],
  },
  {
    id: 1,
    title: "Apprentice",
    startRequired: 5000,
    tapRequired: 50000,
    pointsText: "5K",
    points: 5000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level1.png",
    rewards: [
      { type: "DISH", amount: 2 },
      { type: "CHIPS", amount: 250 },
    ],
  },

  {
    id: 2,
    title: "Chef Assistant",
    startRequired: 50000,
    tapRequired: 250000,
    pointsText: "10K",
    points: 10000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level2.png",
    rewards: [
      { type: "DISH", amount: 3 },
      { type: "CHIPS", amount: 500 },
    ],
  },
  {
    id: 3,
    title: "Junior Chef",
    startRequired: 250000,
    tapRequired: 500000,
    pointsText: "30K",
    points: 30000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level3.png",
    rewards: [{ type: "GOBBL", amount: 100000 }],
  },
  {
    id: 4,
    title: "Station Chef",
    startRequired: 500000,
    tapRequired: 1000000,
    pointsText: "50K",
    points: 50000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level4.png",
    rewards: [
      { type: "DISH", amount: 5 },
      { type: "CHIPS", amount: 1000 },
    ],
  },
  {
    id: 5,
    title: "Pastry Chef",
    startRequired: 1000000,
    tapRequired: 2500000,
    pointsText: "100K",
    points: 100000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level5.png",
    rewards: [{ type: "GOBBL", amount: 250000 }],
  },
  {
    id: 6,
    title: "Sous Chef",
    startRequired: 2500000,
    tapRequired: 5000000,
    pointsText: "250K",
    points: 250000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level6.png",
    rewards: [
      { type: "DISH", amount: 7 },
      { type: "CHIPS", amount: 1500 },
    ],
  },
  {
    id: 7,
    title: "Head Chef",
    startRequired: 5000000,
    tapRequired: 10000000,
    pointsText: "500K",
    points: 500000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level7.png",
    rewards: [{ type: "GOBBL", amount: 750000 }],
  },
  {
    id: 8,
    title: "Executive Chef",
    startRequired: 10000000,
    tapRequired: 50000000,
    pointsText: "1M",
    points: 1000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level8.png",
    rewards: [
      { type: "DISH", amount: 10 },
      { type: "CHIPS", amount: 2000 },
    ],
  },
  {
    id: 9,
    title: "Master Chef",
    startRequired: 50000000,
    tapRequired: 200000000,
    pointsText: "5M",
    points: 5000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level9.png",
    rewards: [{ type: "GOBBL", amount: 7000000 }],
  },
  {
    id: 10,
    title: "Celebrity Chef",
    startRequired: 200000000,
    tapRequired: 500000000,
    pointsText: "7M",
    points: 7000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level10.png",
    rewards: [
      { type: "DISH", amount: 15 },
      { type: "CHIPS", amount: 2500 },
    ],
  },
  {
    id: 11,
    title: "Star Chef",
    startRequired: 500000000,
    tapRequired: 1000000000,
    pointsText: "9M",
    points: 9000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level11.png",
    rewards: [{ type: "GOBBL", amount: 15000000 }],
  },
  {
    id: 12,
    title: "Chef Owner",
    startRequired: 1000000000,
    tapRequired: 2000000000,
    pointsText: "11M",
    points: 11000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level12.png",
    rewards: [
      { type: "DISH", amount: 20 },
      { type: "CHIPS", amount: 3000 },
    ],
  },
  {
    id: 13,
    title: "Chef Patron",
    startRequired: 2000000000,
    tapRequired: 5000000000,
    pointsText: "13M",
    points: 13000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level13.png",
    rewards: [{ type: "GOBBL", amount: 25000000 }],
  },
  {
    id: 14,
    title: "Chef Legend",
    startRequired: 5000000000,
    tapRequired: 5000000000,
    pointsText: "13M",
    points: 13000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level14.png",
    rewards: [
      { type: "DISH", amount: 25 },
      { type: "CHIPS", amount: 5000 },
    ],
  },
  {
    id: 15,
    title: "Chef End",
    startRequired: 5000000000,
    tapRequired: 5000000000000000,
    pointsText: "15M",
    points: 15000000,
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level14.png",
    rewards: [
      { type: "DISH", amount: 50 },
      { type: "CHIPS", amount: 10000 },
    ],
  },
];
export const REFERRAL_TASKS_DATA = [
  {
    id: 0,
    title: "Invite 1 friend",
    referralRequired: 1,
    points: 2500,
    pointsText: "2.5K",
    taskNumber: 301,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 1 },
      { type: "CHIPS", amount: 500 },
    ],
  },
  {
    id: 1,
    title: "Invite 3 friends",
    referralRequired: 3,
    pointsText: "50K",
    points: 50000,
    taskNumber: 302,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 1 },
      { type: "CHIPS", amount: 700 },
    ],
  },
  {
    id: 2,
    title: "Invite 10 friends",
    referralRequired: 10,
    pointsText: "200K",
    points: 200000,
    taskNumber: 303,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 3 },
      { type: "CHIPS", amount: 1000 },
    ],
  },
  {
    id: 3,
    title: "Invite 25 friends",
    referralRequired: 25,
    pointsText: "250K",
    points: 250000,
    taskNumber: 304,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 5 },
      { type: "CHIPS", amount: 2000 },
    ],
  },
  {
    id: 4,
    title: "Invite 50 friends",
    referralRequired: 50,
    pointsText: "300K",
    points: 300000,
    taskNumber: 305,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [{ type: "DISH", amount: 25 }],
  },
  {
    id: 5,
    title: "Invite 100 friends",
    referralRequired: 100,
    pointsText: "500K",
    points: 500000,
    taskNumber: 306,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [{ type: "CHIPS", amount: 5000 }],
  },
  {
    id: 6,
    title: "Invite 500 friends",
    referralRequired: 500,
    pointsText: "2M",
    points: 2000000,
    taskNumber: 307,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [{ type: "DISH", amount: 50 }],
  },
  {
    id: 7,
    title: "Invite 1,000 friends",
    referralRequired: 1000,
    pointsText: "5M",
    points: 5000000,
    taskNumber: 308,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 70 },
      { type: "CHIPS", amount: 10000 },
    ],
  },
  {
    id: 8,
    title: "Invite 10,000 friends",
    referralRequired: 10000,
    pointsText: "10M",
    points: 10000000,
    taskNumber: 309,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 80 },
      { type: "CHIPS", amount: 20000 },
    ],
  },
  {
    id: 9,
    title: "Invite 100,000 friends",
    referralRequired: 100000,
    pointsText: "100M",
    points: 100000000,
    taskNumber: 310,
    type: "REFERRAL",
    url: "https://x.com/intent/retweet?tweet_id=1805921302760260012",
    rewards: [
      { type: "DISH", amount: 100 },
      { type: "CHIPS", amount: 30000 },
    ],
  },
];

// Referral Rewards
export const REFERRAL_REWARDS_DATA = [
  {
    id: 0,
    title: "Trainee",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level0.png",
    gobblRewards: 500,
    tonRewards: 0,
  },
  {
    id: 1,
    title: "Apprentice",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level1.png",
    gobblRewards: 2500,
    tonRewards: 0.001,
  },
  {
    id: 2,
    title: "Chef Assistant",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level2.png",
    gobblRewards: 5000,
    tonRewards: 0.002,
  },
  {
    id: 3,
    title: "Junior Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level3.png",
    gobblRewards: 10000,
    tonRewards: 0.004,
  },
  {
    id: 4,
    title: "Station Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level4.png",
    gobblRewards: 20000,
    tonRewards: 0.008,
  },
  {
    id: 5,
    title: "Pastry Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level5.png",
    gobblRewards: 30000,
    tonRewards: 0.015,
  },
  {
    id: 6,
    title: "Sous Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level6.png",
    gobblRewards: 40000,
    tonRewards: 0.03,
  },
  {
    id: 7,
    title: "Head Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level7.png",
    gobblRewards: 50000,
    tonRewards: 0.06,
  },
  {
    id: 8,
    title: "Executive Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level8.png",
    gobblRewards: 60000,
    tonRewards: 0.12,
  },
  {
    id: 9,
    title: "Master Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level9.png",
    gobblRewards: 100000,
    tonRewards: 0.25,
  },
  {
    id: 10,
    title: "Celebrity Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level10.png",
    gobblRewards: 150000,
    tonRewards: 0.4,
  },
  {
    id: 11,
    title: "Star Chef",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level11.png",
    gobblRewards: 200000,
    tonRewards: 0.5,
  },
  {
    id: 12,
    title: "Chef Owner",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level12.png",
    gobblRewards: 250000,
    tonRewards: 0.6,
  },
  {
    id: 13,
    title: "Chef Patron",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level13.png",
    gobblRewards: 300000,
    tonRewards: 0.75,
  },
  {
    id: 14,
    title: "Chef Legend",
    img: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/level/level14.png",
    gobblRewards: 350000,
    tonRewards: 1,
  },
];
