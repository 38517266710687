import { Alert, Slide, Snackbar, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorPopup } from "../reducers/UiReducers";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const ErrorSnackbar = ({ style }) => {
  const dispatch = useDispatch();
  const errorPopup = useSelector((state) => state.ui.errorPopup);
  const errorMsg = useSelector((state) => state.ui.errorMsg);
  return (
    <Snackbar
      open={errorPopup}
      onClose={() => dispatch(setErrorPopup({ flag: false, msg: "" }))}
      TransitionComponent={SlideTransition}
      autoHideDuration={600}
      style={{
        position: "absolute",
        zIndex: 1111,
        ...style,
        pointerEvents: "none",
      }}
    >
      <Alert
        icon={false}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "94%",
          height: "48px",
          background: "linear-gradient(180deg, red 0%, #FF4C4C 100%)",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.45)",
          borderRadius: "16px",
          marginLeft: "3%",
          marginBottom: "100px",
        }}
      >
        <Typography
          style={{
            fontFamily: "'Karla'",
            fontWeight: 700,
            fontSize: "14px",
            color: "#000000",
          }}
        >
          {errorMsg}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
