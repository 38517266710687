import { useEffect, useState } from "react";
import { useServerAuth } from "./useServerAuth";
import {
  ENERGY_LIMIT_DATA,
  RECHARGE_SPEED_DATA,
  TAP_DATA,
} from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMultiTap,
  updatePlayValues,
  updateScreenLoaded,
  getBackendDataToRedux,
  setIsBannedFlag,
} from "../reducers/UiReducers";
import {
  updateTapScoreToBackendAPI,
  updateUsernameToBackendAPI,
} from "../actions/serverActions";
import useTelegramSDK from "./useTelegramSDK";
import { useNavigate } from "react-router-dom";

const useGameHook = (hookInit = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tapScore = useSelector((state) => state.ui.tapScore);
  const username = useSelector((state) => state.ui.username);
  const energyLeft = useSelector((state) => state.ui.energyLeft);
  const playLevels = useSelector((state) => state.ui.playLevels);
  const dataLoaded = useSelector((state) => state.ui.dataLoaded);
  const refetchData = useSelector((state) => state.ui.refetchData);

  const { accountSC } = useServerAuth();
  const { telegramUsername, isMobileUser, WebAppSDK, isAndroid } =
    useTelegramSDK();

  const [heightChanges, setHeightChanges] = useState(0);

  //1.  To Manage initial loading of the application
  useEffect(() => {
    async function asyncFn() {
      if (hookInit && accountSC && isMobileUser) {
        // //1.  Load Backend
        await dispatch(getBackendDataToRedux(accountSC));
      }
    }

    asyncFn();
  }, [accountSC, hookInit, isMobileUser, refetchData]);

  //2.  To Update username
  useEffect(() => {
    async function asyncFn() {
      if (hookInit && accountSC && isMobileUser) {
        // STEP:1 Update username to backend
        if (
          (username && telegramUsername && username === "") ||
          username !== telegramUsername
        ) {
          // Update Local Data to Backend server
          await updateUsernameToBackendAPI(
            { username: telegramUsername },
            accountSC
          );
        }

        //2. Disable if any multiTap is running
        await dispatch(updateMultiTap(false));
        await dispatch(updateScreenLoaded(true));
      }
    }

    asyncFn();
  }, [accountSC, hookInit, telegramUsername, isMobileUser, username]);

  //3. Update playValues after levelData changes
  useEffect(() => {
    if (playLevels && hookInit) {
      dispatch(
        updatePlayValues({
          tap: TAP_DATA[playLevels.tap].tapCount,
          energy: ENERGY_LIMIT_DATA[playLevels.energy]?.energyLimit,
          recharge: RECHARGE_SPEED_DATA[playLevels.recharge].speed,
        })
      );
    }
  }, [playLevels.tap, playLevels.energy, playLevels.recharge, hookInit]);

  //5.  To Enable Events of Telegram for user
  useEffect(() => {
    async function asyncFn() {
      if (hookInit && accountSC && isMobileUser && dataLoaded && !isAndroid) {
        await WebAppSDK.onEvent("viewportChanged", async (data) => {
          setHeightChanges(heightChanges + 1);
        });
      }
    }

    asyncFn();
  }, [accountSC, hookInit, isMobileUser, dataLoaded, isAndroid]);

  const syncUpData = async () => {
    let res = await updateTapScoreToBackendAPI(
      tapScore,
      energyLeft,
      Date.now(),
      accountSC
    );

    if (res === false) {
      dispatch(setIsBannedFlag(true));
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (hookInit && accountSC) {
        syncUpData();
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [tapScore, hookInit, accountSC, heightChanges]);

  return {};
};

export default useGameHook;
