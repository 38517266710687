import React, { useEffect, useRef, useState } from "react";
import Home from "./pages/Home";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import Navbar from "./components/Navbar";
import Referral from "./pages/Referral";
import Dashboard from "./pages/Dashboard";
import Tasks from "./pages/Tasks";
import Boost from "./pages/Boost";
import { useServerAuth } from "./hooks/useServerAuth";
import League from "./pages/League";
import useTelegramSDK from "./hooks/useTelegramSDK";
import { BackButton } from "./components/BackButton";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import Spin from "./pages/Spin";
import UnderMintenancePopup from "./components/UnderMintenancePopup";
import DailyBox from "./pages/DailyBox";
import useSound from "use-sound";
import { useSelector } from "react-redux";
import SuspiciousActivityPopup from "./components/SuspiciousActivityPopup";
import FoodFive from "./pages/FoodFive";
import Games from "./pages/Games";
import FoodFury from "./pages/FoodFury";
import Dishes from "./pages/Dishes";
import OnboardingFlow from "./components/OnboardingFlow";

function App() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { navigate } = useNavigate();
  const { accountSC, authLoaded } = useServerAuth(true);
  const { WebAppSDK } = useTelegramSDK();
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [suspiciousActivityPopup, setSuspiciousActivityPopup] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const isMuted = useSelector((state) => state.ui.isMuted);
  const isBanned = useSelector((state) => state.ui.isBanned);

  // const [play, { stop }] = useSound(
  //   "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/audio/bgm2.mp3",
  //   {
  //     loop: true,
  //     volume: isMuted ? 0 : 0.75,
  //     soundEnabled: !isMuted,
  //   }
  // );

  // To detect authentication loaded
  useEffect(() => {
    if (authLoaded && accountSC) {
      WebAppSDK.expand();
      WebAppSDK.ready();
      WebAppSDK.disableVerticalSwipes();
      WebAppSDK.enableClosingConfirmation();
      WebAppSDK.setHeaderColor("#000000");
      WebAppSDK.setBackgroundColor("#000000");
    }
  }, [accountSC, authLoaded, WebAppSDK]);

  // useEffect(() => {
  //   (async () => play())();
  //   return () => {
  //     stop();
  //   };
  // }, [play, stop]);

  useEffect(() => {
    if (isBanned) {
      setSuspiciousActivityPopup(true);
    }
  }, [isBanned]);

  return (
    <TonConnectUIProvider manifestUrl="https://gobbltest.vercel.app/tonconnect-manifest.json">
      <div
        style={{
          width: "100%",
          height: "100vh",
          minHeight: WebAppSDK?.viewportHeight,
          zIndex: 0,
        }}
      >
        {pathname !== "/tap" && (
          <BackButton
            onClick={() => {
              if (pathname === "/food5" || pathname === "/foodfury") {
                window.location.href = "/";
              } else window.location.href = "/tap";
            }}
          />
        )}
        <Routes>
          <Route exact path="/" element={<Games />} />
          <Route exact path="/tap" element={<Home />} />
          <Route exact path="/league" element={<League />} />
          <Route exact path="/referral" element={<Referral />} />
          <Route exact path="/tasks" element={<Tasks />} />
          <Route exact path="/boost" element={<Boost />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/spin" element={<Spin />} />
          <Route exact path="/daily-box" element={<DailyBox />} />
          <Route exact path="/food5" element={<FoodFive />} />
          <Route exact path="/foodfury" element={<FoodFury />} />
          <Route exact path="/dishes" element={<Dishes />} />
        </Routes>
        {pathname !== "/food5" && pathname !== "/foodfury" && (
          <Navbar bg="#000" />
        )}
        <OnboardingFlow />
        {/* <UnderMintenancePopup
          maintenancePopup={maintenancePopup}
          setMaintenancePopup={setMaintenancePopup}
        /> */}

        {/* <SuspiciousActivityPopup
          suspiciousActivityPopup={suspiciousActivityPopup}
          setSuspiciousActivityPopup={setSuspiciousActivityPopup}
        /> */}
        {pathname !== "/tap" && (
          <Box
            style={{
              width: "100%",
              height: "100vh",
              background:
                "url(https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/bg_grid.png)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              position: "fixed",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
          />
        )}
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
