import { useEffect, useState } from "react";
import ethersServiceProvider from "../services/ethersServicesProvider";
import constants from "../utils/constants";

const useTelegramSDK = (hookInit = false) => {
  const [userId, setUserID] = useState(0);
  const [tgUsername, setTgUsername] = useState("User");
  const [referParam, setReferParam] = useState(0);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [writeToMsg, setWriteToMsg] = useState(false);
  const [fullName, setFullName] = useState("");
  const WebApp = window.Telegram.WebApp;
  const user = WebApp.initDataUnsafe?.user;

  useEffect(() => {
    if (hookInit) {
      if (WebApp.initDataUnsafe?.user?.is_premium) {
        setIsPremiumUser(WebApp.initDataUnsafe?.user?.is_premium);
      }

      if (WebApp.initDataUnsafe?.start_param) {
        setReferParam(WebApp.initDataUnsafe?.start_param);
      }
      if (WebApp.initDataUnsafe?.user?.id) {
        setUserID(WebApp.initDataUnsafe.user.id);
      }
      if (WebApp.initDataUnsafe?.user) {
        setWriteToMsg(WebApp.initDataUnsafe.user.allows_write_to_pm);
      }
      if (WebApp.initDataUnsafe?.user?.first_name) {
        let nameValue =
          WebApp.initDataUnsafe.user.first_name +
          WebApp.initDataUnsafe.user.last_name;
        setFullName(nameValue);
      }
      if (WebApp.initDataUnsafe?.user) {
        setTgUsername(WebApp.initDataUnsafe.user.username);
      }

      // setTimeout(() => {
      //   console.log("Loaded");
      //   setUserID(1118251889);
      // }, 500);
    }
  }, [WebApp, hookInit]);

  useEffect(() => {
    if (WebApp.initDataUnsafe?.user) {
      ethersServiceProvider.setTGUsername(WebApp.initDataUnsafe.user.username);
      ethersServiceProvider.setTGPhotoUrl(
        JSON.stringify(WebApp.initDataUnsafe.user.photo_url)
      );
    }
    if (WebApp.initDataUnsafe?.user) {
      setTgUsername(WebApp.initDataUnsafe.user.username);
    }
    if (WebApp.initDataUnsafe?.user?.first_name) {
      let nameValue =
        WebApp.initDataUnsafe.user.first_name +
        WebApp.initDataUnsafe.user.last_name;
      setFullName(nameValue);
    }
  }, [WebApp]);

  const _username = ethersServiceProvider.tgUsername;
  const _photoUrl = ethersServiceProvider.photoUrl;

  // const _isMobile =
  //   constants.net === 1
  //     ? true
  //     : WebApp.platform === "android" || WebApp.platform === "ios"
  //     ? true
  //     : false;
  const _isMobile = true;
  const _isAndroid = WebApp.platform === "android" ? true : false;

  const openNewUrl = async (url) => {
    WebApp.HapticFeedback.impactOccurred("light");
    await WebApp.openLink(url);
  };

  const _openNativeUrl = async (url) => {
    WebApp.HapticFeedback.impactOccurred("light");
    await WebApp.openTelegramLink(url);
  };

  const viberate = async (type) => {
    WebApp.HapticFeedback.impactOccurred(type);
  };

  return {
    telegramUserId: userId,
    telegramIsPremiumUser: isPremiumUser,
    telegramFullName: fullName,
    telegramReferId: referParam,
    telegramWritePM: writeToMsg,
    telegramUsername: tgUsername,
    telegramPhotoUrl: _photoUrl,
    WebAppSDK: WebApp,
    openTelegramUrl: openNewUrl,
    openNativeUrl: _openNativeUrl,
    viberate: viberate,
    isMobileUser: _isMobile,
    isAndroid: _isAndroid,
  };
};

export default useTelegramSDK;
