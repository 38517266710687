import { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useServerAuth } from "../hooks/useServerAuth";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { signUpFoodFiveWithTG } from "../actions/serverActions";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useNavigate } from "react-router-dom";
import constants from "../utils/constants";
import Slider from "react-slick";

const tipsData = [
  {
    description: "Cards are swapped during a clash.",
    src: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/tips_slider1.webp",
  },
  {
    description:
      "Do not give up till the last card, there is always a legendary comeback to be made.",
    src: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/tips_slider2.webp",
  },
  {
    description:
      "Choose your best cards to have a higher chance of victory in Master Chef.",
    src: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/tips_slider3.webp",
  },
  {
    description:
      "Upgrading your cards will give you an upper hand against opponents.",
    src: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/tips_slider4.webp",
  },
  {
    description:
      "Having a variety of Dish cards will make your moves less predictable.",
    src: "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/tips_slider5.webp",
  },
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 5,
      }}
      onClick={onClick}
    >
      <Button
        style={{
          position: "absolute",
          maxWidth: 24,
          minWidth: 24,
          top: -170,
          right: 40,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/images/next_icon.svg"
          style={{ maxWidth: 24, minWidth: 24, zIndex: 5 }}
        />
      </Button>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 5,
      }}
      onClick={onClick}
    >
      <Button
        style={{
          position: "absolute",
          maxWidth: 24,
          minWidth: 24,
          top: -170,
          left: 40,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/images/prev_icon.svg"
          style={{ maxWidth: 24, minWidth: 24, zIndex: 5 }}
        />
      </Button>
    </div>
  );
}

function FoodFive() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const { telegramUserId, telegramReferId, telegramFullName } =
    useTelegramSDK(true);
  const { accountSC, authloaded } = useServerAuth();
  const [startGame, setStartGame] = useState(true);
  const [showTips, setShowTips] = useState(false);

  const settings = {
    initialSlide: Math.floor(Math.random() * 6),
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "fade",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShowTips(true);
    }, 1000);

    return () => clearTimeout(showTimeout);
  }, []);

  const build =
    constants.net === 1
      ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/webgl-builds/FOODFIVE/Development/FoodFiveTelegram/Build/"
      : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/webgl-builds/FOODFIVE/Production/FoodFiveTelegram/Build/";

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
    loadingProgression,
    unload,
  } = useUnityContext({
    loaderUrl: build + "FoodFiveTelegram.loader.js",
    dataUrl: build + "FoodFiveTelegram.data.unityweb",
    frameworkUrl: build + "FoodFiveTelegram.framework.js.unityweb",
    codeUrl: build + "FoodFiveTelegram.wasm.unityweb",
    // cacheControl: () => "no-store",
  });

  const UnityHandler_CopyToClipBoard = useCallback(async () => {
    console.log(
      "Call from UNITY - CopyToClipBoard",
      `t.me/Food5TestBot/game?startapp=${telegramUserId}`
    );
    navigator.clipboard.writeText(
      `t.me/Food5TestBot/game?startapp=${telegramUserId}`
    );
  }, [authloaded, isLoaded]);

  const UnityHandler_TelegramReferralUrl = useCallback(async () => {
    console.log("Call from UNITY - SetPlayerIdAndReferalUrl_JS");
    window.location.href = `https://t.me/GobblUpBot/game?startapp=${telegramUserId}`;
  }, [authloaded, isLoaded]);

  const UnityHandler_ExitToMain = useCallback(async () => {
    console.log("Call from UNITY - ExitToMain");
    await unload().then(() => navigate("/tap"));
  }, [authloaded, isLoaded]);

  useEffect(() => {
    addEventListener("CopyToClipBoard", UnityHandler_CopyToClipBoard);
    addEventListener("ExitToMain", UnityHandler_ExitToMain);
    addEventListener(
      "SetPlayerIdAndReferalUrl_JS",
      UnityHandler_TelegramReferralUrl
    );
    return () => {
      removeEventListener("CopyToClipBoard", UnityHandler_CopyToClipBoard);
      removeEventListener("ExitToMain", UnityHandler_ExitToMain);
      removeEventListener(
        "SetPlayerIdAndReferalUrl_JS",
        UnityHandler_TelegramReferralUrl
      );
    };
  }, [
    addEventListener,
    removeEventListener,
    UnityHandler_CopyToClipBoard,
    UnityHandler_ExitToMain,
    UnityHandler_TelegramReferralUrl,
  ]);

  useEffect(() => {
    setStartGame(true);
    (async () => {
      if (isLoaded) {
        const res = await signUpFoodFiveWithTG(
          telegramUserId,
          "TELEGRAM",
          telegramReferId
        );
        const userInfo = {
          loginId: res._id,
          tgId: telegramUserId,
          userName: telegramFullName,
        };
        console.log("userInfo", userInfo);
        sendMessage("URLLoginManager", "GetUserId", JSON.stringify(userInfo));
      }
    })();
  }, [isLoaded, telegramUserId, accountSC]);

  return (
    <Box minHeight="100vh" position="relative" zIndex={1}>
      <div
        style={{
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          textAlign: "center",
          color: "white",
          opacity: !startGame ? 0 : 1,
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: "#2E06CF",
        }}
      >
        <div
          style={{
            display: !isLoaded ? "flex" : "none",
            width: "100%",
            minWidth: "100vw",
            height: "100vh",
            zIndex: 0,
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "3vh",
              position: "relative",
              zIndex: 11,
              paddingTop: "10%",
            }}
          >
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/food5_logo.webp"
              loading="eager"
              style={{
                width: "100%",
                maxWidth: "80%",
                zIndex: 2,
                margin: "auto 0",
                objectFit: "contain",
              }}
            />
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/games/food5_bg_vector.svg"
              loading="eager"
              style={{
                position: "absolute",
                top: "-1vh",
                bottom: 0,
                minHeight: "104vh",
                width: "100%",
                pointerEvents: "none",
              }}
            />
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/food5_bg_gradient.png"
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                height: "100%",
                width: "100%",
                pointerEvents: "none",
                objectFit: "cover",
                zIndex: 1,
                opacity: 0.08,
                filter: "brightness(50%)",
              }}
            />

            {/* tips slider */}
            <Box
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "15px",
                position: "relative",
                zIndex: 2,
                marginTop: "auto",
                marginBottom: "40px",
                opacity: showTips ? 1 : 0,
              }}
            >
              <Typography
                style={{
                  fontFamily: "'Montserrat'",
                  fontWeight: 900,
                  fontSize: "24px",
                  lineHeight: "29px",
                  textAlign: "center",
                  color: "#D1FF19",
                }}
              >
                GAMEPLAY TIPS
              </Typography>
              <Box
                style={{
                  width: "260px",
                  height: "3px",
                  opacity: 0.9,
                  borderRadius: "2px",
                  background:
                    "linear-gradient(90deg, #CF1A8F 0%, #08A6EA 53%, #AE11EB 100%)",
                }}
              />
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              >
                <Slider {...settings}>
                  {tipsData.map((data, i) => (
                    <Box
                      key={i}
                      style={{
                        width: "100%",
                        zIndex: 2,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        style={{
                          minHeight: 50,
                          maxWidth: 265,
                          margin: "0 auto",
                          fontFamily: "'Roboto'",
                          fontStyle: "italic",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "120%",
                          letterSpacing: "1px",
                          textAlign: "center",
                          WebkitTextFillColor: "#FFFF00",
                          WebkitTextStrokeWidth: "0.5px",
                          WebkitTextStrokeColor: "black",
                          textShadow: "0 1px 0 #000000",
                          position: "relative",
                        }}
                      >
                        {data.description}
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          height: 305,
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={data.src}
                          style={{
                            width: "85vw",
                            height: i == 1 ? 220 : 250,
                            objectFit: "contain",
                            // position: "absolute",
                            // bottom: i == 2 || i == 4 ? 80 : 40,
                            // marginLeft: i == 3 && "-20px",
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </Box>
            </Box>

            {/* progressbar */}
            <div
              style={{
                width: "90vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2vw",
                position: "absolute",
                bottom: 45,
                zIndex: 2,
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderRadius: "5vh",
                  padding: "0.35vh",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#FF9416",
                    height: "12px",
                    borderRadius: "3vh",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width:
                        Math.round(loadingProgression * 100) > 25
                          ? `${Math.round(loadingProgression * 100)}%`
                          : "25%",
                      height: "100%",
                      backgroundColor: "#EBEB06",
                      position: "relative",
                      borderRadius: "5vh",
                    }}
                  >
                    <Box
                      style={{
                        zIndex: 4,
                        position: "absolute",
                        top: "-15px",
                        right: -1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/assets/games/food5_loader.svg"
                        alt="FoodVerse FOOD5"
                        style={{
                          width: 38,
                          height: 32,
                        }}
                      />
                      <h3
                        style={{
                          zIndex: 3,
                          color: "#FFFF00",
                          fontFamily: "Rubik",
                          fontSize: "20px",
                          lineHeight: "100%",
                          whiteSpace: "nowrap",
                          position: "absolute",
                          bottom: -35,
                        }}
                      >
                        {Math.round(loadingProgression * 100)}%
                      </h3>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/food5_bg_pattern.svg"
              alt="FoodVerse FOOD5"
              loading="eager"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                zIndex: -1,
                position: "absolute",
                left: 0,
                top: 0,
              }}
            />
          </div>
        </div>
        <Unity
          unityProvider={unityProvider}
          devicePixelRatio={window?.devicePixelRatio || 2}
          style={{
            height: "100vh",
            width: "100%",
            opacity: isLoaded ? 1 : 0,
          }}
        />
      </div>
    </Box>
  );
}

export default FoodFive;
