import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grow,
  Typography,
  useMediaQuery,
  Zoom,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { useServerAuth } from "../hooks/useServerAuth";
import {
  getTasksData,
  setDailyTaskStatusToBackend,
  setLeagueClaimToBackend,
  setTaskStatusToBackend,
} from "../actions/serverActions";
import useTelegramSDK from "../hooks/useTelegramSDK";
import {
  LEAGUE_TASKS_DATA,
  REFERRAL_TASKS_DATA,
  SPECIAL_TASKS_DATA,
} from "../utils/constants";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuccessSnackbar from "../components/SuccessSnackbar";
import { updateRefetchData, updateTaskRewards } from "../reducers/UiReducers";
import ProgressBar from "../components/ProgressBar";
import {
  getNumbersInFormat,
  getNumbersInFormatOnlyMillions,
  numberWithCommas,
} from "../actions/helperFn";
import StickyBox from "react-sticky-box";
import UserDashboard from "../components/UserDashboard";
import TaskRewardPopup from "../components/TaskRewardPopup";
import TimerComp from "../components/TimerComponent";

const tabs = [
  { no: 0, name: "Social" },
  { no: 1, name: "Levels" },
  { no: 2, name: "Ref Tasks" },
  { no: 3, name: "Daily" },
];

const ActionButton = ({
  children,
  onClick,
  color,
  style,
  fontStyle,
  disabled = false,
}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Button
      style={{
        minWidth: 70,
        maxWidth: 70,
        height: 28,
        display: "flex",
        alignItems: "center",
        paddingRight: "6px",
        textTransform: "capitalize",
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        style={{
          minWidth: "100%",
          height: 28,
          borderRadius: "4px",
          fontFamily: "Rubik",
          fontWeight: 700,
          fontSize: 12,
          color: "#000",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: color ? color : !disabled ? "#FF9CFF" : "#b2b2b2",
          paddingLeft: "3px",
          zIndex: 1,
          ...fontStyle,
        }}
      >
        {children}
      </Typography>
      <Box
        style={{
          minWidth: 20,
          height: 20,
          borderRadius: "3px",
          background: color ? color : !disabled ? "#FF9CFF" : "#b2b2b2",
          transform: "rotate(45deg)",
          marginLeft: -12,
        }}
      />
    </Button>
  );
};

const SingleTask = ({
  isDailyTask,
  taskId,
  name,
  url,
  type,
  points,
  pointsText,
  background,
  inProgress,
  setInProgress,
  setIsVerifying,
  rewards,
}) => {
  const dispatch = useDispatch();
  const { openTelegramUrl, viberate, telegramFullName, WebAppSDK } =
    useTelegramSDK();
  const { accountSC } = useServerAuth();

  const specialTasksStatus = useSelector(
    (state) => state.ui.specialTasksStatus
  );

  let currentTaskStatus = () => {
    let tempValue = specialTasksStatus[taskId];
    if (tempValue === 1 || tempValue === 2 || tempValue === 3) {
      return tempValue;
    } else {
      return 0;
    }
  };

  // OPEN URL - STATUS = Started
  const onClickAction = async () => {
    if (currentTaskStatus() === 0 && inProgress === -1) {
      setInProgress(taskId);

      let isContains = false;
      let dataObj = {};
      if (type === "LINK") {
        await openTelegramUrl(url);
      }
      if (type === "STORY") {
        await WebAppSDK.shareToStory(
          "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/Story_Graphics_Gobbl_1.mp4",
          {
            text: "Play GobblUp and Earn TON. Easy Withdrawals.",
            widget_link: { name: "Play Now", url: "https://t.me/GobblUpBot" },
          }
        );
      }
      if (type === "NATIVE") {
        isContains = telegramFullName.includes("🍟");
      }

      if (type !== "NATIVE") {
        dataObj = {
          userId: accountSC,
          taskId: taskId,
          status: 1,
        };
      } else {
        dataObj = {
          userId: accountSC,
          taskId: taskId,
          status: isContains ? 2 : 1,
        };
      }

      let res = await setTaskStatusToBackend(dataObj);
      if (res) {
        await dispatch(updateRefetchData());
      }

      setTimeout(() => {
        setInProgress(-1);
        setIsVerifying(false);
      }, 10000);
    }
    if (inProgress !== -1) {
      setIsVerifying(true);
    }
  };

  // OPEN URL - STATUS = Retry
  const onClickRetryAction = async () => {
    if (currentTaskStatus() === 1 && inProgress === -1) {
      setInProgress(taskId);
      let isContains = false;
      let dataObj = {};
      if (type === "LINK") {
        await openTelegramUrl(url);
      }
      if (type === "NATIVE") {
        isContains = telegramFullName.includes("🍟");
        // Upgrade booster by type to backendAPI
      }

      if (type !== "NATIVE") {
        // Upgrade booster by type to backendAPI
        dataObj = {
          userId: accountSC,
          taskId: taskId,
          status: 2,
        };
      } else {
        dataObj = {
          userId: accountSC,
          taskId: taskId,
          status: isContains ? 2 : 1,
        };
      }

      let res = await setTaskStatusToBackend(dataObj);
      if (res) {
        await dispatch(updateRefetchData());
      }

      setTimeout(() => {
        setInProgress(-1);
        setIsVerifying(false);
      }, 10000);
    }
    if (inProgress !== -1) {
      setIsVerifying(true);
    }
  };

  // CLAIM REWARDS
  const onClickClaim = async () => {
    // Upgrade booster by type to backendAPI
    let dataObj = {
      userId: accountSC,
      taskId: taskId,
      status: 3,
    };

    let res = await setTaskStatusToBackend(dataObj);
    if (res?.error === false) {
      await dispatch(updateTaskRewards(res.result));
      await dispatch(updateRefetchData());
    }

    viberate("medium");
  };

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "65px",
        background: background
          ? background
          : "linear-gradient(271.44deg, #7848FF 0.29%, #346DFF 98.45%)",
        border: "0.498756px solid #FFFFFF",
        borderRadius: "12px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 15px",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontFamily: "Karla",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "120%",
            color: "#FFFFFF",
          }}
        >
          {name}
        </Typography>
        {isDailyTask && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {true && (
              <svg
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11C0.725 11 0.489667 10.9022 0.294 10.7065C0.0983332 10.5108 0.000333333 10.2753 0 10V5C0 4.725 0.0979999 4.48967 0.294 4.294C0.49 4.09833 0.725333 4.00033 1 4H1.5V3C1.5 2.30833 1.74383 1.71883 2.2315 1.2315C2.71917 0.744167 3.30867 0.500334 4 0.5C4.69133 0.499667 5.281 0.7435 5.769 1.2315C6.257 1.7195 6.50067 2.309 6.5 3V4H7C7.275 4 7.5105 4.098 7.7065 4.294C7.9025 4.49 8.00033 4.72533 8 5V10C8 10.275 7.90217 10.5105 7.7065 10.7065C7.51083 10.9025 7.27533 11.0003 7 11H1ZM4 8.5C4.275 8.5 4.5105 8.40217 4.7065 8.2065C4.9025 8.01083 5.00033 7.77533 5 7.5C4.99967 7.22467 4.90183 6.98933 4.7065 6.794C4.51117 6.59867 4.27567 6.50067 4 6.5C3.72433 6.49933 3.489 6.59733 3.294 6.794C3.099 6.99067 3.001 7.226 3 7.5C2.999 7.774 3.097 8.0095 3.294 8.2065C3.491 8.4035 3.72633 8.50133 4 8.5ZM2.5 4H5.5V3C5.5 2.58333 5.35417 2.22917 5.0625 1.9375C4.77083 1.64583 4.41667 1.5 4 1.5C3.58333 1.5 3.22917 1.64583 2.9375 1.9375C2.64583 2.22917 2.5 2.58333 2.5 3V4Z"
                  fill="#D7D7D7"
                />
              </svg>
            )}

            {true ? (
              <TimerComp
                endTime={172647557973912}
                color={"white"}
                fontSize={12}
                fontWeight={400}
              />
            ) : (
              <Typography
                style={{
                  fontFamily: "'Karla'",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "100%",
                  color: "#64FF99",
                }}
              >
                Unlocked Now!
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box
        style={{
          minWidth: currentTaskStatus() === 3 ? "97px" : "auto",
          height: "24px",
          padding: "0 10px",
          background: currentTaskStatus() === 3 ? "#018724" : "#FAFF00",
          borderRadius: "8px",
          fontFamily: "'Karla'",
          fontWeight: 500,
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          textAlign: "center",
          color: currentTaskStatus() === 3 ? "#FAFF00" : "#000000",
          position: "absolute",
          bottom: -11,
        }}
      >
        {currentTaskStatus() === 3 && (
          <img
            src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/check.png"
            style={{ width: 16, height: 16 }}
          />
        )}
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {rewards.map((ele) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={
                    ele.type === "DISH"
                      ? "/images/task_dish_icon.svg"
                      : ele.type === "CHIPS"
                      ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                      : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                  }
                  style={{ width: 16, height: 16 }}
                />
                <Typography
                  style={{
                    padding: "0 2px",
                    fontFamily: "'Karla'",
                    fontWeight: 500,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    textAlign: "center",
                    color: currentTaskStatus() === 3 ? "#FAFF00" : "#000000",
                  }}
                >
                  {numberWithCommas(ele.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      {currentTaskStatus() === 0 && taskId !== 3 && taskId !== 9 && (
        <ActionButton onClick={() => onClickAction(taskId)}>Start</ActionButton>
      )}
      {currentTaskStatus() === 0 && type === "TGLINK" && (
        <ActionButton onClick={() => onClickAction(taskId)}>
          <a href={url} style={{ color: "black" }}>
            Start
          </a>
        </ActionButton>
      )}
      {currentTaskStatus() === 1 &&
        inProgress != taskId &&
        type != "TGLINK" && (
          <ActionButton
            onClick={() => onClickRetryAction(taskId)}
            color="#ef5350"
          >
            Check
          </ActionButton>
        )}
      {currentTaskStatus() === 1 &&
        inProgress != taskId &&
        type === "TGLINK" && (
          <ActionButton
            onClick={() => onClickRetryAction(taskId)}
            color="#ef5350"
          >
            <a href={url} style={{ color: "black" }}>
              Check
            </a>
          </ActionButton>
        )}

      {currentTaskStatus() === 2 && inProgress != taskId && (
        <ActionButton
          onClick={currentTaskStatus === 0 ? null : onClickClaim}
          disabled={currentTaskStatus === 0}
          color="#faff00"
        >
          Claim
        </ActionButton>
      )}

      {(currentTaskStatus() === 1 || currentTaskStatus() === 2) &&
        inProgress === taskId && (
          <Button
            style={{
              minWidth: 50,
              maxWidth: 100,
              height: 28,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Rubik",
              fontWeight: 500,
              fontSize: "9px",
              textAlign: "center",
              color: "#FFB219",
              border: "1px solid #FFB219",
              borderRadius: "4px",
              textTransform: "capitalize",
            }}
          >
            Verifying <div className="loader"></div>
          </Button>
        )}

      {currentTaskStatus() === 3 && (
        <ActionButton disabled={true} color="#64ff99">
          Claimed
        </ActionButton>
      )}
    </Box>
  );
};

const SingleDailyTask = ({
  isDailyTask,
  taskId,
  name,
  background,
  nextUTC,
  rewards,
}) => {
  const dispatch = useDispatch();
  const { viberate } = useTelegramSDK();
  const { accountSC } = useServerAuth();

  const dailyTaskStatus = useSelector((state) => state.ui.dailyTaskStatus);

  let currentTaskStatus = useMemo(() => {
    let tempValue = dailyTaskStatus[taskId];
    if (tempValue === 2 || tempValue === 3) {
      return tempValue;
    } else {
      return 1;
    }
  }, [dailyTaskStatus]);

  // CLAIM REWARDS
  const onClickClaim = async () => {
    // Upgrade booster by type to backendAPI
    let dataObj = {
      userId: accountSC,
      taskId: taskId,
      status: 3,
    };

    let res = await setDailyTaskStatusToBackend(dataObj);
    if (res?.error === false) {
      await dispatch(updateTaskRewards(res.result));
      await dispatch(updateRefetchData());
    }

    viberate("medium");
  };

  const isTimerUnlocked = useMemo(() => {
    if (currentTaskStatus === 3 && nextUTC - Date.now() > 0) {
      return true;
    }
    return false;
  }, [nextUTC, currentTaskStatus]);

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "65px",
        background: background
          ? background
          : "linear-gradient(271.44deg, #7848FF 0.29%, #346DFF 98.45%)",
        border: "0.498756px solid #FFFFFF",
        borderRadius: "12px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 15px",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontFamily: "Karla",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "120%",
            color: "#FFFFFF",
          }}
        >
          {name}
        </Typography>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isTimerUnlocked && (
            <svg
              width="8"
              height="11"
              viewBox="0 0 8 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 11C0.725 11 0.489667 10.9022 0.294 10.7065C0.0983332 10.5108 0.000333333 10.2753 0 10V5C0 4.725 0.0979999 4.48967 0.294 4.294C0.49 4.09833 0.725333 4.00033 1 4H1.5V3C1.5 2.30833 1.74383 1.71883 2.2315 1.2315C2.71917 0.744167 3.30867 0.500334 4 0.5C4.69133 0.499667 5.281 0.7435 5.769 1.2315C6.257 1.7195 6.50067 2.309 6.5 3V4H7C7.275 4 7.5105 4.098 7.7065 4.294C7.9025 4.49 8.00033 4.72533 8 5V10C8 10.275 7.90217 10.5105 7.7065 10.7065C7.51083 10.9025 7.27533 11.0003 7 11H1ZM4 8.5C4.275 8.5 4.5105 8.40217 4.7065 8.2065C4.9025 8.01083 5.00033 7.77533 5 7.5C4.99967 7.22467 4.90183 6.98933 4.7065 6.794C4.51117 6.59867 4.27567 6.50067 4 6.5C3.72433 6.49933 3.489 6.59733 3.294 6.794C3.099 6.99067 3.001 7.226 3 7.5C2.999 7.774 3.097 8.0095 3.294 8.2065C3.491 8.4035 3.72633 8.50133 4 8.5ZM2.5 4H5.5V3C5.5 2.58333 5.35417 2.22917 5.0625 1.9375C4.77083 1.64583 4.41667 1.5 4 1.5C3.58333 1.5 3.22917 1.64583 2.9375 1.9375C2.64583 2.22917 2.5 2.58333 2.5 3V4Z"
                fill="#D7D7D7"
              />
            </svg>
          )}

          {isTimerUnlocked ? (
            <TimerComp
              endTime={nextUTC}
              color={"white"}
              fontSize={12}
              fontWeight={400}
            />
          ) : (
            <Typography
              style={{
                fontFamily: "'Karla'",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "100%",
                color: "#64FF99",
              }}
            >
              Unlocked Now!
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        style={{
          minWidth: currentTaskStatus === 3 ? "97px" : "auto",
          height: "24px",
          padding: "0 10px",
          background: currentTaskStatus === 3 ? "#018724" : "#FAFF00",
          borderRadius: "8px",
          fontFamily: "'Karla'",
          fontWeight: 500,
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          textAlign: "center",
          color: currentTaskStatus === 3 ? "#FAFF00" : "#000000",
          position: "absolute",
          bottom: -11,
        }}
      >
        {currentTaskStatus === 3 && (
          <img
            src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/check.png"
            style={{ width: 16, height: 16 }}
          />
        )}
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {rewards.map((ele) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={
                    ele.type === "DISH"
                      ? "/images/task_dish_icon.svg"
                      : ele.type === "CHIPS"
                      ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                      : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                  }
                  style={{ width: 16, height: 16 }}
                />
                <Typography
                  style={{
                    padding: "0 2px",
                    fontFamily: "'Karla'",
                    fontWeight: 500,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    textAlign: "center",
                    color: currentTaskStatus === 3 ? "#FAFF00" : "#000000",
                  }}
                >
                  {numberWithCommas(ele.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      {currentTaskStatus === 1 && (
        <Link to="/">
          <ActionButton>Start</ActionButton>
        </Link>
      )}

      {currentTaskStatus === 2 && (
        <ActionButton
          onClick={currentTaskStatus === 1 ? null : onClickClaim}
          disabled={currentTaskStatus === 1}
          color="#faff00"
        >
          Claim
        </ActionButton>
      )}

      {currentTaskStatus === 3 && (
        <ActionButton disabled={true} color="#64ff99">
          Claimed
        </ActionButton>
      )}
    </Box>
  );
};

const SingleNonSpecialTask = ({
  taskId,
  name,
  points,
  pointsText,
  currentTabValue,
  rewards,
}) => {
  const dispatch = useDispatch();

  const { viberate } = useTelegramSDK();
  const { accountSC } = useServerAuth();
  const tapScore = useSelector((state) => state.ui.tapScore);
  const leagueLevel = useSelector((state) => state.ui.leagueLevel);
  const leagueTasksStatus = useSelector((state) => state.ui.leagueTasksStatus);
  const refTasksStatus = useSelector((state) => state.ui.refTasksStatus);
  const referralCount = useSelector((state) => state.ui.referralCount);

  let currentTaskStatus = useMemo(() => {
    let tempValue = 0;
    if (currentTabValue === 1) {
      tempValue = leagueTasksStatus[taskId];
    } else {
      tempValue = refTasksStatus[taskId];
    }

    if (tempValue === undefined) {
      return 0;
    } else {
      return tempValue;
    }
  }, [taskId, currentTabValue, leagueTasksStatus, refTasksStatus]);

  let isClaimableStatus = () => {
    if (currentTabValue === 1) {
      return (
        tapScore >= LEAGUE_TASKS_DATA[taskId].tapRequired &&
        leagueLevel === taskId
      );
    }
    let refClaimCond =
      referralCount >= REFERRAL_TASKS_DATA[taskId].referralRequired;
    return refClaimCond;
  };

  // Claim Rewards
  const onClickClaim = async () => {
    // Update status to progress
    let totalLevels = LEAGUE_TASKS_DATA.length;
    if (
      currentTabValue === 1 &&
      tapScore >= LEAGUE_TASKS_DATA[taskId].tapRequired &&
      leagueLevel < totalLevels
    ) {
      // Upgrade booster by type to backendAPI
      let dataObj = {
        userId: accountSC,
        type: "league",
        taskId: taskId,
      };
      let res = await setLeagueClaimToBackend(dataObj);

      if (res?.error === false) {
        await dispatch(updateTaskRewards(res.result));
        await dispatch(updateRefetchData());
      }
    }
    if (currentTabValue === 2) {
      // Upgrade booster by type to backendAPI
      let dataObj = {
        userId: accountSC,
        type: "referral",
        taskId: taskId,
      };
      let res = await setLeagueClaimToBackend(dataObj);

      if (res?.error === false) {
        await dispatch(updateTaskRewards(res.result));
        await dispatch(updateRefetchData());
      }
    }
    viberate("medium");
  };

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "65px",
        background: "linear-gradient(271.44deg, #7848FF 0.29%, #346DFF 98.45%)",
        border: "0.498756px solid #FFFFFF",
        borderRadius: "12px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 15px",
      }}
    >
      <Box
        style={{
          width: "70%",
          fontFamily: "'Karla'",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "21px",
          color: "#FFFFFF",
        }}
      >
        {name}

        {currentTabValue === 1 && (
          <ProgressBar
            value={(100 * tapScore) / points}
            containerStyle={{ height: 9, margin: "3px 0 10px" }}
            outerStyle={{ minHeight: 9 }}
            innerStyle={{ height: 5 }}
          />
        )}
      </Box>

      <Box
        style={{
          minWidth: currentTaskStatus === 2 ? "97px" : "auto",
          height: "24px",
          padding: "0 10px",
          background: currentTaskStatus === 2 ? "#018724" : "#FAFF00",
          borderRadius: "8px",
          fontFamily: "'Karla'",
          fontWeight: 500,
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          textAlign: "center",
          color: currentTaskStatus === 2 ? "#FAFF00" : "#000000",
          position: "absolute",
          bottom: -11,
        }}
      >
        {currentTaskStatus === 2 && (
          <img
            src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/check.png"
            style={{ width: 16, height: 16 }}
          />
        )}
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {rewards.map((ele) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={
                    ele.type === "DISH"
                      ? "/images/task_dish_icon.svg"
                      : ele.type === "CHIPS"
                      ? "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                      : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
                  }
                  style={{ width: 16, height: 16 }}
                />
                <Typography
                  style={{
                    padding: "0 2px",
                    fontFamily: "'Karla'",
                    fontWeight: 500,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    textAlign: "center",
                    color: currentTaskStatus === 2 ? "#FAFF00" : "#000000",
                  }}
                >
                  {getNumbersInFormatOnlyMillions(ele.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {currentTaskStatus === 2 && (
        <ActionButton disabled={true} color="#64ff99">
          Claimed
        </ActionButton>
      )}
      {currentTaskStatus != 2 && (
        <ActionButton
          onClick={!isClaimableStatus() ? null : onClickClaim}
          disabled={!isClaimableStatus()}
          color={!isClaimableStatus() ? "#b2b2b2" : "#faff00"}
        >
          Claim
        </ActionButton>
      )}
    </Box>
  );
};
const Tasks = () => {
  const { viberate, isAndroid, WebAppSDK } = useTelegramSDK();
  const { accountSC } = useServerAuth();

  const score = useSelector((state) => state.ui.score);
  const tapScore = useSelector((state) => state.ui.tapScore);
  const leagueLevel = useSelector((state) => state.ui.leagueLevel);

  const specialTasksStatus = useSelector(
    (state) => state.ui.specialTasksStatus
  );
  const dailyTaskStatus = useSelector((state) => state.ui.dailyTaskStatus);
  const referralCount = useSelector((state) => state.ui.referralCount);
  const refTasksStatus = useSelector((state) => state.ui.refTasksStatus);

  const [tabValue, setTabValue] = useState(0);
  const [socialTasks, setSocialtasks] = useState([]);
  const [dailyTasks, setDailytasks] = useState([]);
  const [nextUTCTime, setNextUTCTime] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);

  //Tasks states
  const [inProgress, setInProgress] = useState(-1);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    async function asyncFn() {
      let res = await getTasksData(accountSC);
      if (res) {
        console.log(res);
        setSocialtasks(res.specialTasks);
        setDailytasks(res.dailyTasks);
        setNextUTCTime(res.startOfNextDayUTC);
        setPageLoaded(true);
      }
    }

    if (accountSC) {
      asyncFn();
    }
  }, [accountSC]);

  const notificationDotStatus = (tabNo) => {
    let refCompletedIndex = refTasksStatus.length;

    if (tabNo === 0) {
      let completedTasksLength = specialTasksStatus.filter(
        (ele) => !isNaN(ele)
      ).length;

      return completedTasksLength !== socialTasks.length;
    }
    if (tabNo === 1) {
      if (leagueLevel === 14) {
        return false;
      } else {
        return tapScore >= LEAGUE_TASKS_DATA[leagueLevel].tapRequired;
      }
    }
    if (tabNo === 2) {
      return (
        referralCount >= REFERRAL_TASKS_DATA[refCompletedIndex].referralRequired
      );
    }
    if (tabNo === 3) {
      let completedTasksLength = dailyTaskStatus.filter(
        (ele) => ele === 3
      ).length;

      return completedTasksLength !== dailyTaskStatus.length;
    }
    return false;
  };

  const leagueLevelData = useMemo(() => {
    return leagueLevel === 14
      ? LEAGUE_TASKS_DATA[leagueLevel]
      : LEAGUE_TASKS_DATA[leagueLevel];
  }, [leagueLevel]);

  return (
    <Box
      style={{
        width: "100%",
        minHeight: "calc(100vh - 0px)",
        position: "relative",
        background: "#161811",
        paddingTop: "25px",
        overflowY: "hidden",
      }}
    >
      {console.log("Tasks")}
      <SuccessSnackbar text="Reward claimed succesfully!" />
      <TaskRewardPopup />
      <UserDashboard />
      <StickyBox offsetTop={10} style={{ zIndex: 0 }}>
        <Box
          style={{
            width: "90%",
            height: "87px",
            padding: "1px",
            marginTop: "15px",
            marginLeft: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontFamily: "'Rubik'",
              fontWeight: 600,
              fontSize: "40px",
              lineHeight: "100%",
              color: "#FAFF00",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <img
              src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/gobbl_coin.png"
              style={{
                width: 35,
                height: 35,
              }}
            />
            {numberWithCommas(score)}
          </Typography>

          <Link to="/league" prefetch="false">
            <Box
              style={{
                width: "max-content",
                minWidth: 115,
                height: 28,
                background:
                  leagueLevel === 1
                    ? "#9EAB08"
                    : leagueLevel === 2
                    ? "#1CB172"
                    : leagueLevel === 3
                    ? "#0DB2BC"
                    : leagueLevel === 4
                    ? "#5339EF"
                    : leagueLevel === 5
                    ? "#AA2CD6"
                    : leagueLevel === 6
                    ? "#D62C88"
                    : leagueLevel === 7
                    ? "#FF5C00"
                    : leagueLevel === 8
                    ? "#D1BD07"
                    : leagueLevel === 9
                    ? "#59B200"
                    : "#D6672C",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "5px",
                borderRadius: "8px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  fontFamily: "'Karla'",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#FFFFFF",
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src={leagueLevelData.img}
                  style={{
                    width: 26,
                    height: 24,
                    objectFit: "contain",
                    transform:
                      leagueLevel % 2 == 0 ? "rotate(12deg)" : "rotate(-12deg)",
                  }}
                />
                Level {leagueLevel + 1}: {leagueLevelData.title}
              </Box>
              <KeyboardArrowRight style={{ color: "#fff" }} />
            </Box>
          </Link>
        </Box>
        <Box
          style={{
            width: "95%",
            height: 52,
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "5px 10px",
            margin: "5% 2.5% 7%",
            position: "relative",
            background: "rgba(0,0,0,0)",
            zIndex: -1,
          }}
        >
          <Box
            style={{
              width: "100%",
              height: 52,
              background: "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
              borderRadius: "6px",
              padding: "1px",
              top: 0,
              left: 0,
              position: "absolute",
              zIndex: -2,
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                background: "#161811",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            />
          </Box>

          {tabs.map((ele, index) => (
            <Button
              key={ele.no}
              style={{
                width: "100%",
                height: "100%",
                fontFamily: "'Rubik'",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "11px",
                textAlign: "center",
                color: tabValue === ele.no ? "#000000" : "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: tabValue === ele.no ? "#FF9CFF" : "",
                borderRadius: "8px",
                position: "relative",
                zIndex: 3,
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                viberate("light");
                setTabValue(ele.no);
              }}
            >
              {ele.name}
              {notificationDotStatus(index) && (
                <span
                  style={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    background: "#FF4C4C",
                    position: "absolute",
                    top: 7,
                    right: 13,
                    zIndex: 3,
                  }}
                />
              )}
            </Button>
          ))}
        </Box>
      </StickyBox>
      <Box
        style={{
          width: "100%",
          minHeight: "calc(100vh - 300px)",
          height: "calc(100vh - 300px)",
          background: "linear-gradient(180deg, #4886FF 0%, #03429F 100%)",
          borderRadius: "32px 32px 0px 0px",
          padding: "1px 0 0",
          marginTop: "-7px",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          style={{
            width: "100%",
            minHeight: "calc(100vh - 218px)",
            height: "100%",
            background: "#2B2D25",
            borderRadius: "32px 32px 0px 0px",
            position: "relative",
            paddingTop: "25px",
            paddingBottom: "60px",
            zIndex: 1,
          }}
        >
          {tabValue === 0 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "22px",
                padding: "0 5% 25px",
                overflowY: "scroll",
                paddingBottom: 100,
              }}
            >
              {isVerifying && (
                <Box style={{ textAlign: "center", color: "white" }}>
                  Verification in progress, Please wait...
                </Box>
              )}

              {pageLoaded &&
                socialTasks.map((ele, i) => (
                  <SingleTask
                    key={i}
                    taskId={ele.id}
                    name={ele.title}
                    type={ele.type}
                    url={isAndroid ? ele.androidUrl : ele.url}
                    points={ele.points}
                    pointsText={ele.pointsText}
                    inProgress={inProgress}
                    setInProgress={setInProgress}
                    isVerifying={isVerifying}
                    setIsVerifying={setIsVerifying}
                    rewards={ele.rewards}
                  />
                ))}

              {pageLoaded && socialTasks.length === 0 && (
                <Box style={{ textAlign: "center" }}>No tasks found</Box>
              )}
              {!pageLoaded && (
                <Box>
                  <CircularProgress size={18} />
                </Box>
              )}
            </Box>
          )}
          {tabValue === 1 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "22px",
                padding: "0 5% 25px",
                overflowY: "scroll",
                paddingBottom: 100,
              }}
            >
              {LEAGUE_TASKS_DATA?.slice(0, -2).map((ele, i) => {
                return (
                  <SingleNonSpecialTask
                    key={i}
                    index={i}
                    taskId={ele.id}
                    taskNumber={ele.taskNumber}
                    name={ele.title}
                    pointsText={ele.pointsText}
                    points={ele.tapRequired}
                    currentTabValue={tabValue}
                    rewards={ele.rewards}
                  />
                );
              })}
              {LEAGUE_TASKS_DATA?.length === 0 && (
                <Box style={{ textAlign: "center" }}>No tasks found</Box>
              )}
            </Box>
          )}
          {tabValue === 2 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "22px",
                padding: "0 5% 25px",
                overflowY: "scroll",
                paddingBottom: 100,
              }}
            >
              {REFERRAL_TASKS_DATA?.map((ele, i) => (
                <SingleNonSpecialTask
                  key={i}
                  index={i}
                  taskId={ele.id}
                  taskNumber={ele.taskNumber}
                  name={ele.title}
                  points={ele.points}
                  pointsText={ele.pointsText}
                  currentTabValue={tabValue}
                  rewards={ele.rewards}
                />
              ))}

              {REFERRAL_TASKS_DATA?.length === 0 && (
                <Box style={{ textAlign: "center" }}>No tasks found</Box>
              )}
            </Box>
          )}
          {tabValue === 3 && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "22px",
                padding: "0 5% 25px",
                overflowY: "scroll",
                paddingBottom: 100,
              }}
            >
              {dailyTasks?.map((ele, i) => (
                <SingleDailyTask
                  key={i}
                  isDailyTask={true}
                  taskId={ele.id}
                  name={ele.title}
                  type={ele.type}
                  rewards={ele.rewards}
                  nextUTC={nextUTCTime}
                />
              ))}

              {REFERRAL_TASKS_DATA?.length === 0 && (
                <Box style={{ textAlign: "center" }}>No tasks found</Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Tasks;
