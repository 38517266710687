import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../actions/helperFn";
import DashboardPopup from "./DashboardPopup";
import useTelegramSDK from "../hooks/useTelegramSDK";

const UserDashboard = () => {
  const { telegramUsername, viberate } = useTelegramSDK(true);

  const chips = useSelector((state) => state.ui.chips);
  const username = useSelector((state) => state.ui.username);
  const profilePic = useSelector((state) => state.ui.profilePic);
  const [openDashboard, setOpenDashboard] = useState(false);

  return (
    <Box
      style={{
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 auto",
      }}
    >
      <DashboardPopup
        profilePic={
          profilePic
            ? profilePic
            : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/profile_thumb.png"
        }
        username={telegramUsername ? telegramUsername : "user"}
        openPopup={openDashboard}
        setOpenPopup={setOpenDashboard}
      />
      <Button
        style={{
          width: "fit-content",
          height: "30px",
          boxShadow: "0px 3px 0px #7F9E06",
          borderRadius: "8px",
          background:
            "linear-gradient(254.51deg, #00CCCC 5.63%, #009999 61.19%, #6666FF 116.96%)",
          padding: "1px",
        }}
        onClick={() => {
          viberate("medium");
          setOpenDashboard(true);
        }}
      >
        <Box
          style={{
            width: "100%",
            height: 28,
            fontFamily: "'Rubik'",
            fontWeight: 400,
            fontSize: "15px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 6px",
            gap: "6px",
            background: "#D1FF1A",
            borderRadius: "8px",
          }}
        >
          <img
            src={
              profilePic
                ? profilePic
                : "https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/profile_thumb.png"
            }
            style={{ width: 24, height: 24, borderRadius: "50%" }}
          />
          {username ? username : "user"}
        </Box>
      </Button>
      <Box
        style={{
          fontFamily: "'Rubik'",
          fontWeight: 400,
          fontSize: "15px",
          lineHeight: "18px",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          // textDecorationLine: "underline",
          color: "#D1FF1A",
        }}
      >
        <img
          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
          style={{ width: 24, height: 24 }}
        />
        {numberWithCommas(chips)}
      </Box>
    </Box>
  );
};

export default UserDashboard;
