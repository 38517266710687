import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useDispatch, useSelector } from "react-redux";
import { removeSignUpGift, updateDailyBoxOpen } from "../reducers/UiReducers";
import SignUpBonus from "../components/SignUpBonus";

const OnboardingFlow = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(390));
  const dispatch = useDispatch();

  const { viberate } = useTelegramSDK();

  const dataLoaded = useSelector((state) => state.ui.dataLoaded);
  const signUpGift = useSelector((state) => state.ui.signUpGift);
  const nextClaimTimestamp = useSelector(
    (state) => state.ui.nextClaimTimestamp
  );

  // Features toggle states
  const [openSignUpBonus, setOpenSignUpBonus] = useState(false);

  // Making onboarding flow
  useEffect(() => {
    if (dataLoaded && signUpGift && nextClaimTimestamp) {
      let isSignUpBonus = signUpGift.length === 3;
      let isClaimAvailable = nextClaimTimestamp < Date.now();

      if (isSignUpBonus) {
        setOpenSignUpBonus(true);
      } else if (isClaimAvailable) {
        dispatch(updateDailyBoxOpen(true));
      }
    }
  }, [dataLoaded, signUpGift, nextClaimTimestamp, openSignUpBonus]);

  const handleCloseGiftPopup = () => {
    viberate("medium");
    setOpenSignUpBonus(false);
    dispatch(removeSignUpGift());
  };

  return (
    <SignUpBonus
      openPopup={openSignUpBonus}
      closePopup={handleCloseGiftPopup}
      setOpenSignUpBonus={setOpenSignUpBonus}
    />
  );
};

export default OnboardingFlow;
