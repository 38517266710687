import { getAnalytics, logEvent } from "firebase/analytics";
import { useCallback } from "react";
import { initializeApp } from "firebase/app";
import constants from "../utils/constants";
import { firebaseConfig } from "../firbase";

export const useLogClickEvent = () => {
  const logClickEvent = useCallback((eventName, eventParams) => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    if (constants.net === 0) {
      logEvent(analytics, eventName, eventParams);
    } else {
      console.log("Hitting event for: " + eventName);
    }
  }, []);

  return { logClickEvent };
};
