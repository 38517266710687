import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import UserDashboard from "../components/UserDashboard";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useSelector } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";
import { useLogClickEvent } from "../hooks/useLogClickEvent";

const Games = () => {
  const { logClickEvent } = useLogClickEvent();
  const { isMobileUser } = useTelegramSDK();
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const screenLoaded = useSelector((state) => state.ui.screenLoaded);

  return (
    <>
      {screenLoaded && (
        <Box
          style={{
            width: "100%",
            height: "fit-content",
            minHeight: "100vh",
            position: "relative",
            background: "#161811",
            paddingTop: isPortrait ? "25px" : "15px",
            paddingBottom: "60px",
          }}
        >
          <UserDashboard />
          <Typography
            style={{
              fontFamily: "'Karla'",
              fontWeight: 500,
              fontSize: "12.2105px",
              lineHeight: "14px",
              textAlign: "center",
              color: "#FFFFFF",
              marginTop: "35px",
            }}
          >
            <span style={{ lineHeight: "30px" }}>
              Play Gobbl Games to earn chips.
            </span>
            <br />
            Chips can be used to level up dishes,
            <br /> open cloches & start restaurants.
          </Typography>
          <Box
            style={{
              padding: "2.55% 5% 5%",
              display: "flex",
              flexDirection: isPortrait ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            <Box
              style={{
                width: 316,
                height: 255,
                position: "relative",
                padding: "7px 7px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/food5.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  fontFamily: "'Karla'",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "14px",
                  textAlign: "center",
                  position: "relative",
                  zIndex: 1,
                  color: "#FFFFFF",
                }}
              >
                For the love of Food and Trump Cards! Presenting
                <br />
                the classic PvP Card Battle for your favorite dishes.
              </Typography>
              <Link
                to="/food5"
                style={{
                  width: "142px",
                  minHeight: "37px",
                  background: "#64FF99",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  textDecoration: "none",
                  position: "relative",
                  zIndex: 1,
                }}
                onClick={() => logClickEvent("Open_Food5")}
              >
                <Button
                  style={{
                    width: "142px",
                    minHeight: "37px",
                    fontFamily: "'Rubik'",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "21px",
                    textAlign: "center",
                    background:
                      "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Play
                </Button>
              </Link>
              <svg
                width="318"
                height="257"
                viewBox="0 0 318 257"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <path
                  d="M269 0.5H49H48.9594C37.68 0.499999 29.1676 0.499998 22.5989 1.38314C16.0079 2.26927 11.299 4.05278 7.67588 7.67588C4.05278 11.299 2.26927 16.0079 1.38314 22.5989C0.499998 29.1676 0.499999 37.68 0.5 48.9594V49V208V208.041C0.499999 219.32 0.499998 227.832 1.38314 234.401C2.26927 240.992 4.05278 245.701 7.67588 249.324C11.299 252.947 16.0079 254.731 22.5989 255.617C29.1675 256.5 37.6799 256.5 48.9592 256.5H49H269H269.041C280.32 256.5 288.832 256.5 295.401 255.617C301.992 254.731 306.701 252.947 310.324 249.324C313.947 245.701 315.731 240.992 316.617 234.401C317.5 227.832 317.5 219.32 317.5 208.041V208V49V48.9592C317.5 37.6799 317.5 29.1675 316.617 22.5989C315.731 16.0079 313.947 11.299 310.324 7.67588C306.701 4.05278 301.992 2.26927 295.401 1.38314C288.832 0.499998 280.32 0.499999 269.041 0.5H269Z"
                  fill="url(#paint0_linear_675_48)"
                  fillOpacity="0.5"
                  stroke="url(#paint1_linear_675_48)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_675_48"
                    x1="159"
                    y1="1"
                    x2="159"
                    y2="256"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7848FF" />
                    <stop offset="1" stopColor="#346DFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_675_48"
                    x1="159"
                    y1="1"
                    x2="159"
                    y2="256"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF97FF" />
                    <stop offset="1" stopColor="#CC37CC" />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
            <Box
              style={{
                width: 316,
                height: 255,
                position: "relative",
                padding: "7px 7px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/foodfury.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  fontFamily: "'Karla'",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "14px",
                  textAlign: "center",
                  position: "relative",
                  zIndex: 1,
                  color: "#FFFFFF",
                }}
              >
                Race against rivals to deliver sizzling hot dishes in the most
                thrilling food delivery game ever!
              </Typography>
              <Link
                to="/foodfury"
                style={{
                  width: "142px",
                  minHeight: "37px",
                  background: "#64FF99",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  textDecoration: "none",
                  position: "relative",
                  zIndex: 1,
                }}
                onClick={() => logClickEvent("Open_FoodFury")}
              >
                <Button
                  style={{
                    width: "142px",
                    minHeight: "37px",
                    fontFamily: "'Rubik'",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "21px",
                    textAlign: "center",
                    background:
                      "linear-gradient(180deg, #7848FF 0%, #346DFF 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Play
                </Button>
              </Link>
              <svg
                width="318"
                height="257"
                viewBox="0 0 318 257"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <path
                  d="M269 0.5H49H48.9594C37.68 0.499999 29.1676 0.499998 22.5989 1.38314C16.0079 2.26927 11.299 4.05278 7.67588 7.67588C4.05278 11.299 2.26927 16.0079 1.38314 22.5989C0.499998 29.1676 0.499999 37.68 0.5 48.9594V49V208V208.041C0.499999 219.32 0.499998 227.832 1.38314 234.401C2.26927 240.992 4.05278 245.701 7.67588 249.324C11.299 252.947 16.0079 254.731 22.5989 255.617C29.1675 256.5 37.6799 256.5 48.9592 256.5H49H269H269.041C280.32 256.5 288.832 256.5 295.401 255.617C301.992 254.731 306.701 252.947 310.324 249.324C313.947 245.701 315.731 240.992 316.617 234.401C317.5 227.832 317.5 219.32 317.5 208.041V208V49V48.9592C317.5 37.6799 317.5 29.1675 316.617 22.5989C315.731 16.0079 313.947 11.299 310.324 7.67588C306.701 4.05278 301.992 2.26927 295.401 1.38314C288.832 0.499998 280.32 0.499999 269.041 0.5H269Z"
                  fill="url(#paint0_linear_675_48)"
                  fillOpacity="0.5"
                  stroke="url(#paint1_linear_675_48)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_675_48"
                    x1="159"
                    y1="1"
                    x2="159"
                    y2="256"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7848FF" />
                    <stop offset="1" stopColor="#346DFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_675_48"
                    x1="159"
                    y1="1"
                    x2="159"
                    y2="256"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF97FF" />
                    <stop offset="1" stopColor="#CC37CC" />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
          </Box>
        </Box>
      )}
      {!isMobileUser && <LoadingScreen text={"Supports Mobile Only"} />}
      {!screenLoaded && isMobileUser && (
        <LoadingScreen text={"Token Coming Soon"} />
      )}
    </>
  );
};

export default Games;
