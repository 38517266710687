import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Slide,
  Typography,
  useTheme,
  Dialog,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardData,
  updateUserWalletAddressAPI,
} from "../actions/serverActions";

import { getNumbersInFormat } from "../actions/helperFn";
import {
  AccessTime,
  Close,
  InfoOutlined,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  useIsConnectionRestored,
  TonConnectButton,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
import { useServerAuth } from "../hooks/useServerAuth";
import WalletSuccessPopup from "./WalletSuccessPopup";
import {
  updateUserWalletAddress,
  createWithdrawTonRequest,
} from "../reducers/UiReducers";
import TimeAgo from "react-timeago";
import Profile from "./Profile";
import { Link } from "react-router-dom";
import { LEAGUE_TASKS_DATA } from "../utils/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardPopup = ({ openPopup, setOpenPopup, profilePic, username }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [dashboardData, setDashboardData] = useState({});
  const [error, setError] = useState("");
  const [walletSuccessPopup, setWalletSuccessPopup] = useState(false);
  const [errorColor, setErrorColor] = useState("RED");

  const userAddress = useSelector((state) => state.ui.userAddress);
  const screenLoaded = useSelector((state) => state.ui.screenLoaded);

  const tonBalance = useSelector((state) => state.ui.tonBalance);
  const pendingTON = useSelector((state) => state.ui.pendingTON);
  const withdrawnTON = useSelector((state) => state.ui.withdrawnTON);
  const chips = useSelector((state) => state.ui.chips);

  const tonPendingTimestamp = useSelector(
    (state) => state.ui.tonPendingTimestamp
  );
  const tonSuccessTimestamp = useSelector(
    (state) => state.ui.tonSuccessTimestamp
  );
  const leagueLevel = useSelector((state) => state.ui.leagueLevel);

  const leagueLevelData = useMemo(() => {
    return leagueLevel === 14
      ? LEAGUE_TASKS_DATA[leagueLevel]
      : LEAGUE_TASKS_DATA[leagueLevel];
  }, [leagueLevel]);

  const userFriendlyAddress = useTonAddress();
  const connectionRestored = useIsConnectionRestored();

  const wallet = useTonWallet();
  const { accountSC } = useServerAuth();

  // API call: to fetch tasks
  useEffect(() => {
    async function asyncFn() {
      let res = await getDashboardData();
      if (res) {
        setDashboardData(res);
      }
    }
    asyncFn();
  }, []);

  useEffect(() => {
    async function asyncFn() {
      if (
        screenLoaded &&
        accountSC &&
        connectionRestored &&
        userFriendlyAddress &&
        userAddress != userFriendlyAddress
      ) {
        await updateUserWalletAddressAPI(
          userFriendlyAddress,
          wallet.name,
          accountSC
        );
        dispatch(updateUserWalletAddress(userFriendlyAddress));
        setWalletSuccessPopup(true);
      }
    }

    asyncFn();
  }, [
    screenLoaded,
    accountSC,
    userFriendlyAddress,
    userAddress,
    wallet?.name,
    connectionRestored,
  ]);

  //1. When tonBalance<1
  //2. When tonBalance>=1
  //3. When wallet not connected
  const handleClaimButton = async () => {
    if (tonBalance > 1) {
      setErrorColor("GREEN");
    } else {
      setErrorColor("RED");
    }

    if (!userFriendlyAddress) {
      setError("Connect your wallet first to withdraw");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      if (tonBalance < 1) {
        setError("Minimum balance required: 1.00 TON");
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError("Withdrawl request has been submitted.");
        dispatch(createWithdrawTonRequest(accountSC));
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    }
  };
  return (
    <Dialog
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      TransitionComponent={Transition}
      aria-labelledby="simple-dialog-title"
      maxWidth="lg"
      fullWidth={false}
      style={{ zIndex: 120 }}
    >
      {walletSuccessPopup && (
        <WalletSuccessPopup
          walletSuccessPopup={walletSuccessPopup}
          setWalletSuccessPopup={setWalletSuccessPopup}
        />
      )}
      <Box
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "grid",
          placeItems: "center",
          background: "transparent",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              width: "100%",
              maxHeight: "95%",
              height: 700,
              position: "relative",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "auto",
              borderRadius: "33px 33px 0px 0px",
              zIndex: 1111,
              overflow: "hidden",
              background: "linear-gradient(180deg, #4886FF 0%, #03429F 100%)",
              padding: "1px 1px 0",
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: 1,
                left: 1,
                bottom: 0,
                width: "calc(100% - 2px)",
                height: "100%",
                background: "#2B2D25",
                borderRadius: "33px 33px 0px 0px",
              }}
            />
            {/* profile */}
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px 5% 0",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  margin: "10px 0",
                }}
              >
                <img
                  src={profilePic}
                  style={{ width: 40, height: 40, borderRadius: "50%" }}
                />
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "'Karla'",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "16px",
                      color: "#FFFFFF",
                      opacity: 0.8,
                    }}
                  >
                    {username}
                  </Typography>
                  <Link to="/league" prefetch="false">
                    <Box
                      style={{
                        width: "max-content",
                        minWidth: 100,
                        height: 22,
                        background:
                          leagueLevel === 1
                            ? "#9EAB08"
                            : leagueLevel === 2
                            ? "#1CB172"
                            : leagueLevel === 3
                            ? "#0DB2BC"
                            : leagueLevel === 4
                            ? "#5339EF"
                            : leagueLevel === 5
                            ? "#AA2CD6"
                            : leagueLevel === 6
                            ? "#D62C88"
                            : leagueLevel === 7
                            ? "#FF5C00"
                            : leagueLevel === 8
                            ? "#D1BD07"
                            : leagueLevel === 9
                            ? "#59B200"
                            : "#D6672C",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: "3px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          fontFamily: "'Karla'",
                          fontWeight: 700,
                          fontSize: "11px",
                          lineHeight: "16px",
                          color: "#FFFFFF",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <img
                          src={leagueLevelData.img}
                          style={{
                            width: 18,
                            height: 16,
                            objectFit: "contain",
                            transform:
                              leagueLevel % 2 == 0
                                ? "rotate(12deg)"
                                : "rotate(-12deg)",
                          }}
                        />
                        {leagueLevelData.title}
                      </Box>
                      <KeyboardArrowRight style={{ color: "#fff" }} />
                    </Box>
                  </Link>
                </Box>
              </Box>
              <Button
                style={{
                  minWidth: "auto",
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                }}
                onClick={() => setOpenPopup(false)}
              >
                <Close
                  style={{
                    fontSize: 24,
                    fill: "#faff00",
                    zIndex: 1,
                  }}
                />
              </Button>
            </Box>
            <Box
              style={{
                width: "100%",
                position: "relative",
                zIndex: 1,
              }}
            >
              {dashboardData && (
                <Box style={{ width: "100%", height: "100%" }}>
                  {/* all balances */}
                  <Box
                    style={{
                      width: "90%",
                      height: 104,
                      borderRadius: "24px",
                      padding: "1px",
                      position: "relative",
                      marginTop: "15px",
                      marginLeft: "5%",
                      background: "transparent",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "15px 25px",
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: "7px",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Karla",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#FFFFFF",
                                zIndex: 1,
                              }}
                            >
                              Total TON
                            </Typography>
                            <Box
                              style={{
                                fontFamily: "'Rubik'",
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: "115%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                color: "#D1FF1A",
                                zIndex: 1,
                              }}
                            >
                              <img
                                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                                style={{ width: 20, height: 20 }}
                              />
                              {tonBalance} TON
                            </Box>
                          </Box>
                          <Box
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              gap: "7px",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Karla",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "#FFFFFF",
                                zIndex: 1,
                              }}
                            >
                              Total Chips
                            </Typography>
                            <Box
                              style={{
                                fontFamily: "'Rubik'",
                                fontWeight: 600,
                                fontSize: 16,
                                lineHeight: "115%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                color: "#D1FF1A",
                                zIndex: 1,
                              }}
                            >
                              <img
                                src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/chips.png"
                                style={{ width: 20, height: 20 }}
                              />
                              {chips}
                            </Box>
                          </Box>
                        </Box>
                        <Button
                          // disabled={true}
                          style={{
                            width: "82px",
                            height: "27px",
                            background: tonBalance < 1 ? "#bdbdbd" : "#D1FF1A",
                            borderRadius: "8px",
                            fontWeight: 600,
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#000",
                            textTransform: "capitalize",
                            zIndex: 1,
                          }}
                          onClick={handleClaimButton}
                        >
                          Claim
                        </Button>
                        {/* background */}
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(180deg, #FF97FF 0%, #CC37CC 100%)",
                            borderRadius: "24px",
                            padding: "1px",
                            marginBottom: "15px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              height: "100%",
                              background: "#2B2D25",
                              borderRadius: "24px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              gap: "10px",
                              padding: "15px",
                              position: "relative",
                            }}
                          >
                            <Box
                              style={{
                                width: "100%",
                                height: "100%",
                                background:
                                  "linear-gradient(241.27deg, rgba(253, 255, 245, 0.08) -5.59%, rgba(253, 255, 245, 0) 100%)",
                                borderRadius: "24px",
                                position: "absolute",
                                top: 0,
                                left: 0,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* error */}
                    {error && (
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: 72,
                          background:
                            errorColor === "GREEN" ? "green" : "#FF4C4C",
                          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.45)",
                          borderRadius: "16px",
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                          zIndex: 2,
                          fontFamily: "'Karla'",
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#FFFFFF",
                          position: "absolute",
                          left: 0,
                          top: 114,
                        }}
                      >
                        <InfoOutlined style={{ fontSize: 18 }} />
                        {error}
                      </Box>
                    )}
                  </Box>

                  {/* wallet */}
                  <Box
                    style={{
                      width: "90%",
                      height: "100%",
                      maxHeight: 70,
                      background: "transparent",
                      borderRadius: "24px",
                      marginTop: "15px",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px",
                      // padding: "15px",
                      marginLeft: "5%",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "1px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "5px",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Karla",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#FFFFFF",
                        }}
                      >
                        {userFriendlyAddress
                          ? "Your TON wallet"
                          : "Wallet address not found"}
                      </Typography>
                      <TonConnectButton />
                    </Box>
                    {/* background */}
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(180deg, #FF97FF 0%, #CC37CC 100%)",
                        borderRadius: "24px",
                        padding: "1px",
                        marginBottom: "15px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(180deg, rgba(120, 72, 255, 0.5) 0%, rgba(52, 109, 255, 0.5) 100%)",
                          borderRadius: "24px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "10px",
                          padding: "15px",
                          position: "relative",
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(180deg, #463188 0%, #254287 100%)",
                            borderRadius: "24px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* ton withdraw  */}
                  {/* {tonDetailData} */}
                  <Box
                    style={{
                      width: "90%",
                      height: "100%",
                      maxHeight: 70,
                      marginLeft: "5%",
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        borderRadius: "24px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Karla'",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          zIndex: 1,
                        }}
                      >
                        TON Withdrawn
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          zIndex: 1,
                          fontFamily: "'Rubik'",
                          fontWeight: 500,
                          fontSize: "18px",
                          color: "#64FF99",
                        }}
                      >
                        <img
                          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                          style={{ width: 18, height: 18 }}
                        />
                        {withdrawnTON} TON
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          zIndex: 1,
                          minHeight: 15,
                        }}
                      >
                        {tonSuccessTimestamp === -1
                          ? ""
                          : [
                              <AccessTime
                                style={{ fontSize: 16, color: "#fff" }}
                              />,
                              <Typography
                                style={{
                                  fontFamily: "'Karla'",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  textAlign: "center",
                                  color: "#FFFFFF",
                                  opacity: 0.8,
                                }}
                              >
                                <TimeAgo date={tonSuccessTimestamp} />
                              </Typography>,
                            ]}
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(180deg, #089B89 0%, #036860 100%)",
                          borderRadius: "24px",
                          padding: "1px",
                          marginBottom: "15px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(180deg, rgba(8, 155, 137, 0.4) 0%, rgba(3, 104, 96, 0.4) 100%)",
                            borderRadius: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "10px",
                            padding: "15px",
                            position: "relative",
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              height: "100%",
                              background:
                                "linear-gradient(180deg, #0f473c 0%, #103a32 100%)",
                              borderRadius: "24px",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        borderRadius: "24px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Karla'",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          zIndex: 1,
                        }}
                      >
                        Pending TON
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          zIndex: 1,
                          fontFamily: "'Rubik'",
                          fontWeight: 500,
                          fontSize: "18px",
                          color: "#FF4C4C",
                        }}
                      >
                        <img
                          src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/ton.png"
                          style={{ width: 18, height: 18 }}
                        />
                        {pendingTON} TON
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          zIndex: 1,
                          minHeight: 15,
                        }}
                      >
                        {tonPendingTimestamp === -1
                          ? ""
                          : [
                              <AccessTime
                                style={{ fontSize: 16, color: "#fff" }}
                              />,
                              <Typography
                                style={{
                                  fontFamily: "'Karla'",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  textAlign: "center",
                                  color: "#FFFFFF",
                                  opacity: 0.8,
                                }}
                              >
                                <TimeAgo date={tonPendingTimestamp} />
                              </Typography>,
                            ]}
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(180deg, #089B89 0%, #036860 100%)",
                          borderRadius: "24px",
                          padding: "1px",
                          marginBottom: "15px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(180deg, rgba(8, 155, 137, 0.4) 0%, rgba(3, 104, 96, 0.4) 100%)",
                            borderRadius: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "10px",
                            padding: "15px",
                            position: "relative",
                          }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              height: "100%",
                              background:
                                "linear-gradient(180deg, #0f473c 0%, #103a32 100%)",
                              borderRadius: "24px",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      width: "55%",
                      height: "0px",
                      opacity: 0.3,
                      border: "1px solid #FFFFFF",
                      marginTop: "15px",
                      marginLeft: "10%",
                    }}
                  />
                  {/* users data */}
                  <Box
                    style={{
                      padding: "25px 5% 0 10%",
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Rubik'",
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "22px",
                          color: "#64FF99",
                        }}
                      >
                        Player Stats
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Rubik",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#FAFF00",
                          }}
                        >
                          Total Feeds
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Karla",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "100%",
                            color: "#FFFFFF",
                          }}
                        >
                          {dashboardData.totalTaps &&
                            getNumbersInFormat(dashboardData.totalTaps)}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Rubik",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#FAFF00",
                          }}
                        >
                          Total Players
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Karla",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "100%",
                            color: "#FFFFFF",
                          }}
                        >
                          {dashboardData?.totalUsers}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Rubik",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#FAFF00",
                          }}
                        >
                          Daily Users
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Karla",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "100%",
                            color: "#FFFFFF",
                          }}
                        >
                          {dashboardData?.totalActivePlayers}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <img
                        src="https://onerare-bucket.s3.ap-south-1.amazonaws.com/tg-apps/dashboard_gobbl.webp"
                        style={{
                          width: "100%",
                          marginBottom: "-45px",
                          marginTop: "35px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DashboardPopup;
