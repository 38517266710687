import { useEffect } from "react";
import useTelegramSDK from "../hooks/useTelegramSDK";
import { useLocation } from "react-router-dom";

export const BackButton = ({ onClick }) => {
  const { WebAppSDK } = useTelegramSDK();
  const backButton = WebAppSDK.BackButton;

  let isButtonShown = false;
  useEffect(() => {
    backButton.show();
    isButtonShown = true;
    return () => {
      isButtonShown = false;

      setTimeout(() => {
        if (!isButtonShown) {
          backButton.hide();
        }
      }, 10);
    };
  }, []);

  useEffect(() => {
    WebAppSDK.onEvent("backButtonClicked", onClick);
    return () => {
      WebAppSDK.offEvent("backButtonClicked", onClick);
    };
  }, [onClick]);

  return null;
};
